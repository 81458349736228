






















































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import { EditActivityInput, RemoveActivityInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import editActivityMutation from './edit-activity.gql';
import removeActivityMutation from './remove-activity.gql';
import query from './view.gql';
import {
  AppCustomerActivityGeneralViewEditActivityMutation,
  AppCustomerActivityGeneralViewEditActivityMutationVariables,
} from './__generated__/AppCustomerActivityGeneralViewEditActivityMutation';
import {
  AppCustomerActivityGeneralViewRemoveActivityMutation,
  AppCustomerActivityGeneralViewRemoveActivityMutationVariables,
} from './__generated__/AppCustomerActivityGeneralViewRemoveActivityMutation';
import {
  AppCustomerActivityGeneralViewQuery,
  AppCustomerActivityGeneralViewQueryVariables,
} from './__generated__/AppCustomerActivityGeneralViewQuery';

@Component({
  apollo: {
    activity: {
      query,
      fetchPolicy: 'network-only',
      variables(this: GeneralView): AppCustomerActivityGeneralViewQueryVariables {
        return { id: this.activityId };
      },
    },
  },
  data() {
    return { activity: undefined };
  },
})
export default class GeneralView extends Vue {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp(true)
  private readonly activityId!: string;

  private readonly activity?: AppCustomerActivityGeneralViewQuery['activity'];

  private async editActivity(input: EditActivityInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerActivityGeneralViewEditActivityMutation,
      AppCustomerActivityGeneralViewEditActivityMutationVariables
    >({
      mutation: editActivityMutation,
      variables: { input: { ...input, id: this.activityId } },
    });

    if (!data) {
      throw new Error('Aktivität konnte nicht gespeichert werden.');
    }
  }

  private async removeActivity(input: RemoveActivityInput): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerActivityGeneralViewRemoveActivityMutation,
      AppCustomerActivityGeneralViewRemoveActivityMutationVariables
    >({
      mutation: removeActivityMutation,
      variables: { input: { ...input, id: this.activityId } },
    });

    if (!data) {
      throw new Error('ktivität konnte nicht entfernt werden.');
    }
  }

  private async onRemoved(hide: () => Promise<void>): Promise<void> {
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Aktivität gelöscht!', class: 'success' }],
    });

    await hide();

    if (this.activity !== undefined) {
      this.$router.replace({
        name: 'AppCustomer/Customer/Activities',
        params: { customerId: this.activity.first.customer.id },
      });
    }
  }

  private get options(): Option[] {
    return [
      {
        value: null,
        label: 'Deaktiviert',
      },
      {
        value: 'PT1H',
        label: 'Eine Stunde',
      },
      {
        value: 'PT4H',
        label: 'Vier Stunden',
      },
      {
        value: 'PT8H',
        label: 'Acht Stunden',
      },
      {
        value: 'P1D',
        label: 'Ein Tag',
      },
    ];
  }
}
