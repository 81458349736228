

















































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditExternalSftpConfigurationInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerExternalSftpConfigurationSettingsViewQuery,
  AppCustomerExternalSftpConfigurationSettingsViewQueryVariables,
} from '@/features/app-customer/views/external-sftp-configuration/settings/__generated__/AppCustomerExternalSftpConfigurationSettingsViewQuery';
import {
  AppCustomerExternalSftpConfigurationSettingsViewEditMutation,
  AppCustomerExternalSftpConfigurationSettingsViewEditMutationVariables,
} from '@/features/app-customer/views/external-sftp-configuration/settings/__generated__/AppCustomerExternalSftpConfigurationSettingsViewEditMutation';
import {
  AppCustomerExternalSftpConfigurationSettingsViewRemoveMutation,
  AppCustomerExternalSftpConfigurationSettingsViewRemoveMutationVariables,
} from '@/features/app-customer/views/external-sftp-configuration/settings/__generated__/AppCustomerExternalSftpConfigurationSettingsViewRemoveMutation';

type FormData = Pick<EditExternalSftpConfigurationInput, 'host' | 'port' | 'username' | 'password'>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerExternalSftpConfigurationSettingsViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined, fingerprint: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerExternalSftpConfigurationSettingsViewQuery['customers'];

  private fingerprint?: string;

  private async edit(input: FormData): Promise<void> {
    const externalSftpConfigurationId = this.customers?.first.externalSftpConfigurations.first?.id;
    if (externalSftpConfigurationId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerExternalSftpConfigurationSettingsViewEditMutation,
      AppCustomerExternalSftpConfigurationSettingsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          externalSftpConfigurationId,
          ...input,
        },
      },
    });

    if (!data) {
      throw new Error('Die Konfiguration des SFTP Servers konnte nicht gespeichert werden');
    }

    if (!data.editExternalSftpConfiguration.verificationResult) {
      throw new Error(
        `Die Konfiguration des SFTP Servers konnte nicht gespeichert werden: ${data.editExternalSftpConfiguration.verificationError}`,
      );
    }

    const text = `Die Konfiguration des SFTP Servers wurde gespeichert`;
    this.fingerprint = data.editExternalSftpConfiguration.verificationFingerprint;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    const externalSftpConfigurationId = this.customers?.first.externalSftpConfigurations.first?.id;
    if (externalSftpConfigurationId === undefined) {
      return;
    }

    if (!window.confirm('Die SFTP Server Konfiguration wurde entfernt')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerExternalSftpConfigurationSettingsViewRemoveMutation,
      AppCustomerExternalSftpConfigurationSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { externalSftpConfigurationId } },
    });

    if (!data) {
      throw new Error('Die SFTP Server Konfiguration konnte nicht entfernt werden');
    }

    const text = `Die SFTP Server Konfiguration wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private isValidPort(value: unknown): boolean {
    return Number.isInteger(value) && (value as number) > 0 && (value as number) < 65536;
  }
}
