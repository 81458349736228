
























































































import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerComgyApiConnectDevicesMetersQuery,
  AppCustomerComgyApiConnectDevicesMetersQueryVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesMetersQuery';
import metersQuery from './meters.gql';
import {
  AppCustomerComgyApiConnectDevicesSensorsQuery,
  AppCustomerComgyApiConnectDevicesSensorsQueryVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesSensorsQuery';
import sensorsQuery from './sensors.gql';
import {
  AppCustomerComgyApiConnectDevicesMeterModulesQuery,
  AppCustomerComgyApiConnectDevicesMeterModulesQueryVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesMeterModulesQuery';
import meterModulesQuery from './meter-modules.gql';
import {
  AppCustomerComgyApiConnectDevicesViewQuery,
  AppCustomerComgyApiConnectDevicesViewQueryVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesViewQuery';
import {
  ComgyEstateUnit,
  ComgyObject,
  ComgySensor,
  ComgySingleSensorGroup,
  ComgyStairway,
  ConnectableDevice,
  ConnectedDevice,
  Device,
  TreePath,
} from './comgy-types';
import { Column } from '@/features/ui/table/model';
import MiniSensor from '@/components/mini-sensor/MiniSensor.vue';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import connectMutation from './connect.gql';
import {
  AppCustomerComgyApiConnectDevicesConnectMutation,
  AppCustomerComgyApiConnectDevicesConnectMutationVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesConnectMutation';
import { ComgyDeviceMapper, ComgyDeviceMapping } from './DeviceMapper';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import unknown from '@/assets/images/mini-sensors/unknown.svg';
import BindAll from './BindAll.vue';
import MappingProposal from './MappingProposal.vue';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ConnectDevicesView): AppCustomerComgyApiConnectDevicesViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return {
      customers: undefined,
      comgyDevices: undefined,
      comgyObjectExpanded: {},
      comgyObjectLink: {},
    };
  },
  components: { MiniSensor, BindAll, MappingProposal },
})
export default class ConnectDevicesView extends Mixins(DeviceRoleMapMixin) {
  private COMGY_LIMIT = 200;
  private unknown = unknown;

  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly columns: Column[] = [
    { name: 'icon', label: '', align: 'right' },
    { name: 'type', label: 'Typ' },
    { name: 'name', label: 'Name' },
    { name: 'link', label: 'Verknüpfung' },
    { name: 'sensors', label: 'Sensoren' },
    { name: 'connect', label: '' },
  ];

  private comgyObjectExpanded!: { [key: string]: boolean | undefined };

  private comgyObjectLink!: { [key: string]: TreePath | undefined };

  private comgyDevices?: Device[];

  private readonly customers?: AppCustomerComgyApiConnectDevicesViewQuery['customers'];

  private get ready(): boolean {
    return !this.$apollo.loading && this.comgyDevices !== undefined;
  }

  private async loadMeters(): Promise<Device[]> {
    const meters: Device[] = [];

    for (let offset = 0; meters.length < 10000; offset += this.COMGY_LIMIT) {
      const { data } = await this.$apollo.query<
        AppCustomerComgyApiConnectDevicesMetersQuery,
        AppCustomerComgyApiConnectDevicesMetersQueryVariables
      >({
        query: metersQuery,
        variables: { customerId: this.customerId, vendorApiId: this.vendorApiId, offset, limit: this.COMGY_LIMIT },
        fetchPolicy: 'no-cache',
      });

      const currentMeters = data.customers.first.comgyApis.first.meters?.items;

      if (currentMeters) {
        meters.push(...(currentMeters as unknown as Device[]));
        if (currentMeters.length !== this.COMGY_LIMIT) {
          break;
        }
      }
    }
    return meters;
  }

  private async loadSensors(): Promise<Device[]> {
    const sensors: Device[] = [];

    for (let offset = 0; sensors.length < 10000; offset += this.COMGY_LIMIT) {
      const { data } = await this.$apollo.query<
        AppCustomerComgyApiConnectDevicesSensorsQuery,
        AppCustomerComgyApiConnectDevicesSensorsQueryVariables
      >({
        query: sensorsQuery,
        variables: { customerId: this.customerId, vendorApiId: this.vendorApiId, offset, limit: this.COMGY_LIMIT },
        fetchPolicy: 'no-cache',
      });

      const currentSensors = data.customers.first.comgyApis.first.sensors?.items;

      if (currentSensors) {
        sensors.push(...(currentSensors as unknown as Device[]));
        if (currentSensors.length !== this.COMGY_LIMIT) {
          break;
        }
      }
    }
    return sensors;
  }

  private async loadMeterModules(): Promise<Device[]> {
    const meterModules: Device[] = [];

    for (let offset = 0; meterModules.length < 10000; offset += this.COMGY_LIMIT) {
      const { data } = await this.$apollo.query<
        AppCustomerComgyApiConnectDevicesMeterModulesQuery,
        AppCustomerComgyApiConnectDevicesMeterModulesQueryVariables
      >({
        query: meterModulesQuery,
        variables: { customerId: this.customerId, vendorApiId: this.vendorApiId, offset, limit: this.COMGY_LIMIT },
        fetchPolicy: 'no-cache',
      });
      const currentMeterModules = data.customers.first.comgyApis.first.meterModules?.items;

      if (currentMeterModules) {
        meterModules.push(
          ...(currentMeterModules.filter((d) => d.details && 'attributes' in d.details) as unknown as Device[]),
        );

        if (currentMeterModules.length !== this.COMGY_LIMIT) {
          break;
        }
      }
    }
    return meterModules;
  }

  private async created(): Promise<void> {
    const [meters, sensors, meterModules] = await Promise.all([
      this.loadMeters(),
      this.loadSensors(),
      this.loadMeterModules(),
    ]);

    this.comgyDevices = [...meters, ...sensors, ...meterModules];
  }

  private get comgyObjects(): ComgyObject[] {
    if (this.comgyDevices === undefined) return [];

    const stairways: { [key: string]: ComgyStairway } = {};
    const estateUnits: { [key: string]: ComgyEstateUnit } = {};
    const signleSensors: ComgySensor[] = [];

    for (const device of this.comgyDevices) {
      const { estateUnit, stairway } = ComgyDeviceMapper.extractStairwayAndEstateUnit(
        device.details?.attributes.breadcrumbs,
      );
      if (estateUnit === undefined || stairway === undefined) {
        if (device.__typename == 'ComgyConnectableDevice' && device.details !== null) {
          signleSensors.push({
            id: device.details.id,
            name: device.details.attributes.breadcrumbs.map((breadcrumb) => breadcrumb.breadcrumb_name).join(' / '),
            sensors: [device],
            sensorsByType: { [device.intendedRole]: 1 },
            type: 'Sensor',
          });
        }
      } else {
        if (!(stairway.id.toString() in stairways)) {
          stairways[stairway.id.toString()] = {
            type: 'Stairway',
            children: [],
            name: stairway.breadcrumb_name,
            id: stairway.id.toString(),
          };
        }
        if (!(estateUnit.id.toString() in estateUnits)) {
          estateUnits[estateUnit.id.toString()] = {
            type: 'EstateUnit',
            parent: stairways[stairway.id.toString()],
            name: estateUnit.breadcrumb_name,
            sensors: [],
            sensorsByType: {},
            id: estateUnit.id.toString(),
          };
          stairways[stairway.id.toString()].children.push(estateUnits[estateUnit.id.toString()]);
        }
        if (device.__typename == 'ComgyConnectableDevice') {
          estateUnits[estateUnit.id.toString()].sensors.push(device);
          if (device.intendedRole in estateUnits[estateUnit.id.toString()].sensorsByType) {
            estateUnits[estateUnit.id.toString()].sensorsByType[device.intendedRole]++;
          } else {
            estateUnits[estateUnit.id.toString()].sensorsByType[device.intendedRole] = 1;
          }
        }
      }
    }

    //filter out objects without unbound sensors
    const stairs = Object.values(stairways);
    for (const stair of stairs) {
      stair.children = stair.children.filter((e) => e.sensors.length > 0);
    }
    return (stairs as (ComgyStairway | ComgySingleSensorGroup)[])
      .concat({
        type: 'SensorGroup',
        children: signleSensors,
        name: '',
        id: 'singleSensorGroup',
      })
      .filter((s) => s.children.length > 0)
      .flatMap((parent) => (this.comgyObjectExpanded[parent.id] ? [parent, ...parent.children] : [parent]));
  }

  private toggle(row: ComgyStairway | ComgySingleSensorGroup): void {
    this.$set(this.comgyObjectExpanded, row.id, !this.comgyObjectExpanded[row.id]);
  }

  private get rootId(): string | undefined {
    return this.customers?.first.rootDirectory.id;
  }

  private parentId(row: ComgyObject): string | undefined {
    if (!('parent' in row) || !(row.parent.id in this.comgyObjectLink)) {
      return this.rootId;
    }
    const pathItems = this.comgyObjectLink[row.parent.id]?.items ?? [];
    return pathItems[pathItems.length - 1]?.id;
  }

  private title(row: ComgyObject): string {
    if (row.type === 'Stairway') {
      return `Eingang "${row.name}" verknüpfen`;
    } else if (row.type === 'EstateUnit') {
      return `Wohnung "${row.name}" in "${row.parent?.name}" verknüpfen`;
    } else {
      return `Sensor "${row.name}" verknüpfen`;
    }
  }

  private label(row: ComgyObject): string {
    switch (row.type) {
      case 'Stairway':
        return 'Eingang';
      case 'EstateUnit':
        return 'Wohnung';
      case 'Sensor':
        return 'Einzelsensor';
      case 'SensorGroup':
        return 'Sensoren ohne Wohnung';
    }
  }

  private get sensorLinkCount(): number {
    return this.comgyObjects.filter((co) => co.type !== 'Stairway' && this.comgyObjectLink[co.id] !== undefined).length;
  }

  private async doBind(estateUnit: ComgyEstateUnit | ComgySensor, showToast = true): Promise<number> {
    const link = this.comgyObjectLink[estateUnit.id];
    const subdivisionId = link?.items[link?.items.length - 1].id;
    if (subdivisionId === undefined) return 0;

    const bindPromises = [];
    for (const device of estateUnit.sensors) {
      const deviceName =
        device.details?.attributes.breadcrumbs.find((bc) => bc.class == 'Meter')?.breadcrumb_name ?? 'Comgy Device';
      const deviceRoomName =
        device.details?.attributes.breadcrumbs.find((bc) => bc.class == 'MeterPoint')?.breadcrumb_name ?? null;

      const bindPromise = this.bindDevice(subdivisionId, device, deviceName, deviceRoomName);
      bindPromises.push(bindPromise);
    }

    await Promise.all(bindPromises);
    if (showToast) {
      await this.showBoundToast(bindPromises.length);
    }

    return bindPromises.length;
  }

  private bindDevice(
    subdivisionId: string,
    device: ConnectableDevice | ConnectedDevice,
    name: string,
    roomName: string | null,
  ): Promise<void> {
    return this.$apollo
      .mutate<
        AppCustomerComgyApiConnectDevicesConnectMutation,
        AppCustomerComgyApiConnectDevicesConnectMutationVariables
      >({
        mutation: connectMutation,
        variables: {
          input: {
            subdivisionId,
            comgyApiId: this.vendorApiId,
            deviceId: device.id,
            name,
            roomName,
            deviceType: device.type,
          },
        },
      })
      .then(({ data }) => {
        if (!data) {
          const message = `Das Gerät "${name}" konnte nicht verknüpft werden.`;
          this.ADD_TOAST_MESSAGES({
            messages: [{ text: message, class: 'error' }],
          });
          throw new Error(message);
        }

        return data;
      })
      .then((data) => {
        const newDevice = data.connectComgyDevice.device;
        device.__typename = newDevice.__typename;
        (device as ConnectedDevice).deviceMount = newDevice.deviceMount;
      });
  }

  private showBoundToast(boundCount: number): Promise<void> {
    return this.ADD_TOAST_MESSAGES({
      messages: [
        {
          text: boundCount === 1 ? `Es wurde ein Gerät verknüpft.` : `Es wurden ${boundCount} Geräte verknüpft.`,
          class: 'success',
        },
      ],
    });
  }

  private get countByType(): { color: string; value: number; label: string; key: string }[] {
    const result = {
      ComgyConnectedDevice: 0,
      ComgyConnectableDevice: 0,
      ComgyUnconnectableDevice: 0,
    };
    if (this.comgyDevices) {
      for (const device of this.comgyDevices) {
        if (device.__typename in result) result[device.__typename]++;
      }
    }
    return [
      { color: '#fe004f', value: result['ComgyUnconnectableDevice'], label: 'Nicht verbindbare Geräte', key: '1' },
      { color: '#4a4d4e', value: result['ComgyConnectableDevice'], label: 'Nicht verbundene Geräte', key: '2' },
      { color: '#66cd05', value: result['ComgyConnectedDevice'], label: 'Verbundene Geräte', key: '3' },
    ];
  }

  private onMappingUpdate(mapping: ComgyDeviceMapping): void {
    this.comgyObjectLink = mapping;

    //expand complete table
    for (const comgyObject of this.comgyObjects) {
      this.$set(this.comgyObjectExpanded, comgyObject.id, true);
    }
  }

  private async doneBindAll(bound: number): Promise<void> {
    if (bound === 0) {
      return this.ADD_TOAST_MESSAGES({
        messages: [{ text: `Es konnten keine Geräte verbunden werden.`, class: 'error' }],
      });
    }

    return this.showBoundToast(bound);
  }
}
