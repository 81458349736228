var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Neuen Zeitplan anlegen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addActivityCheckInSchedule},on:{"submitted":function($event){return _vm.onAddActivityCheckInScheduleSubmitted($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"domain-ui-input-recurring-interval","path":"schedule","label":"Konfiguration"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-headline',[_vm._v("Anwenden auf…")]),_c('p',[_vm._v("Auf welche Objekte dieser Zeitplan angewandt werden soll. Damit der Zeitplan auf ein Objekt angewandt wird, muss dieses Objekt dem ausgewählten Typen und einem der ausgewälten Objekte oder deren untergeordneten Objekte entsprechen.")])],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"treeNodeType","label":"Objekttyp","options":_vm.treeNodeTypes,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"error":formData.treeNodeType === null}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"core-input-tree-node-select-control","path":"treeNodeIds","label":"Anwenden in…","root-ids":_vm.rootIds,"transformer":_vm.pluckId,"error":formData.treeNodeIds === null}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.isValid(formData),"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}])})],1),_c('ui-cell',{attrs:{"basis":1}},[(_vm.schedules.length === 0 && !_vm.$apollo.queries.activity.loading)?_c('ui-form-message',[_vm._v("Bisher wurden keine Zeitpläne angelegt.")]):_c('ui-table-skeleton',{attrs:{"ready":!_vm.$apollo.queries.activity.loading,"column-count":5,"line-count":2,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"rows":_vm.schedules,"columns":_vm.columns},scopedSlots:_vm._u([{key:"interval",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getIntervalText(row)))]}},{key:"startDate",fn:function(ref){
var row = ref.row;
return [_c('ui-date',{attrs:{"date":row.startDate}})]}},{key:"endDate",fn:function(ref){
var row = ref.row;
return [(row.endDate === null)?[_vm._v("–")]:_c('ui-relative-time',{attrs:{"date":row.endDate,"min":"P0D","format":"L"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
var inRange = ref.inRange;
return [_c('span',{class:{ 'text-light': !inRange }},[_vm._v(_vm._s(formattedDate))])]}}],null,true)})]}},{key:"treeNodeIds",fn:function(ref){
var row = ref.row;
return [_c('ui-tooltip',{attrs:{"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"text-overflow"},[_c('ui-inline-list',{attrs:{"items":row.treeNodes.items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true)})],1)]},proxy:true},{key:"content",fn:function(){return [_c('ui-inline-list',{attrs:{"items":row.treeNodes.items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true)})]},proxy:true}],null,true)})]}},{key:"treeNodeType",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-type',{attrs:{"type":row.treeNodeType,"inline":"","plural":""}})]}},{key:"notifyOnMissedLogEntry",fn:function(ref){
var row = ref.row;
return [(row.notifyOnMissedLogEntry === true)?[_vm._v("Ja")]:[_vm._v("Nein")]]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$router.push({ name: 'AppCustomer/Activity/ActivityCheckInSchedule', params: { activityCheckInScheduleId: row.id } })}}},[_vm._v("Bearbeiten")])]}}])})]},proxy:true}])})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }