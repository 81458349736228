



























import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerNukiSmartlockTableFragment } from './__generated__/AppCustomerNukiSmartlockTableFragment';

@Component
export default class NukiSmartlockTable extends Vue {
  @ArrayProp(() => [])
  private readonly smartlocks!: AppCustomerNukiSmartlockTableFragment;

  private readonly columns: Column[] = [
    { name: 'id', label: 'Gerät' },
    { name: 'details', label: 'Details' },
    { name: 'path', label: 'Verknüpftes Objekt' },
    { name: 'integration-spot', label: '' },
    { name: 'action', label: '', align: 'right' },
  ];
}
