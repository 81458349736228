
































import { AddSpotInput, SpotRole, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import addSpot from './add-spot.gql';
import mountAufzugheldenDevice from './mount-aufzughelden-device.gql';
import {
  AppCustomerAddSpotWizardControlMutation,
  AppCustomerAddSpotWizardControlMutationVariables,
} from './__generated__/AppCustomerAddSpotWizardControlMutation';
import {
  AppCustomerMountAufzugheldenDeviceWizardControlMutation,
  AppCustomerMountAufzugheldenDeviceWizardControlMutationVariables,
} from './__generated__/AppCustomerMountAufzugheldenDeviceWizardControlMutation';

type AufzugheldenDevice = AppCustomerMountAufzugheldenDeviceWizardControlMutation['mountAufzugheldenDevice']['device'];
type FormData = Pick<AddSpotInput, 'name' | 'parentId'>;

@Component
export default class MountAufzugheldenDeviceWizardControl extends Vue {
  @StringProp(true)
  private readonly aufzugheldenApiId!: string;

  @StringProp(true)
  private readonly accountId!: string;

  @StringProp(true)
  private readonly deviceId!: string;

  @StringProp(true)
  private readonly rootDirectoryId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private get title(): string {
    return `Aufzug "${this.deviceId}" verknüpfen`;
  }

  private get rootIds(): string[] {
    return [this.rootDirectoryId];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [TreeNodeType.PropertySubdivision];
  }

  private isValidData(data: FormData): boolean {
    return isNonEmptyString(data.parentId) && isNonEmptyString(data.name);
  }

  private async executeFormAction(input: FormData): Promise<AufzugheldenDevice> {
    const spotId = await this.addSpot(input);
    const device = await this.mountAufzugheldenDevice(spotId);

    return device;
  }

  private async addSpot(input: FormData): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddSpotWizardControlMutation,
      AppCustomerAddSpotWizardControlMutationVariables
    >({
      mutation: addSpot,
      variables: { input: { ...input, role: SpotRole.AUFZUGHELDEN_DEVICE } },
    });

    if (!data) {
      throw new Error('Der Aufzug konnte nicht verknüpft werden!');
    }

    return data.addSpot.spot.id;
  }

  private async mountAufzugheldenDevice(spotId: string): Promise<AufzugheldenDevice> {
    const { data } = await this.$apollo.mutate<
      AppCustomerMountAufzugheldenDeviceWizardControlMutation,
      AppCustomerMountAufzugheldenDeviceWizardControlMutationVariables
    >({
      mutation: mountAufzugheldenDevice,
      variables: {
        input: {
          spotId,
          aufzugheldenApiId: this.aufzugheldenApiId,
          accountId: this.accountId,
          deviceId: this.deviceId,
        },
      },
    });

    if (!data) {
      throw new Error('Der Aufzug konnte nicht verknüpft werden!');
    }

    return data.mountAufzugheldenDevice.device;
  }

  private async onSubmitted(device: AufzugheldenDevice, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('aufzughelden-device-mounted', device);
  }

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }
}
