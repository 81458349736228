

















import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerEnerIqEconomicUnitTableFragment } from './__generated__/AppCustomerEnerIqEconomicUnitTableFragment';

@Component
export default class EnerIqEconomicUnitTable extends Vue {
  @ArrayProp(() => [])
  private readonly economicUnits!: AppCustomerEnerIqEconomicUnitTableFragment[];

  private readonly columns: Column[] = [
    { name: 'id', label: 'Gerät' },
    { name: 'name', label: 'Name' },
    { name: 'path', label: 'Verknüpftes Objekt' },
    { name: 'action', label: '', align: 'right' },
  ];

  private getId(economicUnit: AppCustomerEnerIqEconomicUnitTableFragment): string {
    return economicUnit.__typename === 'EnerIqConnectableEconomicUnit'
      ? economicUnit.heatingSystem.id
      : economicUnit.id;
  }

  private getName(economicUnit: AppCustomerEnerIqEconomicUnitTableFragment): string {
    return economicUnit.heatingSystem.name;
  }
}
