var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[(_vm.alertRuleOptions.length > 0)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{attrs:{"title":"Alarmregel erstellen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":function($event){return _vm.onAddWizardClick(show)}}},[_vm._v("Alarmregel erstellen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addDeployment},on:{"submitted":function($event){return _vm.onDeploymentAdded($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"ruleId","disabled":_vm.$apollo.queries.rules.loading,"options":_vm.alertRuleOptions,"option-value":"value","label":"Vorlage"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,false,4290632984)})],1):_vm._e(),(_vm.customers && _vm.count === 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Alarmregeln vorhanden")])],1):(_vm.customers)?[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.customers.first.alertRuleDeployments.items},scopedSlots:_vm._u([{key:"name.label",fn:function(){return [_vm._v("Name"),_c('br'),_vm._v("Vorlage")]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(_vm._s(row.name)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(row.rule.name))])])]}},{key:"category.label",fn:function(){return [_vm._v("Kategorie"),_c('br'),_vm._v("Benachrichtigungsverhalten")]},proxy:true},{key:"category",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-alert-category',{attrs:{"category":row.category,"inline":""}}),_c('br'),_c('domain-ui-alert-notification-behavior',{staticClass:"text-light",attrs:{"behavior":row.notificationBehavior,"inline":""}})],1)]}},{key:"enabled",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[(!row.executable)?[_vm._v("Nicht ausführbar")]:(!row.rule.enabled)?[_vm._v("Vorlage deaktiviert")]:(!row.enabled)?[_vm._v("Deaktiviert")]:[_vm._v("Aktiviert")],(row.deploymentState !== null)?[_c('br'),(!row.enabled || !row.rule.enabled || !row.executable)?_c('span',{staticClass:"text-light"},[_vm._v("Ausführung wird beendet")]):(                     row.version !== row.deploymentState.deploymentVersion                     || row.rule.version !== row.deploymentState.ruleVersion                   )?_c('span',{staticClass:"text-light"},[_vm._v("Ausführung wird aktualisiert")]):(row.deploymentState.error !== null)?_c('span',{staticClass:"text-light"},[_vm._v("Fehler: "+_vm._s(row.deploymentState.error))]):_c('ui-relative-time',{attrs:{"date":row.deploymentState.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
return [_c('span',{staticClass:"text-light"},[_vm._v("Wird "+_vm._s(displayDate.replace('vor', 'seit'))+" ausgeführt")])]}}],null,true)})]:(row.enabled && row.rule.enabled && row.executable)?[_c('br'),_c('span',{staticClass:"text-light"},[_vm._v("Ausführung wird vorbereitet")])]:_vm._e()],2)]}},{key:"alerts.label",fn:function(){return [_vm._v("# Aktive Alarme"),_c('br'),_vm._v("Letzter Alarm")]},proxy:true},{key:"alerts",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(_vm._s(row.alerts.items.length)+_vm._s(row.alerts.more ? '+' : '')),_c('br'),(row.recentAlert.items.length === 0)?_c('span',{staticClass:"text-light"},[_vm._v("–")]):(row.recentAlert.items[0].endDate !== null)?_c('ui-relative-time',{attrs:{"date":row.recentAlert.items[0].endDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
return [_c('span',{staticClass:"text-light"},[_vm._v("Endete "+_vm._s(displayDate))])]}}],null,true)}):_c('ui-relative-time',{attrs:{"date":row.recentAlert.items[0].startDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
return [_c('span',{staticClass:"text-light"},[_vm._v("Begann "+_vm._s(displayDate))])]}}],null,true)})],1)]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$router.push({                   name: 'AppCustomer/AlertRuleDeployment',                   params: { alertRuleDeploymentId: row.id },                 })}}},[_vm._v("Bearbeiten")])]}}])})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }