









































import plusIcon from '@/features/ui/assets/icons/plus.svg';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { AddServiceProvidersActivityInput } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerActivityActivityPinsViewQueryVariables } from './__generated__/AppCustomerActivityActivityPinsViewQuery';
import addServiceProviderActivity from './add-service-provider-activity.gql';
import removeServiceProviderActivity from './remove-service-provider-activity.gql';
import query from './view.gql';
import { Option } from '@/features/ui/inputs/model';
import { AppCustomerActivityActivityServiceProvidersViewQuery } from '@/features/app-customer/views/activity/activity-service-providers/__generated__/AppCustomerActivityActivityServiceProvidersViewQuery';
import {
  AppCustomerActivityActivityPinsViewAddServiceProviderMutation,
  AppCustomerActivityActivityPinsViewAddServiceProviderMutationVariables,
} from '@/features/app-customer/views/activity/activity-service-providers/__generated__/AppCustomerActivityActivityPinsViewAddServiceProviderMutation';
import { RemoveCustomerActivityActivityServiceProviderViewAddServiceProviderMutationVariables } from '@/features/app-customer/views/activity/activity-service-providers/__generated__/RemoveCustomerActivityActivityServiceProviderViewAddServiceProviderMutation';

type Row =
  AppCustomerActivityActivityServiceProvidersViewQuery['activity']['first']['serviceProviders']['items'][number];

@Component({
  apollo: {
    activity: {
      query,
      fetchPolicy: 'network-only',
      variables(this: ActivityServiceProvidersView): AppCustomerActivityActivityPinsViewQueryVariables {
        return { id: this.activityId };
      },
    },
  },
  data() {
    return { activity: undefined };
  },
})
export default class ActivityServiceProvidersView extends Vue {
  @StringProp(true)
  private readonly activityId!: string;

  private readonly serviceProviderColumns: Column[] = [
    { name: 'name', label: 'Dienstleister' },
    { name: 'firstName', label: 'Vorname' },
    { name: 'lastName', label: 'Nachname' },
    { name: 'action', label: '', align: 'right' },
  ];
  private readonly plusIcon = plusIcon;

  private readonly activity?: AppCustomerActivityActivityServiceProvidersViewQuery['activity'];

  private async addActivityServiceProvider(
    input: Pick<AddServiceProvidersActivityInput, 'serviceProviderIds'>,
  ): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerActivityActivityPinsViewAddServiceProviderMutation,
      AppCustomerActivityActivityPinsViewAddServiceProviderMutationVariables
    >({
      mutation: addServiceProviderActivity,
      variables: {
        input: {
          activityId: this.activityId,
          ...input,
        },
      },
    });

    if (!data) {
      throw new Error('Die dienstleister konnte nicht hinzugefügt werden!');
    }

    return;
  }

  private async onAddActivityServiceProviderSubmitted(hide: () => Promise<void>): Promise<void> {
    await hide();
    await this.$apollo.queries.activity.refetch({ id: this.activityId });
  }

  private async remove({ id, name }: Row): Promise<void> {
    if (!window.confirm(`Dienstleister wirklich entfernen ${name}?`)) {
      return;
    }
    const variables: RemoveCustomerActivityActivityServiceProviderViewAddServiceProviderMutationVariables = {
      input: {
        activityId: this.activityId,
        serviceProviderId: id,
      },
    };
    await this.$apollo.mutate({
      mutation: removeServiceProviderActivity,
      variables,
    });
    await this.$apollo.queries.activity.refetch({ id: this.activityId });
  }

  private get options(): Option[] {
    return (
      this.activity?.first.customer.serviceProviders.items.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? []
    );
  }
}
