













































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import { AddServiceProviderInput, EditServiceProviderInput, ServiceProviderAuthMethod } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { v4 as uuidv4 } from 'uuid';
import { Component, Vue } from 'vue-property-decorator';
import { SERVICE_PROVIDER_AUTH_METHOD_META } from './constants';
import editServiceProviderMutation from './edit-service-provider.gql';
import query from './view.gql';
import {
  AppCustomerServiceProviderViewEditServiceProvider,
  AppCustomerServiceProviderViewEditServiceProviderVariables,
} from './__generated__/AppCustomerServiceProviderViewEditServiceProvider';
import {
  AppCustomerServiceProviderViewQuery,
  AppCustomerServiceProviderViewQueryVariables,
} from './__generated__/AppCustomerServiceProviderViewQuery';
import { AppCustomerServiceProviderViewServiceProviderFragment } from './__generated__/AppCustomerServiceProviderViewServiceProviderFragment';

type ServiceProvider = AppCustomerServiceProviderViewServiceProviderFragment;

type FormDataAuth = NonNullable<AddServiceProviderInput['auth']>;

@Component({
  apollo: {
    customers: {
      query,
      variables(this: FormView): AppCustomerServiceProviderViewQueryVariables {
        return { customerId: this.customerId, serviceProviderId: this.serviceProviderId };
      },
    },
  },
  data() {
    return { serviceProviders: undefined };
  },
})
export default class FormView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly serviceProviderId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerServiceProviderViewQuery['customers'];

  private get serviceProvider(): ServiceProvider | undefined {
    const serviceProvider = this.customers?.first.serviceProviders.first;

    if (!serviceProvider) {
      return undefined;
    }

    return {
      ...serviceProvider,
    };
  }

  private get authenticationMethodOptions(): Option<ServiceProviderAuthMethod>[] {
    return Object.values(SERVICE_PROVIDER_AUTH_METHOD_META).sort((a, b) => a.label.localeCompare(b.label));
  }

  private isAuthValueInputHidden(auth?: FormDataAuth): boolean {
    if (auth?.type === ServiceProviderAuthMethod.TOKEN) {
      return true;
    }

    return false;
  }

  private constructAuthInput(auth: FormDataAuth): FormDataAuth {
    const authValue =
      this.serviceProvider?.auth.type === ServiceProviderAuthMethod.PIN && auth.type === ServiceProviderAuthMethod.TOKEN
        ? uuidv4()
        : auth.value;

    return {
      type: auth?.type,
      value: authValue,
    };
  }

  private async editServiceProvider({ auth, ...input }: Omit<EditServiceProviderInput, 'id'>): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerServiceProviderViewEditServiceProvider,
      AppCustomerServiceProviderViewEditServiceProviderVariables
    >({
      mutation: editServiceProviderMutation,
      variables: {
        input: {
          id: this.serviceProviderId,
          ...input,
          auth: auth ? this.constructAuthInput(auth) : auth,
        },
      },
    });

    if (!data) {
      throw new Error('Das Dienstleister konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Dienstleister gespeichert!', class: 'success' }],
    });
  }

  private pluckId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }

  private get options(): Option[] {
    return [
      {
        value: null,
        label: 'Aus Aktivität übernehmen',
      },
      {
        value: 'PT1H',
        label: 'Eine Stunde',
      },
      {
        value: 'PT4H',
        label: 'Vier Stunden',
      },
      {
        value: 'PT8H',
        label: 'Acht Stunden',
      },
      {
        value: 'P1D',
        label: 'Ein Tag',
      },
    ];
  }
}
