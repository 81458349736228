




































































































































































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { ALERT_NOTIFICATION_BEHAVIOR_META } from '@/features/domain-ui/alert-notification-behavior/constants';
import { DomainUiInputAlertRuleDeploymentVariableDefinitionFragment } from '@/features/domain-ui/input-alert-rule-deployment-variable/__generated__/DomainUiInputAlertRuleDeploymentVariableDefinitionFragment';
import { Option } from '@/features/ui/inputs/model';
import { EditAlertRuleDeploymentInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import editDeploymentMutation from './edit-deployment.gql';
import removeDeploymentMutation from './remove-deployment.gql';
import activeAlertsQuery from './active-alerts.gql';
import resetAlertMutation from './reset-alert.gql';
import query from './view.gql';
import { AppCustomerAlertRuleDeploymentViewDeploymentFragment } from './__generated__/AppCustomerAlertRuleDeploymentViewDeploymentFragment';
import {
  AppCustomerAlertRuleDeploymentViewEditDeploymentMutation,
  AppCustomerAlertRuleDeploymentViewEditDeploymentMutationVariables,
} from './__generated__/AppCustomerAlertRuleDeploymentViewEditDeploymentMutation';
import {
  AppCustomerAlertRuleDeploymentViewQuery,
  AppCustomerAlertRuleDeploymentViewQueryVariables,
} from './__generated__/AppCustomerAlertRuleDeploymentViewQuery';
import {
  AppCustomerAlertRuleDeploymentViewRemoveDeploymentMutation,
  AppCustomerAlertRuleDeploymentViewRemoveDeploymentMutationVariables,
} from './__generated__/AppCustomerAlertRuleDeploymentViewRemoveDeploymentMutation';
import {
  AppCustomerAlertRuleDeploymentViewActiveAlertsQuery,
  AppCustomerAlertRuleDeploymentViewActiveAlertsQueryVariables,
} from './__generated__/AppCustomerAlertRuleDeploymentViewActiveAlertsQuery';
import {
  AppCustomerAlertRuleDeploymentViewResetAlertMutation,
  AppCustomerAlertRuleDeploymentViewResetAlertMutationVariables,
} from './__generated__/AppCustomerAlertRuleDeploymentViewResetAlertMutation';

interface InitialFormData {
  deployment?: AppCustomerAlertRuleDeploymentViewDeploymentFragment;
  variables?: Record<string, unknown>;
}

interface FormData {
  input: Omit<EditAlertRuleDeploymentInput, 'variables'>;
  variables?: Record<string, unknown>;
}

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: AlertRuleDeploymentView): AppCustomerAlertRuleDeploymentViewQueryVariables {
        return { customerId: this.customerId, id: this.alertRuleDeploymentId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AlertRuleDeploymentView extends Mixins(DeviceRoleMapMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly alertRuleDeploymentId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAlertRuleDeploymentViewQuery['customers'];

  private readonly notificationBehaviorOptions = Object.values(ALERT_NOTIFICATION_BEHAVIOR_META);

  private get deployment():
    | AppCustomerAlertRuleDeploymentViewQuery['customers']['first']['alertRuleDeployments']['first']
    | undefined {
    return this.customers?.first.alertRuleDeployments.first;
  }

  private get initialData(): InitialFormData {
    if (this.customers === undefined) {
      return {};
    }

    return {
      deployment: this.customers.first.alertRuleDeployments.first,
      variables: Object.fromEntries(
        this.customers.first.alertRuleDeployments.first.variables.map(({ name, value }) => [name, value]),
      ),
    };
  }

  private get roleOptions(): Option[] {
    return (
      this.customers?.first.alertRuleDeployments.first.rule.applicableSpotRoles
        .map((role) => this.deviceRoleMap[role] ?? { name: role, label: role })
        .map(({ name, label }) => ({ value: name, label }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }

  private get rootIds(): string[] {
    return this.customers === undefined ? [] : [this.customers.first.rootDirectory.id];
  }

  private get variableDefinitions(): DomainUiInputAlertRuleDeploymentVariableDefinitionFragment[] {
    return (
      this.customers?.first.alertRuleDeployments.first.rule.variableDefinitions
        .slice()
        .sort((a, b) => (a.inputLabel ?? a.name).localeCompare(b.inputLabel ?? b.name)) ?? []
    );
  }

  private async editDeployment({ input, variables = {} }: FormData): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAlertRuleDeploymentViewEditDeploymentMutation,
      AppCustomerAlertRuleDeploymentViewEditDeploymentMutationVariables
    >({
      mutation: editDeploymentMutation,
      variables: {
        input: {
          ...input,
          variables: Object.entries(variables).map(([name, value]) => ({
            name,
            value,
          })) as EditAlertRuleDeploymentInput['variables'],
          id: this.alertRuleDeploymentId,
        },
      },
    });

    if (!data) {
      throw new Error('Alarmregel konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Alarmregel gespeichert!', class: 'success' }],
    });
  }

  private async removeDeployment(): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAlertRuleDeploymentViewRemoveDeploymentMutation,
      AppCustomerAlertRuleDeploymentViewRemoveDeploymentMutationVariables
    >({
      mutation: removeDeploymentMutation,
      variables: { input: { id: this.alertRuleDeploymentId } },
    });
    if (!data) {
      throw new Error('Alarmregel konnte nicht gelöscht werden.');
    }
  }

  private async activeAlertsSpots(): Promise<
    AppCustomerAlertRuleDeploymentViewActiveAlertsQuery['treeNodes']['first']['alerts']['items']
  > {
    const { data } = await this.$apollo.query<
      AppCustomerAlertRuleDeploymentViewActiveAlertsQuery,
      AppCustomerAlertRuleDeploymentViewActiveAlertsQueryVariables
    >({
      query: activeAlertsQuery,
      variables: { treeNodeId: this.customerId, ruleDeploymentIds: this.alertRuleDeploymentId },
    });

    if (!data) {
      return [];
    }

    return data.treeNodes.first.alerts.items ?? [];
  }

  private async resetActiveAlerts(spotId: string, ruleDeploymentId: string): Promise<void> {
    await this.$apollo.mutate<
      AppCustomerAlertRuleDeploymentViewResetAlertMutation,
      AppCustomerAlertRuleDeploymentViewResetAlertMutationVariables
    >({
      mutation: resetAlertMutation,
      variables: {
        input: { spotId, ruleDeploymentId },
      },
    });
  }

  private async onRemoved(hide: () => Promise<void>): Promise<void> {
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Alarmregel gelöscht!', class: 'success' }],
    });

    await hide();

    const spots = await this.activeAlertsSpots();

    for (const { spot } of spots) {
      if (spot) {
        await this.resetActiveAlerts(spot.id, this.alertRuleDeploymentId);
      }
    }

    if (this.customers !== undefined) {
      this.$router.replace({
        name: 'AppCustomer/Customer/AlertRuleDeployments',
        params: { customerId: this.customers.first.id },
      });
    }
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
