var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-layout',{attrs:{"use":"core-layout-default"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.activity)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-content-header',{attrs:{"headline":_vm.name,"back-link":""},on:{"back-click":function($event){return _vm.$router.go(-1)}}},[_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer' }}},[_vm._v("Kunden")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Customer', params: { customerId: _vm.activity.first.customer.id } }}},[_vm._v(_vm._s(_vm.activity.first.customer.name))]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Customer/Activities', params: { customerId: _vm.activity.first.customer.id } }}},[_vm._v("Aktivitäten")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Activity', params: { activityId: _vm.activity.first.id } }}},[_vm._v(_vm._s(_vm.activity.first.name))]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Activity/ActivityCheckInSchedules', params: { activityId: _vm.activity.first.id } }}},[_vm._v("Zeitpläne")])],1)],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel',[_c('ui-form',{attrs:{"initial-data":_vm.activity.first,"action":_vm.editActivityCheckInSchedule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"domain-ui-input-recurring-interval","path":"schedule","initial-path":"checkInSchedules.first","label":"Konfiguration","non-clearable":true}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-headline',[_vm._v("Anwenden auf…")]),_c('p',[_vm._v("Auf welche Objekte dieser Zeitplan angewandt werden soll. Damit der Zeitplan auf ein Objekt angewandt wird, muss dieses Objekt dem ausgewählten Typen und einem der ausgewälten Objekte oder deren untergeordneten Objekte entsprechen.")])],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"treeNodeType","initial-path":"checkInSchedules.first.treeNodeType","label":"Objekttyp","options":_vm.treeNodeTypes,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"clearable":false}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"core-input-tree-node-select-control","path":"treeNodeIds","initial-path":"checkInSchedules.first.treeNodes.items","label":"Anwenden in…","root-ids":_vm.rootIds,"transformer":_vm.pluckId,"error":formData.treeNodeIds === null}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-headline',[_vm._v("Benachrichtigung")])],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-switch","path":"notifyOnMissedLogEntry","initial-path":"checkInSchedules.first.notifyOnMissedLogEntry","label":"Verpasste Aktivitäten","description":"Eine Benachrichtigung erstellen, wenn eine geplante Aktivität verpasst wurde."}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || formData.treeNodeIds === null,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,false,625717523)})],1)],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }