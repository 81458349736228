







































import { VendorApiType } from '@/types/iot-portal';
import { AddUvmConsumptionApiFormInput } from '../models/add-ivm-consumption-api-form-input.model';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { AddVendorApiResponse } from '@/types/consumption-api/Add-vendor-api-response.model';
import { Observable, lastValueFrom, EMPTY } from 'rxjs';
import { RestClient } from '../../../../core/rest-client/rest-client';
import { CONSUMPTION_API_REST_CLIENT } from '../../../../core/container';
import { ConsumptionAccessCreate } from '@/types/consumption-api/consumption-access-create.model';
import { ConsumptionAccess } from '@/types/consumption-api/consumption-access.model';
import { ThirdParty } from '@/types/consumption-api/third-party.model';

@Component({
  name: 'IvmConsumptionApi',
  data(this: IvmConsumptionApi) {
    this.getThirdParties().subscribe((response) => {
      const thirdParties: ThirdParty[] = response;
      this.consumptionThirdPartyApiId = thirdParties.find((t) => t.VendorApiType == 'IvmConsumptionApi')?.Id;
    });

    return {};
  },
})
export default class IvmConsumptionApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @Inject(CONSUMPTION_API_REST_CLIENT)
  private client?: RestClient;

  private readonly isNonEmptyString = isNonEmptyString;

  private consumptionThirdPartyApiId?: string;

  private isValidData(data: Partial<AddUvmConsumptionApiFormInput>): boolean {
    return (
      this.isNonEmptyString(data.label) &&
      this.isNonEmptyString(data.thirdPartyUsername) &&
      this.isNonEmptyString(data.thirdPartyPassword)
    );
  }

  private async addIvmApi({
    label,
    thirdPartyUsername,
    thirdPartyPassword,
    thirdPartyCustomerId,
  }: AddUvmConsumptionApiFormInput): Promise<AddVendorApiResponse> {
    if (!this.consumptionThirdPartyApiId) {
      throw new Error('Cannot connect data provider: Unknown vendor api type');
    }

    const request: ConsumptionAccessCreate = {
      customerId: this.customerId,
      name: label,
      thirdPartyApiId: this.consumptionThirdPartyApiId,
      thirdPartyUsername,
      thirdPartyPassword,
      thirdPartyCustomerId,
    };
    const response: ConsumptionAccess = await lastValueFrom(
      this.client?.post<ConsumptionAccessCreate, ConsumptionAccess>(
        '/api/v1/consumption/consumption-access',
        request,
      ) ?? EMPTY,
    );

    return {
      id: response.id,
      label,
      __typename: 'IvmConsumptionApi',
      type: VendorApiType.IvmConsumptionApi,
    };
  }

  private getThirdParties(): Observable<ThirdParty[]> {
    const response = this.client?.get<ThirdParty[]>(`/api/v1/consumption/third-parties`) ?? EMPTY;

    return response;
  }
}
