var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[(!_vm.$apollo.queries.customers.loading && _vm.devices.length === 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Geräte verfügbar")])],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table-skeleton',{attrs:{"ready":!_vm.$apollo.queries.customers.loading,"column-count":4,"line-count":2,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('app-customer-viessmann-device-table',{attrs:{"devices":_vm.devices},on:{"tree-node-click":function($event){return _vm.$router.push({ name: 'AppManager/TreeNode', params: { treeNodeId: $event.id } })},"spot-click":function($event){_vm.$router.push({ name: 'AppManager/TreeNode/Spots/Spot', params: { treeNodeId: $event.path.items.slice(-1)[0].id, spotId: $event.id } })}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var device = ref.device;
return [_c('keep-alive',[(device.__typename === 'ViessmannConnectableDevice')?_c('app-customer-connect-viessmann-device-wizard-control',{attrs:{"customer-id":_vm.customerId,"viessmann-api-id":_vm.vendorApiId,"installation-id":_vm.installationId,"gateway-serial":device.gatewaySerial,"device-id":device.deviceId,"root-directory-id":_vm.customers.first.rootDirectory.id},on:{"viessmann-device-connected":function($event){return _vm.$emit('viessmann-device-connected', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{on:{"click":show}},[_vm._v("Verknüpfen")])]}}],null,true)}):_vm._e(),(device.__typename === 'ViessmannConnectedDevice')?_c('ui-flex',{attrs:{"justify":"flex-end"}},[_c('ui-cell',{attrs:{"grow":0}},[_c('app-customer-setup-viessmann-measurements-wizard-control',{attrs:{"customer-id":_vm.customerId,"viessmann-api-id":_vm.vendorApiId,"installation-id":_vm.installationId,"gateway-serial":device.gatewaySerial,"device-id":device.deviceId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{on:{"click":show}},[_vm._v("Messstellen")])]}}],null,true)})],1),_c('ui-cell',{attrs:{"grow":0}},[_c('app-customer-setup-viessmann-settings-wizard-control',{attrs:{"customer-id":_vm.customerId,"viessmann-api-id":_vm.vendorApiId,"installation-id":_vm.installationId,"gateway-serial":device.gatewaySerial,"device-id":device.deviceId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{on:{"click":show}},[_vm._v("Einstellungen")])]}}],null,true)})],1)],1):_vm._e()],1)]}}])})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }