





















import { AddAlertMailConfigurationInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import addAlertMailConfigurationMutation from './add-alert-mail-configuration.gql';
import {
  AppCustomerAddVendorApiWizardControlAlertMailConfigurationMutation,
  AppCustomerAddVendorApiWizardControlAlertMailConfigurationMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlAlertMailConfigurationMutation';

@Component
export default class AlertMailConfiguration extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private isValidData(data: Partial<AddAlertMailConfigurationInput>): boolean {
    return this.isNonEmptyString(data.label);
  }

  private async addAlertMailConfiguration({
    label,
  }: AddAlertMailConfigurationInput): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlAlertMailConfigurationMutation,
      AppCustomerAddVendorApiWizardControlAlertMailConfigurationMutationVariables
    >({
      mutation: addAlertMailConfigurationMutation,
      variables: { input: { customerId: this.customerId, label } },
    });

    if (!data) {
      throw new Error('Die Alarmierungsmail konnte nicht angelegt werden');
    }

    return data.addAlertMailConfiguration.alertMailConfiguration;
  }
}
