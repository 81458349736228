




































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import { AppCustomerActivityViewQueryVariables } from './__generated__/AppCustomerActivityViewQuery';

@Component({
  apollo: {
    activity: {
      query,
      fetchPolicy: 'network-only',
      variables(this: ActivityView): AppCustomerActivityViewQueryVariables {
        return { id: this.activityId };
      },
    },
  },
  data() {
    return { activity: undefined };
  },
})
export default class ActivityView extends Vue {
  @StringProp(true)
  private readonly activityId!: string;
}
