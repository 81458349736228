































import { ConnectViessmannDeviceInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import connectViessmannDeviceMutation from './connect-viessmann-device.gql';
import {
  AppCustomerConnectViessmannDeviceWizardControlMutation,
  AppCustomerConnectViessmannDeviceWizardControlMutationVariables,
} from './__generated__/AppCustomerConnectViessmannDeviceWizardControlMutation';

type ViessmannDevice = AppCustomerConnectViessmannDeviceWizardControlMutation['connectViessmannDevice']['device'];
type FormData = Pick<ConnectViessmannDeviceInput, 'parentId' | 'name'>;

@Component
export default class ConnectViessmannDeviceWizardControl extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly viessmannApiId!: string;

  @StringProp(true)
  private readonly installationId!: string;

  @StringProp(true)
  private readonly gatewaySerial!: string;

  @StringProp(true)
  private readonly deviceId!: string;

  @StringProp(true)
  private readonly rootDirectoryId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private get title(): string {
    return `Gerät "${this.deviceId}" an Gateway "${this.gatewaySerial}" von Heizungssystem "${this.installationId}" verknüpfen`;
  }

  private get rootIds(): string[] {
    return [this.rootDirectoryId];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [TreeNodeType.PropertySubdivision];
  }

  private async connectViessmannDevice(input: FormData): Promise<ViessmannDevice> {
    const { data } = await this.$apollo.mutate<
      AppCustomerConnectViessmannDeviceWizardControlMutation,
      AppCustomerConnectViessmannDeviceWizardControlMutationVariables
    >({
      mutation: connectViessmannDeviceMutation,
      variables: {
        input: {
          installationId: parseInt(this.installationId),
          gatewaySerial: this.gatewaySerial,
          deviceId: this.deviceId,
          name: input.name,
          parentId: input.parentId,
        },
      },
    });

    if (!data) {
      throw new Error('Das Gerät konnte nicht verknüpft werden!');
    }

    return data.connectViessmannDevice.device;
  }

  private async onSubmitted(device: ViessmannDevice, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('viessmann-device-connected', device);
  }

  private isValidData(data: FormData): boolean {
    return isNonEmptyString(data.parentId) && isNonEmptyString(data.name);
  }

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }
}
