






















import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { generateBookmarkTokenUrl } from '@/features/domain-ui/activity-tree-node-bookmark/util';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import bookmarkActivityTreeNodeMutation from './bookmark-activity-tree-node.gql';
import query from './view.gql';
import {
  AppCustomerCustomerActivityTreeNodeBookmarksViewBookmarkActivityTreeNodeMutation,
  AppCustomerCustomerActivityTreeNodeBookmarksViewBookmarkActivityTreeNodeMutationVariables,
} from './__generated__/AppCustomerCustomerActivityTreeNodeBookmarksViewBookmarkActivityTreeNodeMutation';
import {
  AppCustomerCustomerActivityTreeNodeBookmarksViewQuery,
  AppCustomerCustomerActivityTreeNodeBookmarksViewQueryVariables,
} from './__generated__/AppCustomerCustomerActivityTreeNodeBookmarksViewQuery';

type Bookmark =
  AppCustomerCustomerActivityTreeNodeBookmarksViewQuery['customers']['first']['rootDirectory']['activityBookmarks']['items'][number];

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ActivityTreeNodeBookmarksView): AppCustomerCustomerActivityTreeNodeBookmarksViewQueryVariables {
        return { customerId: this.customerId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { activityTreeNodeBookmarks: undefined };
  },
})
export default class ActivityTreeNodeBookmarksView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerCustomerActivityTreeNodeBookmarksViewQuery['customers'];

  protected get count(): number {
    return this.customers?.first.rootDirectory.activityBookmarks.count ?? 0;
  }

  private get bookmarks(): Bookmark[] {
    return this.customers?.first.rootDirectory.activityBookmarks.items ?? [];
  }

  private get loading(): boolean {
    return this.$apollo.queries.customers.loading;
  }

  private async removeBookmark(bookmark: Bookmark): Promise<void> {
    if (
      !window.confirm(
        `Sind Sie sich sicher, dass Sie den Eintragungslink für "${bookmark.treeNode.name}" deaktivieren wollen?`,
      )
    ) {
      return;
    }

    try {
      const { data } = await this.$apollo.mutate<
        AppCustomerCustomerActivityTreeNodeBookmarksViewBookmarkActivityTreeNodeMutation,
        AppCustomerCustomerActivityTreeNodeBookmarksViewBookmarkActivityTreeNodeMutationVariables
      >({
        mutation: bookmarkActivityTreeNodeMutation,
        variables: { input: { token: bookmark.token, activityIds: [], treeNodeId: bookmark.treeNode.id } },
      });

      if (!data) {
        throw new Error('No data in response. Should not happen');
      }

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Eintragungslink deaktiviert', class: 'success' }],
      });
      this.$apollo.queries.customers.refetch();
    } catch (e) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Der Eintragungslink konnte nicht deaktiviert werden!', class: 'error' }],
      });
    }
  }

  private async copyUrlToClipboard(bookmark: Bookmark): Promise<void> {
    await navigator.clipboard.writeText(generateBookmarkTokenUrl(bookmark.token));

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Eintragungslink kopiert', class: 'success' }],
    });
  }
}
