export type ViessmannMeasurementConfiguration = {
  label: string;
  name: string;
  path: string;
};

export const VIESSMANN_DEFAULT_MEASUREMENTS: ViessmannMeasurementConfiguration[] = [
  {
    label: 'Boiler Allgemeine Zulauf Temperatur',
    name: 'boilerCommonSupplyTemperature',
    path: 'heating.boiler.sensors.commonSupply',
  },
  {
    label: 'Boiler Temperatur',
    name: 'boilerTemperature',
    path: 'heating.boiler.temperature',
  },
  {
    label: 'Boiler Haupttemperatur',
    name: 'boilerMainTemperature',
    path: 'heating.boiler.temperature.main',
  },
  {
    label: 'Heizkompressoren',
    name: 'compressors',
    path: 'heating.compressors',
  },
  {
    label: 'Heißwasseraufladung',
    name: 'dhwCharging',
    path: 'heating.dhw.charging',
  },
  {
    label: 'Heißwasser Einmalaufladung',
    name: 'dhwOneTimeCharge',
    path: 'heating.dhw.oneTimeCharge',
  },
  {
    label: 'Heizwasser Primärpumpenstatus',
    name: 'dhwOneTimeCharge',
    path: 'heating.dhw.pumps.primary',
  },
  {
    label: 'Heißwasserspeicher Temperatur Unten',
    name: 'dhwHotWaterStorageBottomTemperature',
    path: 'heating.dhw.sensors.temperature.hotWaterStorage.bottom',
  },

  {
    label: 'Heißwasserspeicher Temperatur Mitte-Unten',
    name: 'dhwHotWaterStorageMidBottomTemperature',
    path: 'heating.dhw.sensors.temperature.hotWaterStorage.midBottom',
  },
  {
    label: 'Heißwasserspeicher Temperatur Mitte',
    name: 'dhwHotWaterStorageMiddleTemperature',
    path: 'heating.dhw.sensors.temperature.hotWaterStorage.middle',
  },
  {
    label: 'Heißwasserspeicher Speichertemperatur',
    name: 'dhwHotWaterStorageTemperature',
    path: 'heating.dhw.sensors.temperature.hotWaterStorage.temperature',
  },
  {
    label: 'Heißwasserspeicher Temperatur Oben',
    name: 'dhwHotWaterStorageTopTemperature',
    path: 'heating.dhw.sensors.temperature.hotWaterStorage.top',
  },
  {
    label: 'Heißwasser Auslasstemperatur',
    name: 'dhwOutletTemperature',
    path: 'heating.dhw.sensors.temperature.outlet',
  },
  {
    label: 'Hydraulischer Separator Temperatur',
    name: 'hydraulicSeparatorTemperature',
    path: 'heating.sensors.temperature.hydraulicSeparator',
  },
  {
    label: 'Angehobener Rücklauf Temperatur',
    name: 'increasedReturnTemperature',
    path: 'heating.sensors.temperature.increasedReturn',
  },
  {
    label: 'Außentemperatur',
    name: 'outsideTemperature',
    path: 'heating.sensors.temperature.outside',
  },
  {
    label: 'Rücklauftemperatur',
    name: 'returnTemperature',
    path: 'heating.sensors.temperature.return',
  },
  {
    label: 'Raumtemperatur',
    name: 'roomTemperature',
    path: 'heating.sensors.temperature.room',
  },
  {
    label: 'Zulauftemperatur',
    name: 'supplyTemperature',
    path: 'heating.sensors.temperature.supply',
  },
  {
    label: 'System Rücklauftemperatur',
    name: 'systemReturnTemperature',
    path: 'heating.sensors.temperature.systemReturn',
  },
  {
    label: 'Pufferentladung Ventil',
    name: 'bufferDischargeThreeWayValve',
    path: 'heating.sensors.valve.bufferDischargeThreeWayValve',
  },
  {
    label: 'Volumetrischer Rücklaufdurchfluss',
    name: 'volumetricFlowReturn',
    path: 'heating.sensors.volumetricFlow.return',
  },
];

export const VIESSMANN_CIRCUIT_MEASUREMENT_TEMPLATES: ViessmannMeasurementConfiguration[] = [
  {
    label: 'Heizkreis {} Raumtemperatur',
    name: 'circuitRoomTemperature{}',
    path: 'heating.circuits.{}.sensors.temperature.room',
  },
  {
    label: 'Heizkreis {} Zulauftemperatur',
    name: 'circuitSupplyTemperature{}',
    path: 'heating.circuits.{}.sensors.temperature.supply',
  },
  {
    label: 'Heizkreis {} Frostschutzstatus',
    name: 'circuitFrostProtection{}',
    path: 'heating.circuits.{}.frostprotection',
  },
  {
    label: 'Heizkreis {} Heizzeitplan',
    name: 'circuitHeatingSchedule{}',
    path: 'heating.circuits.{}.heating.schedule',
  },
  {
    label: 'Heizkreis {} Aktive Arbeitsmodi',
    name: 'circuitActiveOperatingModes{}',
    path: 'heating.circuits.{}.operating.modes.active',
  },
  {
    label: 'Heizkreis {} Aktive Programme',
    name: 'circuitActivePrograms{}',
    path: 'heating.circuits.{}.operating.programs.active',
  },
];

export const VIESSMANN_BURNER_MEASUREMENT_TEMPLATES: ViessmannMeasurementConfiguration[] = [
  {
    label: 'Brennerstarts {}',
    name: 'burnerStarts{}',
    path: 'heating.burners.{}.statistics1',
  },
  {
    label: 'Brennerstunden {}',
    name: 'burnerHours{}',
    path: 'heating.burners.{}.statistics2',
  },
  {
    label: 'Brennerstatus {}',
    name: 'burnerState{}',
    path: 'heating.burners.{}',
  },
];
