











import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerNukiApiCredentialsViewQuery,
  AppCustomerNukiApiCredentialsViewQueryVariables,
} from './__generated__/AppCustomerNukiApiCredentialsViewQuery';
import {
  AppCustomerNukiApiCredentialsViewRemoveMutation,
  AppCustomerNukiApiCredentialsViewRemoveMutationVariables,
} from './__generated__/AppCustomerNukiApiCredentialsViewRemoveMutation';
import {
  AppCustomerNukiApiCredentialsViewStartAuthenticationMutation,
  AppCustomerNukiApiCredentialsViewStartAuthenticationMutationVariables,
} from './__generated__/AppCustomerNukiApiCredentialsViewStartAuthenticationMutation';
import startAuthentication from './start-authentication.gql';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: CredentialsView): AppCustomerNukiApiCredentialsViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class CredentialsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerNukiApiCredentialsViewQuery['customers'];

  private async remove(): Promise<void> {
    if (!window.confirm('Wollen Sie diese Dienstleister-Integration von Nuki wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerNukiApiCredentialsViewRemoveMutation,
      AppCustomerNukiApiCredentialsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { nukiApiId: this.vendorApiId } },
    });

    if (!data) {
      throw new Error('Die Dienstleister-Integration von Nuki konnte nicht entfernt werden.');
    }

    const text = `Die Dienstleister-Integration "${this.customers?.first.nukiApis.first.id}" von Nuki wurde entfernt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private async reauthenticate(): Promise<void> {
    const { customerId, vendorApiId } = this;

    if (vendorApiId === undefined) {
      throw new Error('No vendor api id');
    }

    const redirectUrl = this.$router.resolve({
      name: 'AppCustomer/VendorApis/NukiApi',
      params: { customerId, vendorApiId },
    });

    const authenticationResponse = await this.$apollo.mutate<
      AppCustomerNukiApiCredentialsViewStartAuthenticationMutation,
      AppCustomerNukiApiCredentialsViewStartAuthenticationMutationVariables
    >({
      mutation: startAuthentication,
      variables: {
        input: {
          nukiApiId: vendorApiId,
          redirectUrl: redirectUrl.href,
        },
      },
    });

    if (authenticationResponse.data) {
      location.href = authenticationResponse.data.startNukiApiAuthentication.authorizationUrl;
    }
  }
}
