






































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerAufzugheldenApiViewQuery,
  AppCustomerAufzugheldenApiViewQueryVariables,
} from './__generated__/AppCustomerAufzugheldenApiViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: AufzugheldenApiView): AppCustomerAufzugheldenApiViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(this: AufzugheldenApiView, { data, stale }: ApolloQueryResult<AppCustomerAufzugheldenApiViewQuery>): void {
        if (data.customers.first.aufzugheldenApis.first.connectedDevices.count === 0 && !stale) {
          this.$router.replace({
            name: 'AppCustomer/VendorApis/AufzugheldenApi/AvailableDevices',
          });
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AufzugheldenApiView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerAufzugheldenApiViewQuery['customers'];
}
