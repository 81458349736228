





import { Component, Mixins } from 'vue-property-decorator';
import { ArrayProp, FunctionProp } from '@/util/prop-decorators';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { ComgyEstateUnit, ComgyObject, ComgySensor, ComgySingleSensorGroup, ComgyStairway } from './comgy-types';

@Component
export default class BindAll extends Mixins(Busyable) {
  @ArrayProp()
  private readonly comgyObjects!: ComgyObject[];

  @FunctionProp()
  private readonly doBind!: (estateUnit: ComgyEstateUnit | ComgySensor, showToast: boolean) => Promise<number>;

  @Busy()
  private async bindAll(): Promise<void> {
    const estateUnits = this.comgyObjects
      .filter((co): co is ComgyStairway => co.type === 'Stairway')
      .map((co) => co.children)
      .flat();
    const sensors = this.comgyObjects
      .filter((co): co is ComgySingleSensorGroup => co.type === 'SensorGroup')
      .map((co) => co.children)
      .flat();

    let bound = 0;
    for (const estateUnit of estateUnits) {
      //leave it to this method to decide whether a binding is possible or not
      bound += await this.doBind(estateUnit, false);
    }

    for (const sensor of sensors) {
      //leave it to this method to decide whether a binding is possible or not
      bound += await this.doBind(sensor, false);
    }

    this.$emit('done', bound);
  }
}
