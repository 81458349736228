

















import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAareonHeatingSystemTableFragment } from './__generated__/AppCustomerAareonHeatingSystemTableFragment';

@Component
export default class AareonHeatingSystemTable extends Vue {
  @ArrayProp(() => [])
  private readonly aareonHeatingSystems!: AppCustomerAareonHeatingSystemTableFragment[];

  private readonly columns: Column[] = [
    { name: 'id', label: 'Gerät' },
    { name: 'name', label: 'Name' },
    { name: 'path', label: 'Verknüpftes Objekt' },
    { name: 'action', label: '', align: 'right' },
  ];

  private getId(heatingSystem: AppCustomerAareonHeatingSystemTableFragment): string {
    return heatingSystem.heatingSystem.id;
  }

  private getName(heatingSystem: AppCustomerAareonHeatingSystemTableFragment): string {
    return heatingSystem.heatingSystem.name;
  }
}
