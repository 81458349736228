var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":{ name: ("Viessmann Heizungssystem " + _vm.installationId) },"action":_vm.connectViessmannInstallation,"no-reset":""},on:{"submitted":function($event){return _vm.onSubmitted($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name","error":!_vm.isNonEmptyString(formData.name)}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-array-item","array-input":"core-input-tree-node-select-control","path":"siteId","label":"Installationsort","root-ids":_vm.rootIds,"selectable-types":_vm.selectableTypes,"transformer":_vm.pluckId,"error":formData.siteId === null}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"core-input-tree-node-select-control","path":"consumerIds","label":"Versorgungsbereiche","root-ids":_vm.rootIds,"selectable-types":_vm.selectableConsumerTypes,"transformer":_vm.pluckObjectId,"error":formData.consumerIds === null}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.isValidData(formData),"busy":busy},on:{"click":submit}},[_vm._v("Verknüpfen")])],1)],1)]}}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }