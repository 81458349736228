































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditComgyApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerComgyApiCredentialsEditMutation,
  AppCustomerComgyApiCredentialsEditMutationVariables,
} from './__generated__/AppCustomerComgyApiCredentialsEditMutation';
import {
  AppCustomerComgyApiCredentialsQuery,
  AppCustomerComgyApiCredentialsQueryVariables,
} from './__generated__/AppCustomerComgyApiCredentialsQuery';
import {
  AppCustomerComgyApiCredentialsRemoveMutation,
  AppCustomerComgyApiCredentialsRemoveMutationVariables,
} from './__generated__/AppCustomerComgyApiCredentialsRemoveMutation';

type FormData = Pick<EditComgyApiInput, 'label' | 'token'>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: CredentialsView): AppCustomerComgyApiCredentialsQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class CredentialsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerComgyApiCredentialsQuery['customers'];

  private readonly isNonEmptyString = isNonEmptyString;

  private isValid(formData: FormData): boolean {
    return isNonEmptyString(formData.label) && isNonEmptyString(formData.token);
  }

  private async edit(formData: FormData): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerComgyApiCredentialsEditMutation,
      AppCustomerComgyApiCredentialsEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          ...formData,
          comgyApiId: this.vendorApiId,
        },
      },
    });

    if (!data) {
      throw new Error('Die Zugangsdaten konnten nicht gespeichert werden.');
    }

    const text = 'Die Zugangsdaten wurden aktualisiert.';
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    if (!window.confirm('Wollen Sie diese Dienstleister-Integration von Comgy wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerComgyApiCredentialsRemoveMutation,
      AppCustomerComgyApiCredentialsRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { comgyApiId: this.vendorApiId } },
    });

    if (!data) {
      throw new Error('Die Dienstleister-Integration von Comgy konnte nicht entfernt werden.');
    }

    const text = `Die Dienstleister-Integration von Comgy mit dem Namen "${this.customers?.first.comgyApis.first.label}" wurde entfernt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }
}
