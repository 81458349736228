











import {
  VIESSMANN_API_AUTH_URL,
  VIESSMANN_API_CLIENT_ID,
  VIESSMANN_API_SCOPE,
} from '@/features/app-customer/components/add-vendor-api-wizard-control/viessmann/constants';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerViessmannApiCredentialsViewQuery,
  AppCustomerViessmannApiCredentialsViewQueryVariables,
} from './__generated__/AppCustomerViessmannApiCredentialsViewQuery';
import {
  AppCustomerViessmannApiCredentialsViewRemoveMutation,
  AppCustomerViessmannApiCredentialsViewRemoveMutationVariables,
} from './__generated__/AppCustomerViessmannApiCredentialsViewRemoveMutation';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: CredentialsView): AppCustomerViessmannApiCredentialsViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class CredentialsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerViessmannApiCredentialsViewQuery['customers'];

  private async remove(): Promise<void> {
    if (!window.confirm('Wollen Sie diese Dienstleister-Integration von Viessmann wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerViessmannApiCredentialsViewRemoveMutation,
      AppCustomerViessmannApiCredentialsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { viessmannApiId: this.vendorApiId } },
    });

    if (!data) {
      throw new Error('Die Dienstleister-Integration von Viessmann konnte nicht entfernt werden.');
    }

    const text = `Die Dienstleister-Integration "${this.customers?.first.viessmannApis.first.id}" von Viessmann wurde entfernt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private async reauthenticate(): Promise<void> {
    const { customerId, vendorApiId } = this;

    if (vendorApiId === undefined) {
      throw new Error('No vendor api id');
    }

    const redirectUri = this.$router.resolve({
      name: 'AppCustomer/ViessmannAuthCallback',
      params: { customerId, vendorApiId },
    });

    const qs = new URLSearchParams({
      client_id: VIESSMANN_API_CLIENT_ID,
      redirect_uri: window.location.origin + redirectUri.href,
      response_type: 'code',
      code_challenge: '2e21faa1-db2c-4d0b-a10f-575fd372bc8c-575fd372bc8c',
      scope: VIESSMANN_API_SCOPE,
    });
    location.href = `${VIESSMANN_API_AUTH_URL}?${qs}`;
  }
}
