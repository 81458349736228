var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.devices},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow",staticStyle:{"font-family":"monospace"}},[_vm._v(_vm._s(row.deviceId))])]}},{key:"address",fn:function(ref){
var row = ref.row;
return [(row.details)?_c('div',{staticClass:"text-overflow"},[_vm._v(_vm._s(row.details.address === '' ? '–' : row.details.address)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(row.details.postcode)+" "+_vm._s(row.details.city === '' ? '–' : row.details.city))])]):[_vm._v("–")]]}},{key:"path",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'AufzugheldenConnectedDevice')?_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.deviceMount.spot.path.items},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}}):(row.__typename === 'AufzugheldenConnectableDevice')?[_c('em',[_vm._v("Nicht verknüpft")])]:[_c('em',[_vm._v("Kann nicht verknüpft werden")])]]}},{key:"integration",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'AufzugheldenConnectedDevice')?_c('domain-ui-spot-info',{attrs:{"spot":row.deviceMount.spot},on:{"click":function($event){return _vm.$emit('spot-click', row.deviceMount.spot)}}}):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_vm._t("action",null,{"device":row})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }