var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activity)?_c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{ref:"wizard",attrs:{"title":"Dienstleiste Hinzufügen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-button',{attrs:{"front":"","icon":_vm.plusIcon},on:{"click":show}},[_vm._v("Dienstleister Hinzufügen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addActivityServiceProvider},on:{"submitted":function($event){return _vm.onAddActivityServiceProviderSubmitted(hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"serviceProviderIds","path":"serviceProviderIds","label":"Dienstleister","clearable":false,"options":_vm.options,"reduce":function (ref) {
	var value = ref.value;

	return value;
}}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,false,172373011)})],1),(_vm.activity.first.serviceProviders.items.length)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"columns":_vm.serviceProviderColumns,"rows":_vm.activity.first.serviceProviders.items},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{attrs:{"secondary":""},on:{"click":function($event){return _vm.remove(row)}}},[_vm._v("Entfernen")])]}}],null,false,2877360631)})],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Es sind keine Dienstleister vorhanden")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }