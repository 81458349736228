












































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditEnerIqApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerEnerIqApiSettingsViewEditMutation,
  AppCustomerEnerIqApiSettingsViewEditMutationVariables,
} from './__generated__/AppCustomerEnerIqApiSettingsViewEditMutation';
import {
  AppCustomerEnerIqApiSettingsViewQuery,
  AppCustomerEnerIqApiSettingsViewQueryVariables,
} from './__generated__/AppCustomerEnerIqApiSettingsViewQuery';
import {
  AppCustomerEnerIqApiSettingsViewRemoveMutation,
  AppCustomerEnerIqApiSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerEnerIqApiSettingsViewRemoveMutation';
import { CoreInputTreeNodeSelectControlTreeNodeFragment } from '@/features/core/components/input-tree-node-select-control/__generated__/CoreInputTreeNodeSelectControlTreeNodeFragment';

type FormData = Pick<
  EditEnerIqApiInput,
  | 'maxConnectedHeatingSystems'
  | 'yearOfConstructionAttributeDefinitionId'
  | 'yearOfRestorationOfFacadeAttributeDefinitionId'
  | 'yearOfRestorationOfRoofAttributeDefinitionId'
  | 'yearOfRestorationOfWindowsAttributeDefinitionId'
  | 'treeNodeIds'
>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerEnerIqApiSettingsViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerEnerIqApiSettingsViewQuery['customers'];

  private isValid({ maxConnectedHeatingSystems }: FormData): boolean {
    const max = Number.parseInt(String(maxConnectedHeatingSystems), 10);

    return !Number.isNaN(max);
  }

  private async edit({ maxConnectedHeatingSystems, ...formData }: FormData): Promise<void> {
    const enerIqApiId = this.customers?.first.enerIqApi?.id;
    if (enerIqApiId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerEnerIqApiSettingsViewEditMutation,
      AppCustomerEnerIqApiSettingsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          ...formData,
          maxConnectedHeatingSystems: Number(maxConnectedHeatingSystems),
          enerIqApiId,
        },
      },
    });

    if (!data) {
      throw new Error('Die Einstellungen konnten nicht gespeichert werden');
    }

    const text = `Einstellungen der EnerIQ-Integration wurden gespeichert`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    const enerIqApiId = this.customers?.first.enerIqApi?.id;
    if (enerIqApiId === undefined) {
      return;
    }

    if (!window.confirm('Wollen Sie die EnerIQ-Integration wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerEnerIqApiSettingsViewRemoveMutation,
      AppCustomerEnerIqApiSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { enerIqApiId } },
    });

    if (!data) {
      throw new Error('Die EnerIQ-Integration konnte nicht entfernt werden');
    }

    const text = `Die EnerIQ-Integration wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private pluckId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }

  private transformTreeNodeIds(ids: string[]): { [key: string]: string }[] {
    if (!ids) {
      return [];
    }
    return ids.map((id) => ({ id }));
  }

  private get filterTreeNodes(): (T: CoreInputTreeNodeSelectControlTreeNodeFragment) => boolean {
    return (treeNode) => {
      return treeNode.heatingSystems.count > 0;
    };
  }
}
