var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customers)?_c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{attrs:{"title":"Forms anlegen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Formular anlegen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addForm},on:{"submitted":function($event){return _vm.onSubmitted($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"title","label":"Titel"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,false,2593622453)})],1),_c('ui-cell',{attrs:{"basis":1}},[(!_vm.loading && _vm.forms.length === 0)?_c('ui-form-message',[_vm._v("Bisher wurden keine Formulare angelegt.")]):_c('ui-table-skeleton',{attrs:{"ready":!_vm.loading,"column-count":2},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.forms},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('ui-tooltip',{attrs:{"hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(row.title))]},proxy:true},{key:"content",fn:function(){return [_vm._v(_vm._s(row.description))]},proxy:true}],null,true)})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$router.push({ name: 'AppCustomer/Form', params: { formId: row.id } })}}},[_vm._v("Bearbeiten")])]}}],null,false,2383375615)})]},proxy:true}],null,false,3387572849)})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }