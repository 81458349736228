


































import { RootGetter } from '@/features/core/store';
import { AddAareonApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import addAareonApiMutation from './add-aareon-api.gql';
import query from './view.gql';
import {
  AppCustomerAddVendorApiWizardControlAareonApiMutation,
  AppCustomerAddVendorApiWizardControlAareonApiMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlAareonApiMutation';
import {
  AppCustomerAddVendorApiWizardControlAareonApiViewQuery,
  AppCustomerAddVendorApiWizardControlAareonApiViewQueryVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlAareonApiViewQuery';

interface FormData {
  kafkaTopic: string;
}

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: AareonApi): AppCustomerAddVendorApiWizardControlAareonApiViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AareonApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootGetter
  private readonly admin!: boolean;

  private readonly customers?: AppCustomerAddVendorApiWizardControlAareonApiViewQuery['customers'];

  private readonly isNonEmptyString = isNonEmptyString;

  private get initialData(): Partial<FormData> {
    return {
      kafkaTopic: 'vodafone_' + (this.customers?.first?.externalId ?? ''),
    };
  }

  private async addAareonApi(input: AddAareonApiInput): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlAareonApiMutation,
      AppCustomerAddVendorApiWizardControlAareonApiMutationVariables
    >({
      mutation: addAareonApiMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Die Aareon-Integration konnte nicht aktiviert werden');
    }

    return data.addAareonApi.aareonApi;
  }
}
