






















































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditFormInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import editFormMutation from './edit-form.gql';
import query from './view.gql';
import {
  AppCustomerFormViewEditForm,
  AppCustomerFormViewEditFormVariables,
} from './__generated__/AppCustomerFormViewEditForm';
import { AppCustomerFormViewQuery, AppCustomerFormViewQueryVariables } from './__generated__/AppCustomerFormViewQuery';
import { pluckIds } from '@/util/pluck-id';

type Form = AppCustomerFormViewQuery['customers']['first']['forms']['first'];

@Component({
  apollo: {
    customers: {
      query,
      variables(this: FormView): AppCustomerFormViewQueryVariables {
        return { customerId: this.customerId, formId: this.formId };
      },
    },
  },
  data() {
    return { forms: undefined, pluckIds };
  },
})
export default class FormView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly formId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerFormViewQuery['customers'];

  private get form(): Form | undefined {
    return this.customers?.first.forms.first;
  }

  private async editForm(input: Omit<EditFormInput, 'id'>): Promise<void> {
    const { data } = await this.$apollo.mutate<AppCustomerFormViewEditForm, AppCustomerFormViewEditFormVariables>({
      mutation: editFormMutation,
      variables: {
        input: {
          ...input,
          id: this.formId,
        },
      },
    });

    if (!data) {
      throw new Error('Das Formular konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Formular gespeichert!', class: 'success' }],
    });
  }
}
