var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customers)?_c('div',[_c('ui-flex',{attrs:{"direction":"column"}},[_c('ui-cell',[_c('ui-panel',[_c('ui-table-skeleton',{attrs:{"ready":_vm.ready,"column-count":3,"line-count":1,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-flex',{attrs:{"stretch":""}},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Geräte nach Status")]},proxy:true}],null,false,732113160)})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-grid',{attrs:{"min-column-width":90,"columnGap":10,"rowGap":0,"fit":""}},_vm._l((_vm.countByType),function(item){return _c('span',{key:item.key,staticClass:"text-light",style:({ color: item.color })},[_vm._v(_vm._s(item.label)+": "+_vm._s(item.value))])}),0)],1),_c('ui-cell',{attrs:{"basis":1,"align-self":"flex-end"}},[_c('ui-stacked-horizontal-chart',{attrs:{"points":_vm.countByType}})],1)],1)]},proxy:true}],null,false,1767377671)})],1)],1),_c('ui-cell',[_c('ui-panel',[_c('ui-table-skeleton',{attrs:{"ready":_vm.ready,"column-count":6,"line-count":2,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('mapping-proposal',{attrs:{"customer-id":_vm.customerId,"vendor-api-id":_vm.vendorApiId,"comgy-devices":_vm.comgyDevices},on:{"update":_vm.onMappingUpdate}}),_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.comgyObjects},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var row = ref.row;
return [(row.children !== undefined)?_c('ui-toggle-expand',{attrs:{"right":"","expanded":_vm.comgyObjectExpanded[row.id]},on:{"toggle":function($event){return _vm.toggle(row)}}}):_vm._e()]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.label(row)))])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(row.name))])]}},{key:"sensors",fn:function(ref){
var row = ref.row;
return [(row.type !== 'Stairway')?[_vm._l((row.sensorsByType),function(count,type){return [_c('ui-flex',{attrs:{"items":"center","nowrap":""}},[_c('ui-cell',{attrs:{"grow":0}},[(_vm.deviceRoleMap[type])?_c('mini-sensor',{attrs:{"icon":_vm.deviceRoleMap[type].icons.sm,"counter":count}}):_c('mini-sensor',{attrs:{"counter":count,"icon":_vm.unknown}})],1)],1)]})]:_c('span')]}},{key:"link",fn:function(ref){
var row = ref.row;
return [(_vm.comgyObjectLink[row.id] !== undefined)?_c('ui-flex',{attrs:{"items":"center","nowrap":""}},[_c('ui-cell',[_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":_vm.comgyObjectLink[row.id].items},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})],1),_c('ui-cell',{staticClass:"text-overflow"},[_c('keep-alive',[_c('app-customer-map-comgy-object-wizard-control',{attrs:{"comgy-api-id":_vm.vendorApiId,"title":_vm.title(row),"selectableTypes":[row.children === undefined ? 'PropertySubdivision' : 'Property'],"root-directory-id":_vm.parentId(row)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{on:{"click":show}},[_vm._v("ändern")])]}}],null,true),model:{value:(_vm.comgyObjectLink[row.id]),callback:function ($$v) {_vm.$set(_vm.comgyObjectLink, row.id, $$v)},expression:"comgyObjectLink[row.id]"}})],1)],1)],1):[_c('keep-alive',[_c('app-customer-map-comgy-object-wizard-control',{attrs:{"comgy-api-id":_vm.vendorApiId,"title":_vm.title(row),"selectableTypes":[row.children === undefined ? 'PropertySubdivision' : 'Property'],"root-directory-id":_vm.parentId(row)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{on:{"click":show}},[_vm._v(_vm._s(row.children === undefined ? 'Wohnung' : 'Objekt')+" zum Verknüpfen auswählen")])]}}],null,true),model:{value:(_vm.comgyObjectLink[row.id]),callback:function ($$v) {_vm.$set(_vm.comgyObjectLink, row.id, $$v)},expression:"comgyObjectLink[row.id]"}})],1)]]}},{key:"connect",fn:function(ref){
var row = ref.row;
return [(row.type !== 'Stairway' && _vm.comgyObjectLink[row.id] !== undefined)?_c('ui-link',{on:{"click":function($event){return _vm.doBind(row)}}},[_vm._v(_vm._s(row.sensors.length === 1 ? 'Sensor verbinden' : 'Sensoren verbinden'))]):_vm._e()]}}],null,false,3351217792)})]},proxy:true}],null,false,3960511777)}),_c('bind-all',{directives:[{name:"show",rawName:"v-show",value:(_vm.sensorLinkCount > 0),expression:"sensorLinkCount > 0"}],attrs:{"comgy-objects":_vm.comgyObjects,"do-bind":_vm.doBind},on:{"done":_vm.doneBindAll}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }