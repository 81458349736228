














import { generateDomId } from '@/util/dom-id';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import addViessmannApiMutation from './add-viessmann-api.gql';
import { VIESSMANN_API_AUTH_URL, VIESSMANN_API_CLIENT_ID, VIESSMANN_API_SCOPE } from './constants';
import {
  AppCustomerAddViessmannApiMutation,
  AppCustomerAddViessmannApiMutationVariables,
} from './__generated__/AppCustomerAddViessmannApiMutation';

@Component
export default class ViessmannApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private async initAuth(): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddViessmannApiMutation,
      AppCustomerAddViessmannApiMutationVariables
    >({
      mutation: addViessmannApiMutation,
      variables: {
        input: {
          customerId: this.customerId,
          refreshToken: '',
          label: `AUTHORIZATION REQUESTED ${generateDomId()}`,
        },
      },
    });

    const viessmannApiId = data?.addViessmannApi.viessmannApi.id;

    if (!viessmannApiId) {
      throw new Error('No vendor api id');
    }

    const redirectUri = this.$router.resolve({
      name: 'AppCustomer/ViessmannAuthCallback',
      params: { customerId: this.customerId, vendorApiId: viessmannApiId },
    });

    const qs = new URLSearchParams({
      client_id: VIESSMANN_API_CLIENT_ID,
      redirect_uri: window.location.origin + redirectUri.href,
      response_type: 'code',
      code_challenge: '2e21faa1-db2c-4d0b-a10f-575fd372bc8c-575fd372bc8c',
      scope: VIESSMANN_API_SCOPE,
    });
    location.href = `${VIESSMANN_API_AUTH_URL}?${qs}`;
  }
}
