


















































































































































































































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { ATTRIBUTE_CONFIGURATION_TYPE_META } from '@/features/domain-ui/attribute-configuration-type/constants';
import { TREE_NODE_TYPE_META } from '@/features/domain-ui/tree-node-type/constants';
import { Option } from '@/features/ui/inputs/model';
import { AttributeConfigurationType, EditAttributeDefinitionInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import editAttributeDefinitionMutation from './edit-attribute-definition.gql';
import removeAttributeDefinitionMutation from './remove-attribute-definition.gql';
import query from './view.gql';
import {
  AppCustomerAttributeDefinitionViewEditAttributeDefinition,
  AppCustomerAttributeDefinitionViewEditAttributeDefinitionVariables,
} from './__generated__/AppCustomerAttributeDefinitionViewEditAttributeDefinition';
import {
  AppCustomerAttributeDefinitionViewQuery,
  AppCustomerAttributeDefinitionViewQueryVariables,
} from './__generated__/AppCustomerAttributeDefinitionViewQuery';
import {
  AppCustomerAttributeDefinitionViewRemoveAttributeDefinition,
  AppCustomerAttributeDefinitionViewRemoveAttributeDefinitionVariables,
} from './__generated__/AppCustomerAttributeDefinitionViewRemoveAttributeDefinition';
import plusIcon from '@/features/ui/assets/icons/plus.svg';
import minusIcon from '@/features/ui/assets/icons/minus.svg';
import SpotsQuery from './attribute-definitions-spots.gql';
import { ApolloQueryResult } from 'apollo-client';
import { Calculation } from '@/features/core/util/metric-calculations';
import {
  AttributeDefinitionsSpotsQuery,
  AttributeDefinitionsSpotsQueryVariables,
  AttributeDefinitionsSpotsQuery_treeNodes_first_Directory_spots,
} from './__generated__/AttributeDefinitionsSpotsQuery';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { CoreTreeNodeBarControlPathQuery_treeNodes_items } from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlPathQuery';
import { startCase } from 'lodash';

interface FormData {
  name: string;
  description?: string;
  calculations: Calculation;
}

type Spots = AttributeDefinitionsSpotsQuery_treeNodes_first_Directory_spots['items'];

@Component({
  apollo: {
    attributeDefinitions: {
      query,
      variables(this: AttributeDefinitionView): AppCustomerAttributeDefinitionViewQueryVariables {
        return { id: this.attributeDefinitionId };
      },
      result(
        this: AttributeDefinitionView,
        { data }: ApolloQueryResult<AppCustomerAttributeDefinitionViewQuery>,
      ): void {
        if (data.attributeDefinitions.first.configuration.__typename === 'CalculationAttributeConfiguration') {
          const calculations = data.attributeDefinitions.first.configuration.calculations;
          if (calculations) {
            this.manufacturer = calculations.manufacturer as string;
            this.roles = this.getDeviceRoles(calculations.deviceRole as []);
          }

          this.rootIds = this.customerId ? [this.customerId] : [];
        }
      },
    },
    treeNodes: {
      query: SpotsQuery,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip(this: AttributeDefinitionView): boolean {
        return !this.attributeDefinitions;
      },
      variables(this: AttributeDefinitionView): AttributeDefinitionsSpotsQueryVariables {
        return this.treeNodesVariables;
      },
      result(this: AttributeDefinitionView, { data, loading }: ApolloQueryResult<AttributeDefinitionsSpotsQuery>) {
        if (!loading) {
          this.spots = data.treeNodes.first.__typename === 'RootDirectory' ? data.treeNodes.first.spots.items : [];
          this.setManufacturerAndRoleOptions();
          this.rootIds = this.customerId ? [this.customerId] : [];
        }
      },
    },
  },
  data() {
    return { attributeDefinitions: undefined, spots: undefined, treeNodes: undefined };
  },
})
export default class AttributeDefinitionView extends Mixins(DeviceRoleMapMixin) {
  @StringProp(true)
  private readonly attributeDefinitionId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly attributeDefinitions?: AppCustomerAttributeDefinitionViewQuery['attributeDefinitions'];

  private readonly emptyArray = [];

  private spots?: Spots;

  private readonly treeNodes?: AttributeDefinitionsSpotsQuery['treeNodes'];

  private manufacturer?: string;

  private roles?: string[];

  private readonly plusIcon = plusIcon;

  private readonly minusIcon = minusIcon;

  private showMoreCalculationFields = false;

  private manufacturerOptions: Option[] = [];

  private deviceTypeOptions: Option[] = [];

  private metricOptions: Option[] = [];

  private calculationFactor: string | null = null;

  private rootIds: string[] = [];

  // tree node select control expects an array of objects.
  // convert array of strings into array of objects.
  private transformSpotFilterIds(ids: string[]): { [key: string]: string }[] {
    if (!ids) {
      return [];
    }
    return ids.map((id) => ({ id }));
  }

  private isValidData(data: FormData): boolean {
    if (this.isCalculationType) {
      if (!data.calculations) {
        return false;
      }
      const { manufacturer, deviceRole, metricName, calculationType, calculationFactor, resultUnit, spotFilterIds } =
        data.calculations;
      if (
        !manufacturer ||
        !deviceRole ||
        !metricName ||
        !calculationType ||
        !calculationFactor ||
        !resultUnit ||
        !spotFilterIds ||
        spotFilterIds.length === 0
      ) {
        return false;
      }
    }

    return true;
  }

  private get attributeTypeOptions(): Option<AttributeConfigurationType>[] {
    return Object.values(ATTRIBUTE_CONFIGURATION_TYPE_META).sort((a, b) => a.label.localeCompare(b.label));
  }

  private get isCalculationType(): boolean {
    return (
      this.attributeDefinitions?.first.configuration.__typename ===
      AttributeConfigurationType.CalculationAttributeConfiguration
    );
  }

  private get treeNodesVariables(): AttributeDefinitionsSpotsQueryVariables {
    const customerId = this.attributeDefinitions?.first.customer.id;
    return {
      treeNodeId: customerId || '',
    };
  }

  private setManufacturerAndRoleOptions(): void {
    if (this.treeNodes?.first.__typename === 'RootDirectory') {
      const manufacturers = this.treeNodes.first.manufacturers.manufacturerAggregations;
      const roles = this.treeNodes.first.roles.roleAggregations;

      this.manufacturerOptions = manufacturers
        .map(({ manufacturer }) => {
          const label = manufacturer === 'UNKNOWN' ? 'Unbekannt' : manufacturer;
          return {
            label,
            value: manufacturer,
          };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });

      this.deviceTypeOptions = roles
        .map(({ role }) => {
          return {
            label: this.deviceRoleMap[role] ? this.deviceRoleMap[role].label : startCase(role),
            value: role,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      this.setMetricOptions();
    }
  }

  private setMetricOptions(): void {
    const uniqueMetrics = new Set();
    let spots = this.spots;

    // filter spots by manufacturer if one was selected
    if (this.manufacturer) {
      spots = spots?.filter((spot) => spot.activeDeviceMount?.manufacturer === this.manufacturer);
    }

    // filter spots by roles if one or more were selected
    if (this.roles && this.roles.length > 0) {
      spots = spots?.filter((spot) => this.roles?.includes(spot.role));
    }

    spots?.forEach((spot) => {
      spot.metrics.forEach((metric) => {
        uniqueMetrics.add(metric.name);
      });
    });

    this.metricOptions = Array.from(uniqueMetrics).map((metric) => ({
      label: metric as string,
      value: metric as string,
    }));
  }

  private get customerId(): string | undefined {
    return this.attributeDefinitions?.first.customer.id;
  }

  private get treeNodeTypeOptions(): Option<TreeNodeType>[] {
    return Object.values(TREE_NODE_TYPE_META)
      .map(({ value, labelPlural }) => ({ value, label: labelPlural }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private get metricValueOptions(): Option[] {
    return [
      { value: 'first', label: 'First' },
      { value: 'current', label: 'Current' },
      { value: 'last', label: 'Last' },
    ];
  }

  private get calculationTypes(): Option[] {
    return [
      { value: '+', label: '+' },
      { value: '-', label: '-' },
      { value: '*', label: '*' },
      { value: '/', label: '/' },
    ];
  }

  private refetchTreeNodes(): void {
    this.$apollo.queries.treeNodes.refetch({
      ...this.treeNodesVariables,
      manufacturers: this.manufacturer ? [this.manufacturer] : null,
      roles: this.roles && this.roles.length > 0 ? this.roles : null,
    });
  }

  private onUpdateManufacturer(manufacturer: string): void {
    this.manufacturer = manufacturer;
    this.refetchTreeNodes();
  }

  private getDeviceRoles(roles: Option[] | string[]): string[] {
    const result: string[] = [];

    roles.forEach((role) => {
      if (typeof role === 'string') {
        result.push(role);
      } else {
        result.push(role.value as 'string');
      }
    });

    return result;
  }

  private onUpdateDeviceTypes(deviceTypes: Option[] | string[]): void {
    this.roles = this.getDeviceRoles(deviceTypes);
    this.refetchTreeNodes();
  }

  private async editAttributeDefinition(input: EditAttributeDefinitionInput): Promise<void> {
    const payload = { ...input, id: this.attributeDefinitionId };
    payload.treeNodeTypes = [TreeNodeType.Spot];
    const { data } = await this.$apollo.mutate<
      AppCustomerAttributeDefinitionViewEditAttributeDefinition,
      AppCustomerAttributeDefinitionViewEditAttributeDefinitionVariables
    >({
      mutation: editAttributeDefinitionMutation,
      variables: { input: payload },
    });

    if (!data) {
      throw new Error('Attribut konnte nicht gespeichert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Attribut gespeichert!', class: 'success' }],
    });
  }

  private async removeAttributeDefinition(): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAttributeDefinitionViewRemoveAttributeDefinition,
      AppCustomerAttributeDefinitionViewRemoveAttributeDefinitionVariables
    >({
      mutation: removeAttributeDefinitionMutation,
      variables: { input: { id: this.attributeDefinitionId } },
    });

    if (!data) {
      throw new Error('Attribut konnte nicht entfernt werden.');
    }
  }

  private async onAttributeDefinitionRemoved(hide: () => Promise<void>): Promise<void> {
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Attribut gelöscht!', class: 'success' }],
    });

    await hide();

    this.$router.back();
  }

  private equalsName(name: string): boolean {
    return name === this.attributeDefinitions?.first.configuration.name;
  }

  private toggleSecondaryFields(): void {
    this.showMoreCalculationFields = !this.showMoreCalculationFields;
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }

  private get selectedDeviceTypes(): string[] {
    const selectedDeviceTypes = (this.$refs.deviceTypes as HTMLInputElement).value;
    if (!selectedDeviceTypes || !Array.isArray(selectedDeviceTypes)) {
      return [];
    }
    return selectedDeviceTypes.map((deviceRole) => deviceRole.value);
  }

  private get selectedManufacturerAndRoleTreeNodeIds(): (string | undefined)[] {
    const selectedSpots = this.spots?.filter(
      (spot) => this.roles?.includes(spot.role) && spot.activeDeviceMount?.manufacturer === this.manufacturer,
    );

    return selectedSpots?.map((spot) => spot.property?.node?.id) ?? [];
  }

  private get filter(): (T: CoreTreeNodeBarControlPathQuery_treeNodes_items) => boolean {
    return (treeNode) => {
      const childrenItems: string[] = treeNode.children?.items.map((item) => item.id);
      if (this.selectedManufacturerAndRoleTreeNodeIds.length === 0) {
        return true;
      }
      if (
        this.selectedManufacturerAndRoleTreeNodeIds.includes(treeNode.id) ||
        (childrenItems &&
          childrenItems.length > 0 &&
          childrenItems.some((item) => this.selectedManufacturerAndRoleTreeNodeIds.includes(item)))
      ) {
        return true;
      } else {
        return false;
      }
    };
  }

  @Watch('attributeDefinitions')
  private onAttributeDefinitionsChange(val: AppCustomerAttributeDefinitionViewQuery['attributeDefinitions']): void {
    if (val.first?.configuration?.__typename === 'CalculationAttributeConfiguration') {
      // pre-select "Spots" (Devices)
      if (this.attributeDefinitions) {
        this.attributeDefinitions.first.configuration.treeNodeTypes = [TreeNodeType.Spot];
      }

      // if there was additional fields display them
      const calculations = val.first?.configuration?.calculations;
      if (calculations && calculations.calculationType2) {
        this.showMoreCalculationFields = true;
      }
    }
  }
}
