













































import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { AddFormInput } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppCustomerCustomerFormsViewAddFormMutation,
  AppCustomerCustomerFormsViewAddFormMutationVariables,
} from './__generated__/AppCustomerCustomerFormsViewAddFormMutation';
import {
  AppCustomerCustomerFormsViewQuery,
  AppCustomerCustomerFormsViewQueryVariables,
} from './__generated__/AppCustomerCustomerFormsViewQuery';
import addFormMutation from './add-form.gql';
import query from './view.gql';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';

type Form = AppCustomerCustomerFormsViewQuery['customers']['first']['forms']['items'][number];

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: FormsView): AppCustomerCustomerFormsViewQueryVariables {
        return { id: this.customerId, take: this.take, skip: this.skip };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class FormsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private customers?: AppCustomerCustomerFormsViewQuery['customers'];

  private readonly columns: Column[] = [
    { name: 'title', label: 'title' },
    { name: 'action', label: '', align: 'right' },
  ];

  protected get count(): number {
    return this.customers?.first.forms.count ?? 0;
  }

  private get forms(): Form[] {
    return this.customers?.first.forms.items ?? [];
  }

  private get loading(): boolean {
    return this.$apollo.queries.customers.loading;
  }

  private async addForm(input: AddFormInput): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppCustomerCustomerFormsViewAddFormMutation,
      AppCustomerCustomerFormsViewAddFormMutationVariables
    >({
      mutation: addFormMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Das Formular konnte nicht angelegt werden!');
    }

    return data.addForm.form.id;
  }

  private async onSubmitted(formId: string, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppCustomer/Form', params: { formId } });
  }
}
