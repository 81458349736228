





















import { AddIvmApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import {
  AppCustomerAddVendorApiWizardControlIvmApiMutation,
  AppCustomerAddVendorApiWizardControlIvmApiMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlIvmApiMutation';
import addIvmApiMutation from './add-ivm-api.gql';

@Component
export default class IvmApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private isValidData(data: Partial<AddIvmApiInput>): boolean {
    return this.isNonEmptyString(data.label);
  }

  private async addIvmApi({ label }: AddIvmApiInput): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlIvmApiMutation,
      AppCustomerAddVendorApiWizardControlIvmApiMutationVariables
    >({
      mutation: addIvmApiMutation,
      variables: { input: { customerId: this.customerId, label } },
    });

    if (!data) {
      throw new Error('Die IVM Professional® Integration konnte nicht angelegt werden');
    }

    return data.addIvmApi.ivmApi;
  }
}
