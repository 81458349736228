
































import { defineComponent } from '@vue/composition-api';
import query from './view.gql';

interface NotificationApiProps {
  customerId: string;
}

export default defineComponent<NotificationApiProps, Record<string, unknown>, any, any>({
  props: {
    customerId: { type: String, required: true },
  },
  data() {
    return { customers: undefined };
  },
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables() {
        return { customerId: this.customerId };
      },
    },
  },
});
