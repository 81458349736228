































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerExternalSftpConfigurationViewQuery,
  AppCustomerExternalSftpConfigurationViewQueryVariables,
} from '@/features/app-customer/views/external-sftp-configuration/__generated__/AppCustomerExternalSftpConfigurationViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ExternalSftpConfigurationView): AppCustomerExternalSftpConfigurationViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(
        this: ExternalSftpConfigurationView,
        { data, stale }: ApolloQueryResult<AppCustomerExternalSftpConfigurationViewQuery>,
      ): void {
        if (data.customers.first.externalSftpConfigurations.first === undefined && !stale) {
          this.$router.back();
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class ExternalSftpConfigurationView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerExternalSftpConfigurationViewQuery['customers'];
}
