










































































































































































import { Option } from '@/components/binding-wizard/model';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { GATEWAY_HEALTH_META } from '@/features/domain-ui/gateway-health/constants';
import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';
import { EditAlertMailConfigurationInput, GatewayHealth, SpotHealth } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { at, isString } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerAlertMailConfigurationSettingsViewEditMutation,
  AppCustomerAlertMailConfigurationSettingsViewEditMutationVariables,
} from './__generated__/AppCustomerAlertMailConfigurationSettingsViewEditMutation';
import {
  AppCustomerAlertMailConfigurationSettingsViewQuery,
  AppCustomerAlertMailConfigurationSettingsViewQueryVariables,
} from './__generated__/AppCustomerAlertMailConfigurationSettingsViewQuery';
import {
  AppCustomerAlertMailConfigurationSettingsViewRemoveMutation,
  AppCustomerAlertMailConfigurationSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerAlertMailConfigurationSettingsViewRemoveMutation';

type FormData = Pick<
  EditAlertMailConfigurationInput,
  | 'emailAddress'
  | 'label'
  | 'treeNodeIds'
  | 'enabled'
  | 'alertCategoryIds'
  | 'gatewayHealths'
  | 'spotHealths'
  | 'missedActivityLogEntry'
  | 'heatingSystem'
  | 'companyReferenceAttributeDefinitionId'
  | 'economicUnitReferenceAttributeDefinitionId'
  | 'propertyReferenceAttributeDefinitionId'
  | 'apartmentReferenceAttributeDefinitionId'
>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerAlertMailConfigurationSettingsViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAlertMailConfigurationSettingsViewQuery['customers'];

  private async edit(input: FormData): Promise<void> {
    const alertMailConfigurationId = this.customers?.first.alertMailConfigurations.first?.id;
    if (alertMailConfigurationId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAlertMailConfigurationSettingsViewEditMutation,
      AppCustomerAlertMailConfigurationSettingsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          alertMailConfigurationId,
          ...input,
        },
      },
    });

    if (!data) {
      throw new Error('Die Einstellungen der Alarmierungsmail konnten nicht gespeichert werden');
    }

    const text = `Die Einstellungen der Alarmierungsmail wurden gespeichert`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    const alertMailConfigurationId = this.customers?.first.alertMailConfigurations.first?.id;
    if (alertMailConfigurationId === undefined) {
      return;
    }

    if (!window.confirm('Die Alarmierungsmail wurde entfernt')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAlertMailConfigurationSettingsViewRemoveMutation,
      AppCustomerAlertMailConfigurationSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { alertMailConfigurationId } },
    });

    if (!data) {
      throw new Error('Die Alarmierungsmail konnte nicht entfernt werden');
    }

    const text = `Die Alarmierungsmail wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private get gatewayHealthOptions(): Option[] {
    return at(GATEWAY_HEALTH_META, GatewayHealth.CRITICAL, GatewayHealth.DEGRADED);
  }

  private get spotHealthOptions(): Option[] {
    return at(SPOT_HEALTH_META, SpotHealth.CRITICAL, SpotHealth.DEGRADED);
  }

  private get rootIds(): string[] {
    return this.customers === undefined ? [] : [this.customers.first.rootDirectory.id];
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] {
    return objects === null ? [] : objects.map(({ id }) => id);
  }

  private get complete(): boolean {
    if (this.customers === undefined) {
      return true;
    }
    const {
      emailAddress,
      companyReferenceAttributeDefinition,
      economicUnitReferenceAttributeDefinition,
      propertyReferenceAttributeDefinition,
      apartmentReferenceAttributeDefinition,
    } = this.customers.first.alertMailConfigurations.first;
    return (
      emailAddress !== null &&
      companyReferenceAttributeDefinition !== null &&
      economicUnitReferenceAttributeDefinition !== null &&
      propertyReferenceAttributeDefinition !== null &&
      apartmentReferenceAttributeDefinition !== null
    );
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }
}
