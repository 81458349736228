




































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerNukiSmartlockTableFragment } from '../../../components/nuki-smartlock-table/__generated__/AppCustomerNukiSmartlockTableFragment';
import disconnectMutation from './disconnect.gql';
import query from './view.gql';
import {
  AppCustomerNukiApiAvailableSmartlocksViewDisconnectMutation,
  AppCustomerNukiApiAvailableSmartlocksViewDisconnectMutationVariables,
} from './__generated__/AppCustomerNukiApiAvailableSmartlocksViewDisconnectMutation';
import {
  AppCustomerNukiApiAvailableSmartlocksViewQuery,
  AppCustomerNukiApiAvailableSmartlocksViewQueryVariables,
} from './__generated__/AppCustomerNukiApiAvailableSmartlocksViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: AvailableSmartlocksView): AppCustomerNukiApiAvailableSmartlocksViewQueryVariables {
        return { customerId: this.customerId, nukiApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AvailableSmartlocksView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerNukiApiAvailableSmartlocksViewQuery['customers'];

  private get smartlocks(): AppCustomerNukiSmartlockTableFragment[] {
    return this.customers?.first.nukiApis.first.smartlocks?.items ?? [];
  }

  public async disconnect(smartlock: AppCustomerNukiSmartlockTableFragment): Promise<void> {
    if (!window.confirm('Wollen Sie die Verknüpfung der Nuki-Schließanlage wirklich trennen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerNukiApiAvailableSmartlocksViewDisconnectMutation,
      AppCustomerNukiApiAvailableSmartlocksViewDisconnectMutationVariables
    >({
      mutation: disconnectMutation,
      variables: { input: { smartlockId: parseInt(smartlock.id) } },
    });

    if (!data) {
      throw new Error('Die Verknüpfung der Nuki-Schließanlage konnte nicht getrennt werden.');
    }

    const text = `Die Verknüpfung der Nuki-Schließanlage "${smartlock.id}" wurde getrennt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$emit('nuki-smartlock-disconnected', smartlock);

    this.$apollo.queries.customers.refetch();
  }
}
