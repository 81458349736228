


























import { RootGetter } from '@/features/core/store';
import { AddEnerIqApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import addEnerIqApiMutation from './add-ener-iq-api.gql';
import query from './view.gql';
import {
  AppCustomerAddVendorApiWizardControlEnerIqApiMutation,
  AppCustomerAddVendorApiWizardControlEnerIqApiMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlEnerIqApiMutation';
import {
  AppCustomerAddVendorApiWizardControlEnerIqApiViewQuery,
  AppCustomerAddVendorApiWizardControlEnerIqApiViewQueryVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlEnerIqApiViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: EnerIqApi): AppCustomerAddVendorApiWizardControlEnerIqApiViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class EnerIqApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootGetter
  private readonly admin!: boolean;

  private readonly customers?: AppCustomerAddVendorApiWizardControlEnerIqApiViewQuery['customers'];

  private async addEnerIqApi(input: AddEnerIqApiInput): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlEnerIqApiMutation,
      AppCustomerAddVendorApiWizardControlEnerIqApiMutationVariables
    >({
      mutation: addEnerIqApiMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Die EnerIQ-Integration konnte nicht aktiviert werden');
    }

    return data.addEnerIqApi.enerIqApi;
  }
}
