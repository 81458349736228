































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerIvmApiViewQuery,
  AppCustomerIvmApiViewQueryVariables,
} from './__generated__/AppCustomerIvmApiViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: IvmApiView): AppCustomerIvmApiViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(this: IvmApiView, { data, stale }: ApolloQueryResult<AppCustomerIvmApiViewQuery>): void {
        if (data.customers.first.ivmApis.first === undefined && !stale) {
          this.$router.back();
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class IvmApiView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerIvmApiViewQuery['customers'];
}
