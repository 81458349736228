





































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { Column } from '@/features/ui/table/model';
import { AddHeatingSystemSettingInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { NIL } from 'uuid';
import { Component, Mixins } from 'vue-property-decorator';
import addHeatingSystemSettingMutation from './add-heating-system-setting.gql';
import {
  ViessmannSettingConfiguration,
  VIESSMANN_CIRCUIT_SETTING_TEMPLATES,
  VIESSMANN_DEFAULT_SETTINGS,
} from './constants';
import query from './view.gql';
import {
  AppCustomerSetupViessmannSettingsWizardControlAddHeatingSystemSettingMutation,
  AppCustomerSetupViessmannSettingsWizardControlAddHeatingSystemSettingMutationVariables,
} from './__generated__/AppCustomerSetupViessmannSettingsWizardControlAddHeatingSystemSettingMutation';
import {
  AppCustomerSetupViessmannSettingsWizardControlViewQuery,
  AppCustomerSetupViessmannSettingsWizardControlViewQueryVariables,
} from './__generated__/AppCustomerSetupViessmannSettingsWizardControlViewQuery';

type Device =
  AppCustomerSetupViessmannSettingsWizardControlViewQuery['customers']['first']['viessmannApis']['first']['connectedInstallations']['first']['devices']['first'];

type Measurement =
  AppCustomerSetupViessmannSettingsWizardControlViewQuery['customers']['first']['viessmannApis']['first']['connectedInstallations']['first']['heatingSystem']['measurements'][number];

type SettingSelection = {
  selected: boolean;
  name: string;
  metricName: string;
  associatedMeasurementIds: string[];
};

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(
        this: SetupViessmannSettingsWizardControl,
      ): AppCustomerSetupViessmannSettingsWizardControlViewQueryVariables {
        return {
          customerId: this.customerId,
          viessmannApiId: this.viessmannApiId,
          installationId: parseInt(this.installationId),
          deviceId: this.deviceId,
          featureNames: this.featureNames,
          featureNamesReady: this.featureNames !== null,
        };
      },
      result(
        this: SetupViessmannSettingsWizardControl,
        { data }: ApolloQueryResult<AppCustomerSetupViessmannSettingsWizardControlViewQuery>,
      ): void {
        const features =
          data.customers?.first.viessmannApis.first.connectedInstallations.first.devices.first.features?.map(
            ({ feature }) => feature,
          ) ?? [];

        this.settingSelections =
          this.settingConfigurations
            ?.filter(({ path }) => features.includes(path))
            .map((settingConfiguration) => ({
              selected: true,
              name: settingConfiguration.label,
              metricName: settingConfiguration.name,
              associatedMeasurementIds: [],
            })) ?? [];
      },
    },
  },
  data() {
    return { customers: undefined, settingSelections: [] };
  },
})
export default class SetupViessmannSettingsWizardControl extends Mixins(Busyable) {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly viessmannApiId!: string;

  @StringProp(true)
  private readonly gatewaySerial!: string;

  @StringProp(true)
  private readonly installationId!: string;

  @StringProp(true)
  private readonly deviceId!: string;

  private readonly customers?: AppCustomerSetupViessmannSettingsWizardControlViewQuery['customers'];

  private settingSelections!: SettingSelection[];

  private get title(): string {
    return `Gerät "${this.deviceId}" an Gateway "${this.gatewaySerial}" von Heizungssystem "${this.installationId}" Einstellungen`;
  }

  private get columns(): Column[] {
    return [
      { name: 'selected', label: 'Auswählen', width: 30 },
      { name: 'name', label: 'Name der Einstellung' },
      { name: 'associatedMeasurements', label: 'Angeschlossen Messstellen' },
    ];
  }

  private get measurements(): Measurement[] {
    return this.customers?.first.viessmannApis.first.connectedInstallations.first.heatingSystem.measurements ?? [];
  }

  private get device(): Device | undefined {
    return this.customers?.first.viessmannApis.first.connectedInstallations.first.devices.first;
  }

  private get featureNames(): string[] | null {
    return this.settingConfigurations?.map(({ path }) => path) ?? null;
  }

  private get circuitSettingConfigurations(): ViessmannSettingConfiguration[] {
    const circuits = this.device?.circuits?.flatMap(({ components }) => components) ?? [];

    return circuits.flatMap((circuit) =>
      VIESSMANN_CIRCUIT_SETTING_TEMPLATES.map((settingConfiguration) => ({
        ...settingConfiguration,
        name: settingConfiguration.name.replace('{}', circuit),
        label: settingConfiguration.label.replace('{}', circuit),
        path: settingConfiguration.path.replace('{}', circuit),
      })),
    );
  }

  private get settingConfigurations(): ViessmannSettingConfiguration[] | null {
    return this.customers ? VIESSMANN_DEFAULT_SETTINGS.concat([...this.circuitSettingConfigurations]) : null;
  }

  private get isValidSelection(): boolean {
    if (this.settingSelections.some(({ name }) => !name)) {
      return false;
    }
    return true;
  }

  @Busy()
  private async addSettings(hide: () => Promise<void>): Promise<void> {
    const device = this.customers?.first.viessmannApis.first.connectedInstallations.first.devices.first;
    if (!device || device.__typename !== 'ViessmannConnectedDevice') {
      return;
    }
    const addHeatingSystemSettingInputs: AddHeatingSystemSettingInput[] = this.settingSelections
      .filter(({ selected }) => selected === true)
      .map(({ name, metricName, associatedMeasurementIds }) => ({
        name,
        metricName,
        heatingSystemId: this.customers?.first.viessmannApis.first.connectedInstallations.first.heatingSystem.id ?? NIL,
        spotId: device.deviceMount.spot.id,
        associatedMeasurementIds,
      }));

    const results = await Promise.allSettled(
      addHeatingSystemSettingInputs.map((input) =>
        this.$apollo.mutate<
          AppCustomerSetupViessmannSettingsWizardControlAddHeatingSystemSettingMutation,
          AppCustomerSetupViessmannSettingsWizardControlAddHeatingSystemSettingMutationVariables
        >({
          mutation: addHeatingSystemSettingMutation,
          variables: {
            input,
          },
        }),
      ),
    );

    this.ADD_TOAST_MESSAGES({
      messages: [
        {
          text: `${results.filter(({ status }) => status === 'fulfilled').length} Einstellungen werden hinzugefügt.`,
          class: 'success',
        },
      ],
    });

    await hide();
  }
}
