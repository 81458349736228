


























import { AppCustomerAufzugheldenDeviceTableDeviceFragment } from '@/features/app-customer/components/aufzughelden-device-table/__generated__/AppCustomerAufzugheldenDeviceTableDeviceFragment';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import unmountMutation from './unmount.gql';
import query from './view.gql';
import { AppCustomerAufzugheldenApiConnectedDevicesViewDeviceCollectionFragment } from './__generated__/AppCustomerAufzugheldenApiConnectedDevicesViewDeviceCollectionFragment';
import {
  AppCustomerAufzugheldenApiConnectedDevicesViewQuery,
  AppCustomerAufzugheldenApiConnectedDevicesViewQueryVariables,
} from './__generated__/AppCustomerAufzugheldenApiConnectedDevicesViewQuery';
import {
  AppCustomerAufzugheldenApiConnectedDevicesViewUnmountMutation,
  AppCustomerAufzugheldenApiConnectedDevicesViewUnmountMutationVariables,
} from './__generated__/AppCustomerAufzugheldenApiConnectedDevicesViewUnmountMutation';

type Device = AppCustomerAufzugheldenDeviceTableDeviceFragment;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
      variables(this: ConnectedDevicesView): AppCustomerAufzugheldenApiConnectedDevicesViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class ConnectedDevicesView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAufzugheldenApiConnectedDevicesViewQuery['customers'];

  private get collection(): AppCustomerAufzugheldenApiConnectedDevicesViewDeviceCollectionFragment | undefined {
    return this.customers?.first.aufzugheldenApis.first.connectedDevices;
  }

  private get devices(): AppCustomerAufzugheldenApiConnectedDevicesViewDeviceCollectionFragment['items'] {
    return this.collection?.items ?? [];
  }

  protected get count(): number {
    return this.collection?.count ?? 0;
  }

  private async unmount(device: Device): Promise<void> {
    if (!window.confirm('Möchten Sie die Verbindung zu diesem Aufzug wirklich trennen?')) {
      return;
    }

    const deviceId = device.__typename === 'AufzugheldenConnectedDevice' ? device?.deviceMount.id : undefined;

    if (!deviceId) {
      throw new Error('Kann das Gerät nicht finden');
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAufzugheldenApiConnectedDevicesViewUnmountMutation,
      AppCustomerAufzugheldenApiConnectedDevicesViewUnmountMutationVariables
    >({
      mutation: unmountMutation,
      variables: { input: { deviceId } },
    });

    if (!data) {
      throw new Error('Die Verbindung konnte nicht getrennt werden');
    }

    const text = `Die Verbindung zum Aufzug "${device.deviceId}" wurde getrennt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$emit('aufzughelden-device-unmounted', device);
    this.$apollo.queries.customers.refetch();
  }
}
