





import { Component, Mixins } from 'vue-property-decorator';
import { ComgyDeviceMapper, Property } from './DeviceMapper';
import {
  AppCustomerComgyApiConnectDevicesCustomerPropertiesQuery,
  AppCustomerComgyApiConnectDevicesCustomerPropertiesQueryVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesCustomerPropertiesQuery';
import customerPropertiesQuery from '@/features/app-customer/views/comgy-api/connect-devices/properties.gql';
import { ArrayProp, StringProp } from '@/util/prop-decorators';
import { ComgyApiEstateUnitDetails, Device } from '@/features/app-customer/views/comgy-api/connect-devices/comgy-types';
import {
  AppCustomerComgyApiConnectDevicesEstateUnitsQuery,
  AppCustomerComgyApiConnectDevicesEstateUnitsQueryVariables,
} from './__generated__/AppCustomerComgyApiConnectDevicesEstateUnitsQuery';
import estateUnitsQuery from '@/features/app-customer/views/comgy-api/connect-devices/estate-units.gql';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';

@Component
export default class MappingProposal extends Mixins(Busyable) {
  private COMGY_LIMIT = 200;
  private PROPERTIES_LIMIT = 50;

  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @ArrayProp()
  private readonly comgyDevices?: Device[];

  private async loadEstateUnits(): Promise<ComgyApiEstateUnitDetails[]> {
    const estateUnits: ComgyApiEstateUnitDetails[] = [];

    for (let offset = 0; estateUnits.length < 10000; offset += this.COMGY_LIMIT) {
      const resp = await this.$apollo.query<
        AppCustomerComgyApiConnectDevicesEstateUnitsQuery,
        AppCustomerComgyApiConnectDevicesEstateUnitsQueryVariables
      >({
        query: estateUnitsQuery,
        variables: {
          customerId: this.customerId,
          vendorApiId: this.vendorApiId,
          skip: offset,
          take: this.COMGY_LIMIT,
        },
        fetchPolicy: 'no-cache',
      });
      const currentEstateUnits = resp.data.customers.first.comgyApis.first.estateUnits.items;

      if (currentEstateUnits) {
        estateUnits.push(...(currentEstateUnits.map((eu) => eu.details) as unknown as ComgyApiEstateUnitDetails[]));

        if (currentEstateUnits.length !== this.COMGY_LIMIT) {
          break;
        }
      }
    }
    return estateUnits;
  }

  private async loadCustomerProperties(): Promise<Property[]> {
    const properties: Property[] = [];

    for (let offset = 0; properties.length < 10000; offset += this.COMGY_LIMIT) {
      const resp = await this.$apollo.query<
        AppCustomerComgyApiConnectDevicesCustomerPropertiesQuery,
        AppCustomerComgyApiConnectDevicesCustomerPropertiesQueryVariables
      >({
        query: customerPropertiesQuery,
        variables: { customerId: this.customerId, skip: offset, take: this.PROPERTIES_LIMIT },
        fetchPolicy: 'no-cache',
      });

      if ('properties' in resp.data.customers.first.rootDirectory) {
        const currentProperties = resp.data.customers.first.rootDirectory.properties.items;

        if (currentProperties) {
          properties.push(...currentProperties);

          if (currentProperties.length !== this.PROPERTIES_LIMIT) {
            break;
          }
        }
      }
    }
    return properties;
  }

  @Busy()
  private async startAutoMapping(): Promise<void> {
    const [properties, estateUnits] = await Promise.all([this.loadCustomerProperties(), this.loadEstateUnits()]);
    const comgyObjectLink = new ComgyDeviceMapper(properties, estateUnits, this.comgyDevices || []).mapping;

    this.$emit('update', comgyObjectLink);
  }
}
