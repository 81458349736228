






























































































































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Option } from '@/features/ui/inputs/model';
import { Column } from '@/features/ui/table/model';
import { AddAlertRuleDeploymentInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import addDeploymentMutation from './add-deployment.gql';
import rulesQuery from './rules.gql';
import query from './view.gql';
import {
  AppCustomerCustomerAlertRuleDeploymentsViewAddDeploymentMutation,
  AppCustomerCustomerAlertRuleDeploymentsViewAddDeploymentMutationVariables,
} from './__generated__/AppCustomerCustomerAlertRuleDeploymentsViewAddDeploymentMutation';
import {
  AppCustomerCustomerAlertRuleDeploymentsViewQuery,
  AppCustomerCustomerAlertRuleDeploymentsViewQueryVariables,
} from './__generated__/AppCustomerCustomerAlertRuleDeploymentsViewQuery';
import {
  AppCustomerCustomerAlertRuleDeploymentsViewRulesQuery,
  AppCustomerCustomerAlertRuleDeploymentsViewRulesQueryVariables,
} from './__generated__/AppCustomerCustomerAlertRuleDeploymentsViewRulesQuery';

@Component({
  apollo: {
    rules: {
      query: rulesQuery,
      fetchPolicy: 'cache-and-network',
      variables(this: AlertRuleDeploymentsView): AppCustomerCustomerAlertRuleDeploymentsViewRulesQueryVariables {
        return { customerId: this.customerId };
      },
    },
    customers: {
      query,
      fetchPolicy: 'network-only',
      pollInterval: 60000,
      variables(this: AlertRuleDeploymentsView): AppCustomerCustomerAlertRuleDeploymentsViewQueryVariables {
        return { customerId: this.customerId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { rules: undefined, customers: undefined };
  },
})
export default class AlertRuleDeploymentsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly rules?: AppCustomerCustomerAlertRuleDeploymentsViewRulesQuery['rules'];
  private readonly customers?: AppCustomerCustomerAlertRuleDeploymentsViewQuery['customers'];

  private readonly columns: Column[] = [
    { name: 'name' },
    { name: 'category' },
    { name: 'enabled', label: 'Status' },
    { name: 'alerts' },
    { name: 'action', label: '', align: 'right' },
  ];

  protected get count(): number {
    return this.customers?.first.alertRuleDeployments.items.length ?? 0;
  }

  private get alertRuleOptions(): Option[] {
    return (
      this.rules?.first.alertRules.items.map(({ id, name, enabled }) => ({
        value: id,
        label: `${name}${enabled ? '' : ' (deaktiviert)'}`,
      })) ?? []
    );
  }

  private async onAddWizardClick(show: () => Promise<void>): Promise<void> {
    await Promise.all([this.$apollo.queries.rules.refetch(), show()]);
  }

  private async addDeployment(input: AddAlertRuleDeploymentInput): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppCustomerCustomerAlertRuleDeploymentsViewAddDeploymentMutation,
      AppCustomerCustomerAlertRuleDeploymentsViewAddDeploymentMutationVariables
    >({
      mutation: addDeploymentMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Die Alarmanwendung konnte nicht erstellt werden!');
    }

    return data.addAlertRuleDeployment.deployment.id;
  }

  private async onDeploymentAdded(alertRuleDeploymentId: string, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppCustomer/AlertRuleDeployment', params: { alertRuleDeploymentId } });
  }
}
