
























import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerViessmannDeviceTableFragment } from './__generated__/AppCustomerViessmannDeviceTableFragment';

@Component
export default class ViessmannDeviceTable extends Vue {
  @ArrayProp(() => [])
  private readonly devices!: AppCustomerViessmannDeviceTableFragment;

  private readonly columns: Column[] = [
    { name: 'id', label: 'Gerät' },
    { name: 'path', label: 'Verknüpftes Objekt' },
    { name: 'integration-spot', label: '' },
    { name: 'device-details', label: '' }, //TODO ask bernd to have an external viessman identifier
    { name: 'action', label: '', align: 'right' },
  ];
}
