






























import { AddAufzugheldenApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import addAufzugheldenApiMutation from './add-aufzughelden-api.gql';
import {
  AppCustomerAddVendorApiWizardControlAufzugheldenApiMutation,
  AppCustomerAddVendorApiWizardControlAufzugheldenApiMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlAufzugheldenApiMutation';

@Component
export default class AufzugheldenApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private isValidData(data: Partial<AddAufzugheldenApiInput>): boolean {
    return this.isNonEmptyString(data.username) && this.isNonEmptyString(data.password);
  }

  private async addAufzugheldenApi(
    input: AddAufzugheldenApiInput,
  ): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlAufzugheldenApiMutation,
      AppCustomerAddVendorApiWizardControlAufzugheldenApiMutationVariables
    >({
      mutation: addAufzugheldenApiMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Der Datenanbieter konnte nicht hinzugefügt werden!');
    }

    return data.addAufzugheldenApi.aufzugheldenApi;
  }
}
