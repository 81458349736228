





























import { AddComgyApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import addComgyApiMutation from './add-comgy-api.gql';
import {
  AppCustomerAddVendorApiWizardControlComgyApiMutation,
  AppCustomerAddVendorApiWizardControlComgyApiMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlComgyApiMutation';

@Component
export default class ComgyApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private isValidData(data: Partial<AddComgyApiInput>): boolean {
    return this.isNonEmptyString(data.label) && this.isNonEmptyString(data.token);
  }

  private async addComgyApi(input: AddComgyApiInput): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlComgyApiMutation,
      AppCustomerAddVendorApiWizardControlComgyApiMutationVariables
    >({
      mutation: addComgyApiMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Der Datenanbieter konnte nicht hinzugefügt werden!');
    }

    return data.addComgyApi.comgyApi;
  }
}
