var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.economicUnits},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.getId(row)))])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.getName(row)))])]}},{key:"path",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.heatingSystem.site.path.items},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_vm._t("action",null,{"economicUnit":row})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }