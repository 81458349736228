








import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerViessmannConnectedInstallationSettingsViewQuery,
  AppCustomerViessmannConnectedInstallationSettingsViewQueryVariables,
} from './__generated__/AppCustomerViessmannConnectedInstallationSettingsViewQuery';
import {
  AppCustomerViessmannConnectedInstallationSettingsViewRemoveMutation,
  AppCustomerViessmannConnectedInstallationSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerViessmannConnectedInstallationSettingsViewRemoveMutation';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerViessmannConnectedInstallationSettingsViewQueryVariables {
        return {
          customerId: this.customerId,
          viessmannApiId: this.vendorApiId,
          installationId: parseInt(this.installationId),
        };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @StringProp(true)
  private readonly installationId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerViessmannConnectedInstallationSettingsViewQuery['customers'];

  private async remove(): Promise<void> {
    if (!window.confirm('Wollen Sie diese Installation wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerViessmannConnectedInstallationSettingsViewRemoveMutation,
      AppCustomerViessmannConnectedInstallationSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { installationId: parseInt(this.installationId) } },
    });

    if (!data) {
      throw new Error('Die Installation von Viessmann konnte nicht entfernt werden.');
    }

    const text = `Die Installation "${this.installationId}" wurde entfernt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({
      name: 'AppCustomer/VendorApis/ViessmannApi/AvailableInstallations',
      params: { customerId: this.customerId, vendorApiId: this.vendorApiId },
    });
  }
}
