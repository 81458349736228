














































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins } from 'vue-property-decorator';
import setActivityFormsMutation from './edit-activity-forms.gql';
import query from './view.gql';
import {
  AppCustomerActivityActivityFormsViewSetActivityFormsMutation,
  AppCustomerActivityActivityFormsViewSetActivityFormsMutationVariables,
} from './__generated__/AppCustomerActivityActivityFormsViewSetActivityFormsMutation';
import {
  AppCustomerActivityActivityFormsViewQuery,
  AppCustomerActivityActivityFormsViewQueryVariables,
} from './__generated__/AppCustomerActivityActivityFormsViewQuery';
import { differenceWith } from 'lodash';

type ActivityForm = AppCustomerActivityActivityFormsViewQuery['activity']['first']['forms'][number];
type TreeNode = ActivityForm['treeNodes'][number];
type Form = ActivityForm['form'];

type ActivityFormConfiguration = {
  formId: string;
  formTitle: string;
  formDescription?: string;
  treeNodes: TreeNode[];
};

@Component({
  beforeRouteLeave(to, from, next) {
    if (!(this as ActivityFormsView).dirty) {
      next();
      return;
    }
    const answer = window.confirm(
      'Sie haben ungespeicherte Änderungen die verloren gehen werden, wenn Sie diese Seite verlassen ohne zu speichern. Sind Sie sich sicher, dass Sie die Seite verlassen möchten?',
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  apollo: {
    activity: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: ActivityFormsView): AppCustomerActivityActivityFormsViewQueryVariables {
        return { id: this.activityId };
      },
      result(this: ActivityFormsView, { data }: ApolloQueryResult<AppCustomerActivityActivityFormsViewQuery>): void {
        this.configurations = data.activity.first.forms.map(({ form, treeNodes }) => ({
          formId: form.id,
          formTitle: form.title,
          formDescription: form.description ?? undefined,
          treeNodes,
        }));
      },
    },
  },
  data() {
    return { activity: undefined, configurations: [] };
  },
})
export default class ActivityFormsView extends Mixins(Busyable) {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp(true)
  private readonly activityId!: string;

  private readonly activity?: AppCustomerActivityActivityFormsViewQuery['activity'];

  private configurations!: ActivityFormConfiguration[];

  private dirty = false;

  private get activityForms(): ActivityForm[] {
    return this.activity?.first.forms ?? [];
  }

  private get availableForms(): Form[] {
    return differenceWith(
      this.activity?.first.customer.forms.items ?? [],
      this.configurations,
      (a, b) => a.id === b.formId,
    );
  }

  private get customerId(): string | undefined {
    return this.activity?.first.customer.id;
  }

  private get rootIds(): string[] {
    if (this.activity === undefined) {
      return [];
    }
    return [this.activity.first.customer.id];
  }

  private addForm(form: Form): void {
    if (this.configurations.find(({ formId }) => formId === form.id)) {
      return;
    }
    this.configurations.push({
      formId: form.id,
      formTitle: form.title,
      formDescription: form.description ?? undefined,
      treeNodes: [],
    });
    this.dirty = true;
  }

  private removeForm(formId: string): void {
    this.configurations = this.configurations.filter(({ formId: id }) => formId !== id);
    this.dirty = true;
  }

  @Busy()
  private async setActivityForms(): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerActivityActivityFormsViewSetActivityFormsMutation,
      AppCustomerActivityActivityFormsViewSetActivityFormsMutationVariables
    >({
      mutation: setActivityFormsMutation,
      variables: {
        input: {
          id: this.activityId,
          forms: this.configurations.map(({ formId, treeNodes }) => ({
            formId,
            treeNodeIds: treeNodes.map(({ id }) => id),
          })),
        },
      },
    });

    if (!data) {
      throw new Error('Die Formulare konnten nicht gespeichert werden!');
    }
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Formulare gespeichert.', class: 'success' }],
    });
    this.dirty = false;
  }
}
