



import { Component, Vue } from 'vue-property-decorator';
import { StringProp } from '@/util/prop-decorators';
import ConsumptionDataExchange from '@/features/app-customer/components/add-vendor-api-wizard-control/consumption-data-exchange/ConsumptionDataExchange.vue';
import { ConsumptionDataExchangeType } from '@/types/iot-portal';

@Component({
  components: {
    ConsumptionDataExchange,
  },
})
export default class ConsumptionDataExchangeVarysSW extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly type: ConsumptionDataExchangeType = ConsumptionDataExchangeType.VarysSW;
}
