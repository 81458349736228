var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customer)?_c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard',{ref:"wizard",attrs:{"title":"Aktivitäten anlegen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-button',{attrs:{"front":"","icon":_vm.plusIcon},on:{"click":show}},[_vm._v("Aktivitäten anlegen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.addActivity},on:{"submitted":function($event){return _vm.onSubmitted($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,false,2619055884)})],1),(_vm.totalPages > 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.customer.first.activities.items,"align":"left","striped":""},scopedSlots:_vm._u([{key:"name.label",fn:function(){return [_vm._v("Name")]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}},{key:"count",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.serviceProviders.count))]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('ui-link',{on:{"click":function($event){return _vm.$router.push({ name: 'AppCustomer/Activity', params: { activityId: row.id } })}}},[_vm._v("Bearbeiten")])]}}],null,false,3910090244)}),_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Keine Aktivitäten definiert.")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }