var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activity)?_c('ui-panel',[_c('ui-form',{attrs:{"initial-data":_vm.activity.first,"action":_vm.editActivity},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"name","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"checkOutInterval","path":"checkOutInterval","label":"Abschlusszeitraum","description":"             In diesem Zeitraum kann die Aktivität als abgeschlossen markiert werden.             Kann auch individuell pro PIN konfiguriert werden.           ","clearable":false,"options":_vm.options,"reduce":function (ref) {
	var value = ref.value;

	return value;
}}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-wizard',{attrs:{"title":"Aktivität löschen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{attrs:{"disabled":_vm.activity === undefined,"secondary":""},on:{"click":show}},[_vm._v("Aktivität löschen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.removeActivity},on:{"submitted":function($event){return _vm.onRemoved(hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('p',[_vm._v("Sind Sie sich sicher, dass Sie die Aktivität "),_c('em',[_vm._v(_vm._s(_vm.activity.first.name))]),_vm._v(" löschen wollen?")])]),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"busy":busy},on:{"click":submit}},[_vm._v("Löschen")])],1)],1)]}}],null,true)})]}}],null,true)})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,false,1239015946)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }