


























































































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { INTERVAL_UNIT_META } from '@/features/domain-ui/interval/constants';
import { RecurringInterval } from '@/features/domain-ui/recurring-interval/model';
import { TREE_NODE_TYPE_META } from '@/features/domain-ui/tree-node-type/constants';
import { EditActivityCheckInScheduleInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import editActivityCheckInScheduleMutation from './edit-activity-check-in-schedule.gql';
import query from './view.gql';
import {
  AppCustomerActivityCheckInScheduleViewEditActivityCheckInScheduleMutation,
  AppCustomerActivityCheckInScheduleViewEditActivityCheckInScheduleMutationVariables,
} from './__generated__/AppCustomerActivityCheckInScheduleViewEditActivityCheckInScheduleMutation';
import {
  AppCustomerActivityCheckInScheduleViewQuery,
  AppCustomerActivityCheckInScheduleViewQueryVariables,
} from './__generated__/AppCustomerActivityCheckInScheduleViewQuery';
import { Option } from '@/features/ui/inputs/model';

@Component({
  apollo: {
    activity: {
      query,
      variables(this: ActivityCheckInScheduleView): AppCustomerActivityCheckInScheduleViewQueryVariables {
        return { id: this.activityCheckInScheduleId, activityId: this.activityId };
      },
      manual: false,
    },
  },
  data() {
    return { activity: undefined };
  },
})
export default class ActivityCheckInScheduleView extends Vue {
  @StringProp(true)
  private readonly activityId!: string;

  @StringProp(true)
  private readonly activityCheckInScheduleId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly activity?: AppCustomerActivityCheckInScheduleViewQuery['activity'];

  private get name(): string | undefined {
    const schedule = this.activity?.first.checkInSchedules.first;
    if (schedule === undefined) {
      return undefined;
    }
    return `Jeder ${schedule.amount} ${INTERVAL_UNIT_META[schedule.unit].labelPlural}`;
  }

  private get rootIds(): string[] | undefined {
    if (this.activity === undefined) {
      return undefined;
    }
    return [this.activity.first.customer.id];
  }

  private get treeNodeTypes(): Option[] {
    return Object.keys(TreeNodeType).map((type) => ({
      value: type,
      label: TREE_NODE_TYPE_META[type as TreeNodeType].labelPlural,
    }));
  }

  private async editActivityCheckInSchedule({
    schedule,
    ...input
  }: { schedule: RecurringInterval } & Pick<
    EditActivityCheckInScheduleInput,
    'treeNodeIds' | 'treeNodeType' | 'notifyOnMissedLogEntry'
  >): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerActivityCheckInScheduleViewEditActivityCheckInScheduleMutation,
      AppCustomerActivityCheckInScheduleViewEditActivityCheckInScheduleMutationVariables
    >({
      mutation: editActivityCheckInScheduleMutation,
      variables: { input: { ...schedule, id: this.activityCheckInScheduleId, ...input } },
    });

    if (!data) {
      throw new Error('Der Zeitplan konnte nicht geändert werden.');
    }

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Zeitplan geändert.', class: 'success' }],
    });
  }

  private pluckId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
