import {
  Breadcrumb,
  Device,
  ConnectedDevice,
  TreePath,
  ConnectableDevice,
  ComgyApiEstateUnitDetails,
} from '@/features/app-customer/views/comgy-api/connect-devices/comgy-types';
import { TreeNodeType } from '@/types/iot-portal';
import { AppCustomerComgyApiConnectDevicesCustomerPropertiesQuery } from './__generated__/AppCustomerComgyApiConnectDevicesCustomerPropertiesQuery';

// breadcrumb-id -> TreePath
export type ComgyDeviceMapping = { [key: string]: TreePath | undefined };
export type Property =
  (AppCustomerComgyApiConnectDevicesCustomerPropertiesQuery['customers']['first']['rootDirectory'] & {
    __typename: 'Directory';
  })['properties']['items'][number];

export type PropertySubdivision = Property['propertySubdivisions']['items'][number];

export class ComgyDeviceMapper {
  private readonly propertiesByStreet!: { [key: string]: Property[] };
  private readonly estateUnitsById!: { [key: string]: ComgyApiEstateUnitDetails };
  private readonly comgyDevices!: Device[];

  private mappingResult: ComgyDeviceMapping;

  public constructor(
    customerProperties: Property[],
    comgyEstateUnits: ComgyApiEstateUnitDetails[],
    comgyDevices: Device[],
  ) {
    this.propertiesByStreet = {};
    for (const customerProperty of customerProperties) {
      if (customerProperty.address?.street) {
        const normalizedStreet = this.normalizeStreet(customerProperty.address.street);
        if (!(normalizedStreet in this.propertiesByStreet)) {
          this.propertiesByStreet[normalizedStreet] = [];
        }
        this.propertiesByStreet[normalizedStreet].push(customerProperty);
      }
    }

    this.estateUnitsById = {};
    for (const comgyEstateUnit of comgyEstateUnits) {
      this.estateUnitsById[comgyEstateUnit.id] = comgyEstateUnit;
    }

    this.comgyDevices = comgyDevices;

    this.mappingResult = {};
    this.map();
  }

  public get mapping(): ComgyDeviceMapping {
    return this.mappingResult;
  }

  public static extractStairwayAndEstateUnit(breadcrumbs: Breadcrumb[] | undefined): {
    estateUnit: Breadcrumb | undefined;
    stairway: Breadcrumb | undefined;
  } {
    let estateUnit: Breadcrumb | undefined;
    let stairway: Breadcrumb | undefined;

    if (breadcrumbs === undefined) return { estateUnit, stairway };

    for (const breadcrumb of breadcrumbs) {
      if (breadcrumb.class === 'EstateUnit') {
        estateUnit = breadcrumb;
      } else if (breadcrumb.class === 'Stairway') {
        stairway = breadcrumb;
      }
    }
    return { estateUnit, stairway };
  }

  private map(): void {
    const connectedDevices = this.comgyDevices.filter(
      (d): d is ConnectedDevice => d.__typename === 'ComgyConnectedDevice',
    );
    const connectableDevices = this.comgyDevices.filter(
      (d): d is ConnectableDevice => d.__typename === 'ComgyConnectableDevice',
    );

    //the auto. mapping has always a higher priority than already connected devices
    for (const device of connectableDevices) {
      const { estateUnit, stairway } = ComgyDeviceMapper.extractStairwayAndEstateUnit(
        device.details?.attributes.breadcrumbs,
      );

      //connected devices are used for suggestions
      if (estateUnit === undefined || stairway === undefined) {
        continue;
      }

      if (stairway.id in this.mappingResult && estateUnit.id in this.mappingResult) {
        //already mapped (by another device)
        continue;
      }

      this.mapConnectableDevice(stairway, estateUnit);
    }

    for (const device of connectedDevices) {
      const { estateUnit, stairway } = ComgyDeviceMapper.extractStairwayAndEstateUnit(
        device.details?.attributes.breadcrumbs,
      );

      //connected devices are used for suggestions
      if (estateUnit === undefined || stairway === undefined) {
        continue;
      }
      if (stairway.id in this.mappingResult && estateUnit.id in this.mappingResult) {
        //already mapped (by another device)
        continue;
      }

      this.mapConnectedDevice(stairway, estateUnit, device);
    }
  }

  private mapConnectedDevice(stairway: Breadcrumb, estateUnit: Breadcrumb, device: ConnectedDevice): void {
    this.mappingResult[stairway.id.toString()] = { items: [], __typename: 'TreeNodeCollection' };
    this.mappingResult[estateUnit.id.toString()] = { items: [], __typename: 'TreeNodeCollection' };

    this.transferPath(
      device.deviceMount.spot.path,
      this.mappingResult[stairway.id.toString()],
      this.mappingResult[estateUnit.id.toString()],
    );
  }

  private mapConnectableDevice(stairway: Breadcrumb, estateUnit: Breadcrumb): void {
    const correspondingEstateUnit = this.estateUnitsById[estateUnit.id];
    let street = stairway.breadcrumb_name;
    let postalCode: string | null = null;

    if (correspondingEstateUnit) {
      street = correspondingEstateUnit.attributes.address.street;
      postalCode = correspondingEstateUnit.attributes.address['postal-code'];
    }

    const property = this.findPropertyByLocation(street, postalCode);
    if (!property) {
      //no matching possible
      return;
    }

    const subdivision = this.findSubdivision(property, correspondingEstateUnit);
    if (!subdivision) {
      //no estate unit matching possible

      if (!(stairway.id.toString() in this.mappingResult) && property.propertySubdivisions.items.length > 0) {
        //at least we can map the stairway

        this.mappingResult[stairway.id.toString()] = { items: [], __typename: 'TreeNodeCollection' };
        this.transferPath(
          property.propertySubdivisions.items[0].path,
          this.mappingResult[stairway.id.toString()],
          undefined,
        );
      }
      return;
    }

    this.mappingResult[stairway.id.toString()] = { items: [], __typename: 'TreeNodeCollection' };
    this.mappingResult[estateUnit.id.toString()] = { items: [], __typename: 'TreeNodeCollection' };

    this.transferPath(
      subdivision.path,
      this.mappingResult[stairway.id.toString()],
      this.mappingResult[estateUnit.id.toString()],
    );
    this.mappingResult[estateUnit.id.toString()]?.items.push({
      id: subdivision.id,
      name: subdivision.name,
      __typename: subdivision.__typename,
    });
  }

  private transferPath(
    source: TreePath,
    stairwayPath: TreePath | undefined,
    estateUnitPath: TreePath | undefined,
  ): void {
    for (const value of source.items) {
      switch (value.__typename) {
        case TreeNodeType.RootDirectory:
        case TreeNodeType.Directory:
        case TreeNodeType.PropertyGroup:
        case TreeNodeType.Property:
          stairwayPath?.items.push(value); //fallthrough
        case TreeNodeType.PropertySubdivision:
          estateUnitPath?.items.push(value);
          break;
      }
    }
  }

  private findPropertyByLocation(street: string, postalCode: string | null): Property | undefined {
    const matches = this.propertiesByStreet[this.normalizeStreet(street)];

    //no property found
    if (!matches) {
      return undefined;
    }

    //only one property found
    if (matches.length === 1) {
      return matches[0];
    }

    //multiple properties with same street found
    if (postalCode) {
      return matches.find((p) => p.address?.postal === postalCode);
    }

    //no exact match nad multiple matches -> dont choose any of them!
    return undefined;
  }

  private findSubdivision(property: Property, estateUnit?: ComgyApiEstateUnitDetails): PropertySubdivision | undefined {
    //we expect only one attribute in a subdivision! an this can be the externalnumber of a estate unit
    if (estateUnit) {
      if (estateUnit.attributes['external-number']) {
        const externalId = estateUnit.attributes['external-number'];
        const match = property.propertySubdivisions.items
          .filter((s) => s.attributes.length > 0) //filter out subdivisions which have no external number
          .find((s) => s.attributes[0].value === externalId);
        if (match) {
          return match;
        }
      }

      const normalizedName = this.normalizeEstateUnitName(estateUnit.attributes.position);
      const match = property.propertySubdivisions.items.find((s) =>
        this.normalizeEstateUnitName(s.name).includes(normalizedName),
      );
      if (match) {
        return match;
      }
    }

    return undefined;
  }

  private normalizeStreet(street: string): string {
    return street
      .toLowerCase()
      .trim()
      .replace('str.', 'strasse')
      .replace('ß', 'ss')
      .replace(/\s\s+/g, ' ') //multiple spaces to one
      .replace(/([0-9]) ([^0-9])/g, '$1$2'); //"1 c" -> "1c"
  }

  private normalizeEstateUnitName(name: string): string {
    return name
      .toLowerCase()
      .trim()
      .replace(/\s\s+/g, ' ') //multiple spaces to one
      .replace(/([0-9]+)[\s.]*([^0-9\s]+)/g, '_$1$2_'); //"11. og" -> "_11og_"
  }
}
