
import {
  VIESSMANN_API_API_URL,
  VIESSMANN_API_CLIENT_ID,
  VIESSMANN_API_ME_URL,
} from '@/features/app-customer/components/add-vendor-api-wizard-control/viessmann/constants';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import editViessmannApiMutation from './edit-viessmann-api.gql';
import {
  AppCustomerViessmannAuthCallbackViewEditMutation,
  AppCustomerViessmannAuthCallbackViewEditMutationVariables,
} from './__generated__/AppCustomerViessmannAuthCallbackViewEditMutation';

@Component
export default class ViessmannAuthCallbackView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private async created(): Promise<undefined | Route> {
    const viessmannApiCode = this.$route.query['code'];

    if (typeof viessmannApiCode !== 'string') {
      return this.$router.replace({
        name: 'AppCustomer/Customer/VendorApis',
        params: { customerId: this.customerId },
      });
    }

    const redirectUri = this.$router.resolve({
      name: 'AppCustomer/ViessmannAuthCallback',
      params: { customerId: this.customerId, vendorApiId: this.vendorApiId },
    });

    const body = new URLSearchParams();

    body.append('grant_type', 'authorization_code');
    body.append('redirect_uri', window.location.origin + redirectUri.href);
    body.append('client_id', VIESSMANN_API_CLIENT_ID);
    body.append('code', viessmannApiCode);
    body.append('code_verifier', '2e21faa1-db2c-4d0b-a10f-575fd372bc8c-575fd372bc8c');

    const response = await fetch(VIESSMANN_API_API_URL, {
      method: 'POST',
      body,
    });

    const data = await response.json();

    const meResponse = await fetch(VIESSMANN_API_ME_URL, {
      headers: {
        Authorization: `Bearer ${data.access_token}`,
      },
    });

    const meData = await meResponse.json();

    this.$apollo.mutate<
      AppCustomerViessmannAuthCallbackViewEditMutation,
      AppCustomerViessmannAuthCallbackViewEditMutationVariables
    >({
      mutation: editViessmannApiMutation,
      variables: {
        input: {
          viessmannApiId: this.vendorApiId,
          label: meData.loginId ?? '',
          refreshToken: data.refresh_token,
        },
      },
    });

    return this.$router.replace({
      name: 'AppCustomer/VendorApis/ViessmannApi',
      params: { customerId: this.customerId, vendorApiId: this.vendorApiId },
    });
  }

  private render(): void {
    // nothing
  }
}
