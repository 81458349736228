















































import { RestClient } from '@/features/core/rest-client/rest-client';
import { AddIvmApiInput, VendorApiType } from '@/types/iot-portal';
import { AddVendorInput, AddVendorOutPut, AddVendorResponse } from '@/types/notification-api.model';
import { isNonEmptyString } from '@/util/string';
import { defineComponent } from '@vue/composition-api';
import { EMPTY, lastValueFrom } from 'rxjs';

interface IvmApiViewProps {
  customerId: string;
}

export default defineComponent<IvmApiViewProps, Record<string, unknown>, any, any>({
  inject: ['GENERIC_NOTIFICATION_API_REST_CLIENT'],
  props: {
    customerId: { type: String, required: true },
  },
  data() {
    return {
      isNonEmptyString,
      formData: {} as AddIvmApiInput,
      client: undefined as RestClient | undefined,
    };
  },
  created() {
    this.client = this.GENERIC_NOTIFICATION_API_REST_CLIENT as RestClient;
  },
  methods: {
    isValidData(data: Partial<AddIvmApiInput>): boolean {
      return this.isNonEmptyString(data.label);
    },
    async addIvmApi(input: AddVendorInput): Promise<AddVendorOutPut> {
      try {
        const response: AddVendorResponse = await lastValueFrom(
          this.client?.post('/api/v1/notification', {
            customerId: this.customerId,
            vendorName: 'MockApi',
            ...input,
          }) ?? EMPTY,
        );

        if (response.status === 'config exists') {
          throw new Error('Den IVM Professional® gibt es bereits');
        }

        return {
          id: response.id,
          label: this.formData.label,
          __typename: 'IvmNotificationApi',
          type: VendorApiType.IvmNotificationApi,
        };
      } catch (error) {
        throw new Error('Die IVM Professional® Integration konnte nicht angelegt werden');
      }
    },
  },
});
