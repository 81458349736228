































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditAufzugheldenApiInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerAufzugheldenApiCredentialsViewEditMutation,
  AppCustomerAufzugheldenApiCredentialsViewEditMutationVariables,
} from './__generated__/AppCustomerAufzugheldenApiCredentialsViewEditMutation';
import {
  AppCustomerAufzugheldenApiCredentialsViewQuery,
  AppCustomerAufzugheldenApiCredentialsViewQueryVariables,
} from './__generated__/AppCustomerAufzugheldenApiCredentialsViewQuery';
import {
  AppCustomerAufzugheldenApiCredentialsViewRemoveMutation,
  AppCustomerAufzugheldenApiCredentialsViewRemoveMutationVariables,
} from './__generated__/AppCustomerAufzugheldenApiCredentialsViewRemoveMutation';

type FormData = Pick<EditAufzugheldenApiInput, 'password' | 'username'>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: CredentialsView): AppCustomerAufzugheldenApiCredentialsViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class CredentialsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAufzugheldenApiCredentialsViewQuery['customers'];

  private readonly isNonEmptyString = isNonEmptyString;

  private isValid(formData: FormData): boolean {
    return isNonEmptyString(formData.username) && isNonEmptyString(formData.password);
  }

  private async edit(formData: FormData): Promise<void> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAufzugheldenApiCredentialsViewEditMutation,
      AppCustomerAufzugheldenApiCredentialsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          ...formData,
          aufzugheldenApiId: this.vendorApiId,
        },
      },
    });

    if (!data) {
      throw new Error('Die Zugangsdaten konnten nicht gespeichert werden.');
    }
  }

  private async remove(): Promise<void> {
    if (!window.confirm('Wollen Sie diese Dienstleister-Integration von Aufzughelden wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAufzugheldenApiCredentialsViewRemoveMutation,
      AppCustomerAufzugheldenApiCredentialsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { aufzugheldenApiId: this.vendorApiId } },
    });

    if (!data) {
      throw new Error('Die Dienstleister-Integration von Aufzughelden konnte nicht entfernt werden.');
    }

    const text = `Die Dienstleister-Integration von Aufzughelden mit dem Benutzernamen "${this.customers?.first.aufzugheldenApis.first.username}" wurde entfernt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }
}
