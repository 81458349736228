var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('app-customer-add-vendor-api-wizard-control',{attrs:{"customer-id":_vm.customerId},on:{"vendor-api-added":function($event){_vm.$router.push(_vm.routeToVendorApi($event)).catch(function () {})}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{on:{"click":show}},[_vm._v("Datenanbieter anbinden")])]}}])})],1),_c('ui-cell',{attrs:{"basis":1}},[(_vm.apis.length === 0 && !_vm.apisLoading)?_c('ui-form-message',[_vm._v("Bisher wurden keine Datenanbieter angebunden")]):_c('ui-table-skeleton',{attrs:{"ready":!_vm.$apollo.queries.customer.loading,"column-count":4},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.apis},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-vendor-api-type',{attrs:{"type":_vm.vendorApiTypeString(row)}})]}},{key:"label",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.label))]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.__typename !== 'LegacyVendorApi')?_c('ui-link',{attrs:{"to":_vm.routeToVendorApi(row)}},[_vm._v("Bearbeiten")]):_vm._e()]}}])})]},proxy:true}])})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-pagination',{attrs:{"total-pages":_vm.totalPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }