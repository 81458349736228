
























import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import removeMutation from './remove.gql';
import query from './view.gql';
import editMutation from './edit.gql';
import {
  AppCustomerAareonApiSettingsViewQuery,
  AppCustomerAareonApiSettingsViewQueryVariables,
} from './__generated__/AppCustomerAareonApiSettingsViewQuery';
import {
  AppCustomerAareonApiSettingsViewRemoveMutation,
  AppCustomerAareonApiSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerAareonApiSettingsViewRemoveMutation';
import {
  AppCustomerAareonApiSettingsViewEditMutation,
  AppCustomerAareonApiSettingsViewEditMutationVariables,
} from './__generated__/AppCustomerAareonApiSettingsViewEditMutation';

interface FormData {
  kafkaTopic: string;
}

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerAareonApiSettingsViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAareonApiSettingsViewQuery['customers'];

  private readonly isNonEmptyString = isNonEmptyString;

  private async edit({ ...formData }: FormData): Promise<void> {
    const aareonApiId = this.customers?.first.aareonApi?.id;
    if (aareonApiId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAareonApiSettingsViewEditMutation,
      AppCustomerAareonApiSettingsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          ...formData,
          aareonApiId,
        },
      },
    });

    if (!data) {
      throw new Error('Die Einstellungen konnten nicht gespeichert werden');
    }

    const text = `Einstellungen der Aareon-Integration wurden gespeichert`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    const aareonApiId = this.customers?.first.aareonApi?.id;
    if (aareonApiId === undefined) {
      return;
    }

    if (!window.confirm('Wollen Sie die Aareon-Integration wirklich löschen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAareonApiSettingsViewRemoveMutation,
      AppCustomerAareonApiSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { aareonApiId } },
    });

    if (!data) {
      throw new Error('Die Aareon-Integration konnte nicht entfernt werden');
    }

    const text = `Die Aareon-Integration wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }
}
