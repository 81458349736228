



































import { ATTRIBUTE_CONFIGURATION_TYPE_META } from '@/features/domain-ui/attribute-configuration-type/constants';
import { Option } from '@/features/ui/inputs/model';
import { AddAttributeDefinitionInput, AttributeConfigurationType } from '@/types/iot-portal';
import { ObjectProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppAdminAddAttributeDefinitionWizardControlMutation,
  AppAdminAddAttributeDefinitionWizardControlMutationVariables,
} from './__generated__/AppAdminAddAttributeDefinitionWizardControlMutation';
import addAttributeDefinitionMutation from './add-attribute-definition.gql';

type AttributeDefinition =
  AppAdminAddAttributeDefinitionWizardControlMutation['addAttributeDefinition']['attributeDefinition'];

@Component
export default class AddAttributeDefinitionWizardControl extends Vue {
  @ObjectProp(true)
  private readonly initialData!: Pick<AddAttributeDefinitionInput, 'customerId'> & Partial<AddAttributeDefinitionInput>;

  private get attributeTypeOptions(): Option<AttributeConfigurationType>[] {
    return Object.values(ATTRIBUTE_CONFIGURATION_TYPE_META).sort((a, b) => a.label.localeCompare(b.label));
  }

  private async addAttributeDefinition(
    input: Pick<AddAttributeDefinitionInput, 'name' | 'type'>,
  ): Promise<AttributeDefinition> {
    const { data } = await this.$apollo.mutate<
      AppAdminAddAttributeDefinitionWizardControlMutation,
      AppAdminAddAttributeDefinitionWizardControlMutationVariables
    >({
      mutation: addAttributeDefinitionMutation,
      variables: {
        input: {
          ...this.initialData,
          ...input,
          treeNodeTypes: [],
          description: null,
        },
      },
    });

    if (!data) {
      throw new Error('Das Attribut konnte nicht hinzugefügt werden!');
    }

    return data.addAttributeDefinition.attributeDefinition;
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private isValidData(data: Partial<AddAttributeDefinitionInput>): boolean {
    return this.isNonEmptyString(data.name) && this.isNonEmptyString(data.type);
  }

  private async onSubmitted(definition: AttributeDefinition, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('attribute-definition-added', definition);
  }
}
