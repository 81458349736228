var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.installations},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow",staticStyle:{"font-family":"monospace"}},[_vm._v(_vm._s(row.id))])]}},{key:"heating-system",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'ViessmannConnectedInstallation')?_c('div',[_vm._v(_vm._s(row.heatingSystem.name)),_c('br'),_vm._v(_vm._s(_vm.consumersCount(row))+_vm._s('\u00a0')+"Versorgungsbereiche – "+_vm._s(_vm.measurementsCount(row))+_vm._s('\u00a0')+"Messstellen")]):_vm._e()]}},{key:"site",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'ViessmannConnectedInstallation')?_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.heatingSystem.site.path.items},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}}):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_vm._t("action",null,{"installation":row})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }