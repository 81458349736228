































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerAlertMailConfigurationViewQuery,
  AppCustomerAlertMailConfigurationViewQueryVariables,
} from './__generated__/AppCustomerAlertMailConfigurationViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: AlertMailConfigurationView): AppCustomerAlertMailConfigurationViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(
        this: AlertMailConfigurationView,
        { data, stale }: ApolloQueryResult<AppCustomerAlertMailConfigurationViewQuery>,
      ): void {
        if (data.customers.first.alertMailConfigurations.first === undefined && !stale) {
          this.$router.back();
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AlertMailConfigurationView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerAlertMailConfigurationViewQuery['customers'];
}
