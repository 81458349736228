














































import plusIcon from '@/features/ui/assets/icons/plus.svg';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { AddActivityInput } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppCustomerCustomerActivitiesViewAddActivityMutation,
  AppCustomerCustomerActivitiesViewAddActivityMutationVariables,
} from './__generated__/AppCustomerCustomerActivitiesViewAddActivityMutation';
import {
  AppCustomerCustomerActivitiesViewQuery,
  AppCustomerCustomerActivitiesViewQueryVariables,
} from './__generated__/AppCustomerCustomerActivitiesViewQuery';
import addActivityMutation from './add-activity.gql';
import query from './view.gql';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: ActivitiesView): AppCustomerCustomerActivitiesViewQueryVariables {
        return { id: this.customerId, take: this.pageSize, skip: this.pageSize * (this.currentPage - 1) };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class ActivitiesView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private customer?: AppCustomerCustomerActivitiesViewQuery['customer'];

  private readonly plusIcon = plusIcon;

  private readonly columns: Column[] = [
    { name: 'name' },
    { name: 'count', label: '# Dienstleister' },
    { name: 'action', label: '', align: 'right' },
  ];

  protected get count(): number {
    return this.customer?.first.activities.count ?? 0;
  }

  private async addActivity(input: AddActivityInput): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppCustomerCustomerActivitiesViewAddActivityMutation,
      AppCustomerCustomerActivitiesViewAddActivityMutationVariables
    >({
      mutation: addActivityMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data) {
      throw new Error('Die Aktivitäten konnte nicht hinzugefügt werden!');
    }

    return data.addActivity.activity.id;
  }

  private async onSubmitted(activityId: string, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppCustomer/Activity', params: { activityId } });
  }
}
