











































































































































import { RestClient } from '@/features/core/rest-client/rest-client';
import { GATEWAY_HEALTH_META } from '@/features/domain-ui/gateway-health/constants';
import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';
import { EditVendorApiInput } from '@/types/notification-api.model';
import { defineComponent } from '@vue/composition-api';
import { at, isString } from 'lodash';
import { EMPTY, lastValueFrom } from 'rxjs';
import { mapActions } from 'vuex';
import treeNodeQuery from './treeNodeQuery.gql';
import query from './view.gql';

interface SettingsViewProps {
  customerId: string;
}

export default defineComponent<SettingsViewProps, Record<string, unknown>, any, any>({
  inject: ['GENERIC_NOTIFICATION_API_REST_CLIENT'],
  props: {
    customerId: { type: String, required: true },
  },
  data() {
    return {
      customers: undefined,
      config: {} as EditVendorApiInput,
      client: undefined as RestClient | undefined,
      partnerName: 'IVM Professional®',
      loading: true,
    };
  },
  computed: {
    gatewayHealthOptions() {
      return at(GATEWAY_HEALTH_META, 'CRITICAL', 'DEGRADED');
    },
    spotHealthOptions() {
      return at(SPOT_HEALTH_META, 'CRITICAL', 'DEGRADED');
    },
    rootIds() {
      return this.customers === undefined ? [] : [this.customers.first.rootDirectory.id];
    },
  },
  created() {
    this.client = this.GENERIC_NOTIFICATION_API_REST_CLIENT as RestClient;
  },
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables() {
        return { customerId: this.customerId };
      },
      result() {
        // Execute your logic after the query has returned something
        this.loadData();
      },
    },
  },
  methods: {
    ...mapActions(['ADD_TOAST_MESSAGES']),
    async getConfig() {
      try {
        return await lastValueFrom(
          this.client.get(`/api/v1/notification?customerId=${this.customerId}&vendorName=${'MockApi'}`) ?? EMPTY,
        );
      } catch (error) {
        throw new Error('Die Einstellungen der IVM Professional® Integration konnten nicht geladen werden');
      }
    },
    async edit(input: EditVendorApiInput) {
      this.loading = true;
      try {
        await lastValueFrom(
          this.client.put('/api/v1/notification', {
            customerId: this.customerId,
            vendorId: this.config.vendorId,
            ...input,
          }) ?? EMPTY,
        );
        this.loadData();
      } catch (error) {
        this.loading = false;
        throw new Error('Die Einstellungen der IVM Professional® Integration konnten nicht gespeichert werden');
      }

      const text = `Die Einstellungen der IVM Professional® Integration wurden gespeichert`;
      this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
    },
    async remove() {
      if (!window.confirm('Die IVM Professional® Integration wurde entfernt')) {
        return;
      }

      this.loading = true;
      try {
        await lastValueFrom(
          this.client.delete('/api/v1/notification', {
            customerId: this.customerId,
            vendorId: this.config.vendorId,
          }) ?? EMPTY,
        );
      } catch (error) {
        throw new Error('Die IVM Professional® Integration konnte nicht entfernt werden');
      }

      this.loading = false;
      const text = `Die IVM Professional® Integration wurde entfernt`;
      this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

      this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
    },
    pluckIds<T>(objects: { id: T }[] | null): T[] {
      return objects === null ? [] : objects.map(({ id }) => id);
    },
    isNonEmptyString(value: unknown): boolean {
      return isString(value) && value.length > 0;
    },
    consentStatusText(consent: boolean): string {
      if (consent) {
        return `Die Integration wurde aktiviert. ${this.partnerName} wird nun im Falle von identifzierten Alarmmeldungen von Geräten oder Heizungsanlagen übermittelt. Wenn Sie die Integration deaktivieren möchten, dann entfernen Sie den Haken unter Einverständniserklärung und speichern die neuen Einstellungen`;
      }
      return `Die Integration wurde deaktiviert. ${this.partnerName} erhält aktuell keine Alarmmeldungen.`;
    },
    filterTreeNodes(treeNode: any) {
      return !treeNode.name.toLowerCase().includes('temperatur');
    },
    loadData() {
      this.getConfig().then((response: EditVendorApiInput) => {
        this.$apollo
          .query({
            query: treeNodeQuery,
            variables: { ids: response.treeNodeIds },
          })
          .then((treeNodes: any) => {
            this.config = {
              ...response,
              alertCategories: this.customers?.first.alertCategories.items.filter((category: any) =>
                response.alertCategoryIds?.includes(category.id),
              ),
              treeNodes: response.treeNodeIds ? treeNodes?.data.treeNodes : null,
            };
            this.loading = false;
          });
      });
    },
  },
});
