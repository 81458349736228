




































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerAareonApiViewQuery,
  AppCustomerAareonApiViewQueryVariables,
} from './__generated__/AppCustomerAareonApiViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: AareonApiView): AppCustomerAareonApiViewQueryVariables {
        return { customerId: this.customerId };
      },
      result(this: AareonApiView, { data }: ApolloQueryResult<AppCustomerAareonApiViewQuery>): void {
        if (data.customers.first.aareonApi === null) {
          this.$router.back();
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AareonApiView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly customers?: AppCustomerAareonApiViewQuery['customers'];
}
