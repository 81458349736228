




















import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerViessmannInstallationFragment } from './__generated__/AppCustomerViessmannInstallationFragment';
import { AppCustomerViessmannInstallationTableFragment } from './__generated__/AppCustomerViessmannInstallationTableFragment';

@Component
export default class ViessmannInstallationTable extends Vue {
  @ArrayProp(() => [])
  private readonly installations!: AppCustomerViessmannInstallationTableFragment[];

  private readonly columns: Column[] = [
    { name: 'id', label: 'Heizungssystem' },
    { name: 'heating-system', label: '' },
    { name: 'site', label: '' },
    { name: 'action', label: '', align: 'right' },
  ];

  private measurementsCount(installation: AppCustomerViessmannInstallationFragment): number {
    return installation.heatingSystem.measurements.length;
  }

  private consumersCount(installation: AppCustomerViessmannInstallationFragment): number {
    return installation.heatingSystem.consumers.length;
  }
}
