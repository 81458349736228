import { ServiceProviderAuthMethod } from '@/types/iot-portal';

export { ServiceProviderAuthMethod } from '@/types/iot-portal';

export interface ServiceProviderAuthMethodMeta {
  value: ServiceProviderAuthMethod;
  label: string;
  description?: string;
}

export const SERVICE_PROVIDER_AUTH_METHOD_META: {
  readonly [k in ServiceProviderAuthMethod]: Readonly<ServiceProviderAuthMethodMeta>;
} = Object.freeze({
  [ServiceProviderAuthMethod.PIN]: {
    value: ServiceProviderAuthMethod.PIN,
    label: 'Pin',
  },
  [ServiceProviderAuthMethod.TOKEN]: {
    value: ServiceProviderAuthMethod.TOKEN,
    label: 'Token',
  },
});
