













































import { AppCustomerAufzugheldenDeviceTableDeviceFragment } from '@/features/app-customer/components/aufzughelden-device-table/__generated__/AppCustomerAufzugheldenDeviceTableDeviceFragment';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { NIL } from 'uuid';
import { Component, Vue } from 'vue-property-decorator';
import devicesQuery from './devices.gql';
import unmountMutation from './unmount.gql';
import query from './view.gql';
import {
  AppCustomerAufzugheldenApiAvailableDevicesViewDevicesQuery,
  AppCustomerAufzugheldenApiAvailableDevicesViewDevicesQueryVariables,
} from './__generated__/AppCustomerAufzugheldenApiAvailableDevicesViewDevicesQuery';
import {
  AppCustomerAufzugheldenApiAvailableDevicesViewQuery,
  AppCustomerAufzugheldenApiAvailableDevicesViewQueryVariables,
} from './__generated__/AppCustomerAufzugheldenApiAvailableDevicesViewQuery';
import {
  AppCustomerAufzugheldenApiAvailableDevicesViewUnmountMutation,
  AppCustomerAufzugheldenApiAvailableDevicesViewUnmountMutationVariables,
} from './__generated__/AppCustomerAufzugheldenApiAvailableDevicesViewUnmountMutation';

type Device = AppCustomerAufzugheldenDeviceTableDeviceFragment;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: AvailableDevicesView): AppCustomerAufzugheldenApiAvailableDevicesViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(
        this: AvailableDevicesView,
        { data }: ApolloQueryResult<AppCustomerAufzugheldenApiAvailableDevicesViewQuery>,
      ): void {
        const aufzugheldenAccounts = data.customers.first.aufzugheldenApis.first.accounts?.items;
        if (aufzugheldenAccounts?.length === 1) {
          this.selectedAccountId = aufzugheldenAccounts[0].accountId;
        }
      },
    },
    devices: {
      query: devicesQuery,
      fetchPolicy: 'cache-and-network',
      skip(this: AvailableDevicesView): boolean {
        return this.selectedAccountId === null;
      },
      variables(this: AvailableDevicesView): AppCustomerAufzugheldenApiAvailableDevicesViewDevicesQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId, accountId: this.selectedAccountId ?? NIL };
      },
    },
  },
  data() {
    return { customers: undefined, devices: undefined, selectedAccountId: null };
  },
})
export default class AvailableDevicesView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAufzugheldenApiAvailableDevicesViewQuery['customers'];

  private readonly devices?: AppCustomerAufzugheldenApiAvailableDevicesViewDevicesQuery['devices'];

  private selectedAccountId!: string | null;

  private get accountOptions(): Option[] {
    return (
      this.customers?.first.aufzugheldenApis.first.accounts?.items.map(
        ({ accountId, accountName, accountDescription }) => ({
          value: accountId,
          label: [accountName, accountDescription].filter(({ length }) => length > 0).join(' – '),
        }),
      ) ?? []
    );
  }

  private async unmount(device: Device): Promise<void> {
    if (!window.confirm('Möchten Sie die Verbindung zu diesem Aufzug wirklich trennen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAufzugheldenApiAvailableDevicesViewUnmountMutation,
      AppCustomerAufzugheldenApiAvailableDevicesViewUnmountMutationVariables
    >({
      mutation: unmountMutation,
      variables: { input: { deviceId: device.deviceId } },
    });

    if (!data) {
      throw new Error('Die Verbindung konnte nicht getrennt werden');
    }

    const text = `Die Verbindung zum Aufzug "${device.deviceId}" wurde getrennt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$emit('aufzughelden-device-unmounted', device);
  }
}
