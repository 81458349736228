






























































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { at, isString } from 'lodash';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { EMPTY, lastValueFrom } from 'rxjs';
import { CONSUMPTION_API_REST_CLIENT } from '../../../../core/container';
import { ConsumptionAccess } from '@/types/consumption-api/consumption-access.model';
import { Observable } from 'rxjs';
import { RestClient } from '../../../../core/rest-client/rest-client';
import { EditConsumptionAccessFormData } from '../models/edit-consumption-access-form-data.model';
import { ConsumptionAccessUpdate } from '@/types/consumption-api/consumption-access-update.model';
import { ConsumptionAccessInitialFormData } from '../models/consumption-access-initial-form-data.model';
import { TreeNodeNamesQuery, TreeNodeNamesQueryVariables } from './__generated__/TreeNodeNamesQuery';
import { ApolloQueryResult } from '@@/node_modules/apollo-client';
import query from './tree-node-names.gql';
import Spinner from '@/components/clickables/Spinner.vue';

const vendorApiName = 'IVM Professional® Consumption API';

@Component({
  name: 'IVMSettingsView',
  components: { Spinner },
  data(this: IvmSettingsView) {
    this.getConsumptionAccess(this.vendorApiId).subscribe((response) => {
      this.consumptionAccess = response;
    });

    return { consumptionAccess: undefined, treeNodes: undefined };
  },
  apollo: {
    treeNodes: {
      query,
      fetchPolicy: 'cache-and-network',
      skip(this: IvmSettingsView): boolean {
        return this.loaded;
      },
      variables(this: IvmSettingsView): TreeNodeNamesQueryVariables {
        if (this.consumptionAccess) {
          return { ids: this.consumptionAccess.permittedTreeNodeIds };
        }

        return { ids: [] };
      },
    },
  },
})
export default class IvmSettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private consumptionAccess?: ConsumptionAccess;
  private readonly treeNodes?: TreeNodeNamesQuery['treeNodes'];
  private loaded = false;

  @Inject(CONSUMPTION_API_REST_CLIENT)
  private client?: RestClient;

  private async edit(input: EditConsumptionAccessFormData): Promise<void> {
    const consumptionAccessUpdate: ConsumptionAccessUpdate = {
      id: this.vendorApiId,
      name: input.name,
      enabled: input.enabled,
      permittedTreeNodeIds: input.permittedTreeNodeIds,
    };
    const response = await lastValueFrom(this.editConsumptionAccess(consumptionAccessUpdate));

    this.updateConsumptionAccess(consumptionAccessUpdate);
    this.loaded = false;

    const text = `Die Einstellungen der IVM Professional® Consumption API Integration wurden gespeichert`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    if (!window.confirm('Die IVM Professional® Consumption API Integration wurde entfernt')) {
      return;
    }

    const response = await lastValueFrom(this.deleteConsumptionAccess(this.vendorApiId));

    const text = `Die IVM Professional® Integration Consumption API wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private pluckIds<T>(objects: { id: T }[] | null): T[] {
    return objects === null ? [] : objects.map(({ id }) => id);
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private getConsumptionAccess(id: string): Observable<ConsumptionAccess> {
    const response = this.client?.get<ConsumptionAccess>(`/api/v1/consumption/consumption-access?id=${id}`) ?? EMPTY;

    return response;
  }

  private editConsumptionAccess(updatePayload: ConsumptionAccessUpdate): Observable<string> {
    const response =
      this.client?.put<ConsumptionAccessUpdate>(
        `/api/v1/consumption/consumption-access?id=${this.vendorApiId}`,
        updatePayload,
      ) ?? EMPTY;

    return response;
  }

  private deleteConsumptionAccess(id: string): Observable<string> {
    const response = this.client?.delete(`/api/v1/consumption/consumption-access?id=${id}`) ?? EMPTY;

    return response;
  }

  private get rootIds(): string[] {
    return [this.customerId];
  }

  private get selectableTreeNodeTypes(): string[] {
    return ['Property'];
  }

  private get loading(): boolean {
    const loading = !this.consumptionAccess || this.$apollo.queries.treeNodes.loading;
    this.loaded = !loading;
    return loading;
  }

  private get treeNodeNames(): TreeNodeNamesQuery['treeNodes']['items'] {
    return this.treeNodes?.items ?? [];
  }

  private get initialData(): ConsumptionAccessInitialFormData | undefined {
    if (this.consumptionAccess) {
      const permittedTreeNodes =
        this.consumptionAccess.permittedTreeNodeIds?.map((treeNodeId) => ({
          id: treeNodeId,
          name: this.getTreeNodeName(treeNodeId) || '',
        })) || [];

      return { ...this.consumptionAccess, permittedTreeNodes };
    }

    return undefined;
  }

  private getTreeNodeName(id: string): string | undefined {
    return this.treeNodeNames.find((treeNode) => treeNode.id == id)?.name;
  }

  private updateConsumptionAccess(consumptionAccessUpdate: ConsumptionAccessUpdate): void {
    if (this.consumptionAccess) {
      this.consumptionAccess.enabled = consumptionAccessUpdate.enabled;
      this.consumptionAccess.name = consumptionAccessUpdate.name;
      this.consumptionAccess.permittedTreeNodeIds = consumptionAccessUpdate.permittedTreeNodeIds;
    }
  }

  private get consentStatement(): string {
    return `Hiermit bestätige ich, dass die Intgeration zwischen der GK4.0 Plattform und ${vendorApiName} aktiviert werden soll. Es sollen alle Daten anhand der gesetzten Konfiguration übermittelt oder empfangen werden. Bitte speichern Sie die neuen Einstellungen.`;
  }

  private consentStatusText(consent: boolean): string {
    if (consent) {
      return `Die Integration wurde aktiviert. Es werden anhand der vorgenommenen Konfiguration Dateien importiert & Verbrauchsdaten bereitgestellt. Wenn Sie die Integration deaktivieren möchten, dann entfernen Sie den Haken unter Einverständniserklärung und speichern die neuen Einstellungen`;
    }
    return `Die Integration wurde deaktiviert. Es werden aktuell keine Daten importiert oder Verbrauchsdaten bereitgestellt.`;
  }
}
