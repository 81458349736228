































import { ConnectNukiSmartlockInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import connectNukiSmartlockMutation from './connect-nuki-smartlock.gql';
import {
  AppCustomerConnectNukiSmartlockWizardControlMutation,
  AppCustomerConnectNukiSmartlockWizardControlMutationVariables,
} from './__generated__/AppCustomerConnectNukiSmartlockWizardControlMutation';

type NukiSmartlock = AppCustomerConnectNukiSmartlockWizardControlMutation['connectNukiSmartlock']['smartlock'];
type FormData = Pick<ConnectNukiSmartlockInput, 'parentId' | 'name'>;

@Component
export default class ConnectNukiSmartlockWizardControl extends Vue {
  @StringProp(true)
  private readonly rootDirectoryId!: string;

  @StringProp(true)
  private readonly nukiApiId!: string;

  @StringProp(true)
  private readonly smartlockId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private get title(): string {
    return `Schließanlage verknüpfen`;
  }

  private get rootIds(): string[] {
    return [this.rootDirectoryId];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [TreeNodeType.PropertySubdivision];
  }

  private async connectNukiSmartlock(input: FormData): Promise<NukiSmartlock> {
    const { data } = await this.$apollo.mutate<
      AppCustomerConnectNukiSmartlockWizardControlMutation,
      AppCustomerConnectNukiSmartlockWizardControlMutationVariables
    >({
      mutation: connectNukiSmartlockMutation,
      variables: {
        input: {
          name: input.name,
          nukiApiId: this.nukiApiId,
          parentId: input.parentId,
          smartlockId: this.smartlockId,
        },
      },
    });

    if (!data) {
      throw new Error('Die Schließanlage konnte nicht verknüpft werden!');
    }

    return data.connectNukiSmartlock.smartlock;
  }

  private async onSubmitted(smartlock: NukiSmartlock, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('nuki-smartlock-connected', smartlock);
  }

  private isValidData(data: FormData): boolean {
    return isNonEmptyString(data.parentId) && isNonEmptyString(data.name);
  }

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }
}
