










































import { ConnectViessmannInstallationInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import connectViessmannInstallationMutation from './connect-viessmann-installation.gql';
import {
  AppCustomerConnectViessmannInstallationWizardControlMutation,
  AppCustomerConnectViessmannInstallationWizardControlMutationVariables,
} from './__generated__/AppCustomerConnectViessmannInstallationWizardControlMutation';

type ViessmannInstallation =
  AppCustomerConnectViessmannInstallationWizardControlMutation['connectViessmannInstallation']['installation'];
type FormData = Pick<ConnectViessmannInstallationInput, 'siteId' | 'consumerIds' | 'name'>;

@Component
export default class ConnectViessmannInstallationWizardControl extends Vue {
  @StringProp(true)
  private readonly viessmannApiId!: string;

  @StringProp(true)
  private readonly installationId!: string;

  @StringProp(true)
  private readonly rootDirectoryId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private get title(): string {
    return `Heizungssystem "${this.installationId}" verknüpfen`;
  }

  private get rootIds(): string[] {
    return [this.rootDirectoryId];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [TreeNodeType.Property, TreeNodeType.PropertyGroup, TreeNodeType.PropertySubdivision];
  }

  private get selectableConsumerTypes(): TreeNodeType[] {
    return [TreeNodeType.Property, TreeNodeType.PropertyGroup, TreeNodeType.PropertySubdivision];
  }

  private isValidData(data: FormData): boolean {
    return isNonEmptyString(data.name) && isNonEmptyString(data.siteId);
  }

  private async connectViessmannInstallation(input: FormData): Promise<ViessmannInstallation> {
    const { data } = await this.$apollo.mutate<
      AppCustomerConnectViessmannInstallationWizardControlMutation,
      AppCustomerConnectViessmannInstallationWizardControlMutationVariables
    >({
      mutation: connectViessmannInstallationMutation,
      variables: {
        input: {
          siteId: input.siteId,
          viessmannApiId: this.viessmannApiId,
          installationId: parseInt(this.installationId),
          name: input.name,
          consumerIds: input.consumerIds ?? [],
        },
      },
    });

    if (!data) {
      throw new Error('Das Heizungssystem konnte nicht verknüpft werden!');
    }

    return data.connectViessmannInstallation.installation;
  }

  private async onSubmitted(installation: ViessmannInstallation, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$emit('viessmann-installation-connected', installation);
  }

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }

  private pluckObjectId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
