
























import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { DomainUiAttributeDefinitionTableAttributeDefinitionFragment } from '@/features/domain-ui/attribute-definition-table/__generated__/DomainUiAttributeDefinitionTableAttributeDefinitionFragment';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerCustomerAttributeDefinitionsViewQuery,
  AppCustomerCustomerAttributeDefinitionsViewQueryVariables,
} from './__generated__/AppCustomerCustomerAttributeDefinitionsViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables(this: AttributeDefinitionsView): AppCustomerCustomerAttributeDefinitionsViewQueryVariables {
        return {
          searchQuery: this.searchQuery === '' ? null : this.searchQuery,
          customerId: this.customerId,
          skip: this.skip,
          take: this.take,
        };
      },
      result(this: AttributeDefinitionsView): void {
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class AttributeDefinitionsView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerCustomerAttributeDefinitionsViewQuery['customers'];

  private searchQuery = '';

  private get attributeDefinitions(): DomainUiAttributeDefinitionTableAttributeDefinitionFragment[] | undefined {
    return this.customers?.first.attributeDefinitions.items;
  }

  protected get count(): number {
    return this.customers?.first.attributeDefinitions.count ?? 0;
  }

  private get nextSearchQuery(): string {
    return isString(this.$route.query.query) ? this.$route.query.query : '';
  }

  private set nextSearchQuery(value: string) {
    this.$router.replace({ query: { ...this.$route.query, query: value === '' ? undefined : value } });

    if (!this.$apollo.queries.customers.loading) {
      this.searchQuery = value;
    }
  }

  private onAttributeDefinitionAdded(attributeDefinitionId: string): void {
    this.$router.push({ name: 'AppCustomer/AttributeDefinition', params: { attributeDefinitionId } });
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Attribut erfolgreich erstellt', class: 'success' }],
    });
  }
}
