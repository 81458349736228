








import { RootState } from '@/features/core/store';
import { NIL } from 'uuid';
import { Component, Vue } from 'vue-property-decorator';
import entryCustomerQuery from './entry-customer.gql';
import {
  AppCustomerAppViewEntryCustomerQuery,
  AppCustomerAppViewEntryCustomerQueryVariables,
} from './__generated__/AppCustomerAppViewEntryCustomerQuery';

@Component
export default class AppView extends Vue {
  @RootState
  private readonly appCustomerLastCustomerId?: string;

  private noCustomersAvailable = false;

  private async created(): Promise<void> {
    const { data } = await this.$apollo.query<
      AppCustomerAppViewEntryCustomerQuery,
      AppCustomerAppViewEntryCustomerQueryVariables
    >({
      query: entryCustomerQuery,
      variables: { lastCustomerId: this.appCustomerLastCustomerId ?? NIL },
    });

    const customerId = data.lastCustomer.items[0]?.id ?? data.customers.items[0]?.id;

    if (customerId === undefined) {
      this.noCustomersAvailable = true;

      return;
    }

    try {
      await this.$router.replace({ name: 'AppCustomer/Customer', params: { customerId } });
    } catch (e) {
      if (!(e instanceof Error) || !e.message.includes('Redirected when going from')) {
        throw e;
      }
    }
  }
}
