




import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import DataConsumptionPollPanel from '@/features/app-customer/components/data-consumption-poll-panel/DataConsumptionPollPanel.vue';

@Component({
  components: { DataConsumptionPollPanel },
  data() {
    return { customers: undefined };
  },
})
export default class DataConsumptionPollView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;
}
