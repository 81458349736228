



























import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { SetEnerIqEconomicUnitConfigurationInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import disconnectMutation from './disconnect.gql';
import query from './view.gql';
import setConfigurationMutation from './set-configuration.gql';
import {
  AppCustomerEnerIqConnectedEconomicUnitSettingsViewDisconnectMutation,
  AppCustomerEnerIqConnectedEconomicUnitSettingsViewDisconnectMutationVariables,
} from './__generated__/AppCustomerEnerIqConnectedEconomicUnitSettingsViewDisconnectMutation';
import {
  AppCustomerEnerIqConnectedEconomicUnitSettingsViewQuery,
  AppCustomerEnerIqConnectedEconomicUnitSettingsViewQueryVariables,
} from './__generated__/AppCustomerEnerIqConnectedEconomicUnitSettingsViewQuery';
import {
  AppCustomerEnerIqConnectedEconomicUnitSettingsViewSetConfigurationMutation,
  AppCustomerEnerIqConnectedEconomicUnitSettingsViewSetConfigurationMutationVariables,
} from './__generated__/AppCustomerEnerIqConnectedEconomicUnitSettingsViewSetConfigurationMutation';
import { omitDeepBy } from '@/util/omit-deep-by';
type FormData = Pick<SetEnerIqEconomicUnitConfigurationInput, 'configuration'>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerEnerIqConnectedEconomicUnitSettingsViewQueryVariables {
        return {
          customerId: this.customerId,
          connectedEconomicUnitId: this.connectedEconomicUnitId,
        };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @StringProp(true)
  private readonly connectedEconomicUnitId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerEnerIqConnectedEconomicUnitSettingsViewQuery['customers'];

  private async setConnectedEconomicUnitConfiguration({ configuration }: FormData): Promise<void> {
    const economicUnitId = this.customers?.first.enerIqApi?.connectedEconomicUnits.first.id;
    if (economicUnitId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerEnerIqConnectedEconomicUnitSettingsViewSetConfigurationMutation,
      AppCustomerEnerIqConnectedEconomicUnitSettingsViewSetConfigurationMutationVariables
    >({
      mutation: setConfigurationMutation,
      variables: {
        input: {
          id: this.connectedEconomicUnitId,
          configuration,
        },
      },
    });

    if (!data) {
      throw new Error('Die Einstellungen konnten nicht gespeichert werden');
    }

    const text = `Einstellungen der EnerIQ-Integration wurden gespeichert`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  public async disconnect(): Promise<void> {
    if (!window.confirm('Wollen Sie die Verknüpfung der Heizungsanlage zu EnerIQ wirklich trennen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerEnerIqConnectedEconomicUnitSettingsViewDisconnectMutation,
      AppCustomerEnerIqConnectedEconomicUnitSettingsViewDisconnectMutationVariables
    >({
      mutation: disconnectMutation,
      variables: { input: { id: this.connectedEconomicUnitId } },
    });

    if (!data) {
      throw new Error('Die Verknüpfung der Heizungsanlage zu EnerIQ konnte nicht getrennt werden');
    }

    const text = ` Die Verknüpfung der Heizungsanlage zu EnerIQ wurde getrennt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({
      name: 'AppCustomer/VendorApis/EnerIqApi/EconomicUnits',
      params: { customerId: this.customerId, vendorApiId: this.vendorApiId },
    });
  }

  private jsonStringify(value: unknown): string {
    return JSON.stringify(
      omitDeepBy(value, (value, key) => key === '__typename'),
      undefined,
      2,
    );
  }

  private jsonParse(value: string): unknown {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }
}
