






























import { ArrayProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { TreeNode, TreePath } from '@/features/app-customer/views/comgy-api/connect-devices/comgy-types';

type FormData = { parent: TreeNode };

@Component
export default class MapComgyObjectWizardControl extends Vue {
  @StringProp(true)
  private readonly comgyApiId!: string;

  @StringProp(true)
  private readonly title!: string;

  @ObjectProp(false)
  private readonly value?: TreePath;

  @ArrayProp(true)
  private readonly selectableTypes!: string[];

  @StringProp(true)
  private readonly rootDirectoryId!: string;

  private get initialSelection(): TreeNode | undefined {
    return this.value !== undefined && this.value.items.length
      ? this.value.items[this.value.items.length - 1]
      : undefined;
  }

  private get rootIds(): string[] {
    return [this.rootDirectoryId];
  }

  private isValidData(data: FormData): boolean {
    return !!data.parent;
  }

  private async onSubmitted(input: FormData, hide: () => Promise<void>): Promise<void> {
    this.$emit('input', { items: [input.parent] });
    await hide();
  }
}
