
























import { Component, Vue } from 'vue-property-decorator';
import { EnumProp, StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { AddConsumptionDataExchangeConfigurationInput, ConsumptionDataExchangeType } from '@/types/iot-portal';
import addConsumptionDataExchangeConfiguration from '../consumption-data-exchange/add-consumption-data-exchange.gql';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import {
  AppCustomerAddConsumptionExchangeConfigMutation,
  AppCustomerAddConsumptionExchangeConfigMutationVariables,
} from '../consumption-data-exchange/__generated__/AppCustomerAddConsumptionExchangeConfigMutation';

@Component
export default class ConsumptionDataExchange extends Vue {
  private readonly isNonEmptyString = isNonEmptyString;

  @StringProp(true)
  private readonly customerId!: string;

  @EnumProp(...Object.values(ConsumptionDataExchangeType))
  private readonly type!: ConsumptionDataExchangeType;

  private typeName(): string {
    return this.type == ConsumptionDataExchangeType.VarysSW ? 'Stadtwerke Eisleben' : this.type;
  }
  private isValidData(data: Partial<AddConsumptionDataExchangeConfigurationInput>): boolean {
    return this.isNonEmptyString(data.label);
  }

  private async addConsumptionExchangeProvider(
    input: AddConsumptionDataExchangeConfigurationInput,
  ): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddConsumptionExchangeConfigMutation,
      AppCustomerAddConsumptionExchangeConfigMutationVariables
    >({
      mutation: addConsumptionDataExchangeConfiguration,
      variables: { input: { ...input, customerId: this.customerId, type: this.type } },
    });

    if (!data) {
      throw new Error('Der Datenanbieter konnte nicht hinzugefügt werden!');
    }

    return data.addConsumptionDataExchangeConfiguration.consumptionDataExchangeConfiguration;
  }
}
