var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.smartlocks},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.id))])]}},{key:"details",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.details.name))])]}},{key:"path",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'NukiConnectedSmartlock')?_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.deviceMount.spot.path.items},on:{"tree-node-click":function($event){return _vm.$emit('tree-node-click', $event)}}}):[_c('em',[_vm._v("Nicht verknüpft")])]]}},{key:"integration-spot",fn:function(ref){
var row = ref.row;
return [(row.__typename === 'NukiConnectedSmartlock')?_c('domain-ui-spot-info',{attrs:{"spot":row.deviceMount.spot},on:{"click":function($event){return _vm.$emit('spot-click', row.deviceMount.spot)}}}):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_vm._t("action",null,{"smartlock":row})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }