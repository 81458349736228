var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-layout',{attrs:{"use":"core-layout-default"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.serviceProvider)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-content-header',{attrs:{"headline":_vm.serviceProvider.name,"back-link":""},on:{"back-click":function($event){return _vm.$router.go(-1)}}},[_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer' }}},[_vm._v("Kunden")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Customer', params: { customerId: _vm.customers.first.id } }}},[_vm._v(_vm._s(_vm.customers.first.name))]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Customer/ServiceProviders', params: { customerId: _vm.customers.first.id } }}},[_vm._v("Dienstleister")])],1)],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel',[_c('ui-form',{attrs:{"initial-data":_vm.serviceProvider,"action":_vm.editServiceProvider},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"name","path":"name","label":"Firma"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"firstName","path":"firstName","label":"Vorname"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"lastName","path":"lastName","label":"Nachname"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"email","path":"email","label":"Email"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"phoneNumber","path":"phoneNumber","label":"Telefonnummer"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"auth.type","option-value":"value","label":"Auth Method","initial-path":"auth.type","options":_vm.authenticationMethodOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
}}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[(!_vm.isAuthValueInputHidden(formData.auth))?_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"auth.value","path":"auth.value","label":"Auth Value"}}):_vm._e()],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"core-input-tree-node-select-control","initial-path":"treeNodes.items","path":"treeNodeIds","label":"Gültig für","transformer":_vm.pluckId,"root-ids":[_vm.customerId]}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"checkOutInterval","path":"checkOutInterval","label":"Abschlusszeitraum","description":"                   In diesem Zeitraum kann die Aktivität als abgeschlossen markiert werden.                   Diese Einstellung überschreibt den Abschlusszeitraum der in der Aktivität konfiguriert wurde.                 ","clearable":false,"options":_vm.options,"reduce":function (ref) {
	var value = ref.value;

	return value;
}}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,false,4064345564)})],1)],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }