var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":"Attribut anlegen"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":_vm.initialData,"action":_vm.addAttributeDefinition,"no-reset":""},on:{"submitted":function($event){return _vm.onSubmitted($event.result, hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","label":"Name","error":!_vm.isNonEmptyString(formData.name)}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","path":"type","label":"Typ","options":_vm.attributeTypeOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"error":!_vm.isNonEmptyString(formData.type)}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.isValidData(formData),"busy":busy},on:{"click":submit}},[_vm._v("Anlegen")])],1)],1)]}}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }