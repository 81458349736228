































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerConsumptionExchangeConfigurationViewQuery,
  AppCustomerConsumptionExchangeConfigurationViewQueryVariables,
} from './__generated__/AppCustomerConsumptionExchangeConfigurationViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(
        this: ConsumptionDataExchangeConfigurationView,
      ): AppCustomerConsumptionExchangeConfigurationViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(
        this: ConsumptionDataExchangeConfigurationView,
        { data, stale }: ApolloQueryResult<AppCustomerConsumptionExchangeConfigurationViewQuery>,
      ): void {
        if (data && data.customers) {
          if (data.customers.first.consumptionDataExchangeConfigurations.first === undefined && !stale) {
            this.$router.back();
          }
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class ConsumptionDataExchangeConfigurationView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerConsumptionExchangeConfigurationViewQuery['customers'];
}
