var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-flex',[(_vm.measurementSelections.length === 0 && !_vm.$apollo.queries.customers.loading)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Es gibt keine Messstelle.")])],1):[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table-skeleton',{attrs:{"ready":!_vm.$apollo.queries.customers.loading,"column-count":2,"line-count":3,"header-line-count":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.measurementSelections},scopedSlots:_vm._u([{key:"selected",fn:function(ref){
var row = ref.row;
return [_c('ui-input-checkbox',{model:{value:(row.selected),callback:function ($$v) {_vm.$set(row, "selected", $$v)},expression:"row.selected"}})]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('ui-input-text',{attrs:{"disabled":row.selected === false},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}},{key:"consumer",fn:function(ref){
var row = ref.row;
return [_c('ui-input-select',{attrs:{"options":_vm.consumerOptions,"clearable":false,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"disabled":row.selected === false},model:{value:(row.consumerId),callback:function ($$v) {_vm.$set(row, "consumerId", $$v)},expression:"row.consumerId"}})]}}],null,true)})]},proxy:true}],null,true)})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!_vm.isValidSelection,"busy":_vm.busy},on:{"click":function($event){return _vm.addMeasurements(hide)}}},[_vm._v("Anlegen")])],1)]],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }