























































































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { generateActivityCheckInAuthTokenUrl } from '@/features/domain-ui/activity-tree-node-bookmark/util';
import plusIcon from '@/features/ui/assets/icons/plus.svg';
import { Option } from '@/features/ui/inputs/model';
import { Column } from '@/features/ui/table/model';
import { AddServiceProviderInput, ServiceProviderAuthMethod, ServiceProviderStatus } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { v4 as uuidv4 } from 'uuid';
import { Component, Mixins } from 'vue-property-decorator';
import { SERVICE_PROVIDER_AUTH_METHOD_META } from '../../service-provider/constants';
import AddServiceProviderMutation from './add-service-provider.gql';
import query from './view.gql';
import {
  AppCustomerCustomerServiceProvidersViewAddServiceProviderMutation,
  AppCustomerCustomerServiceProvidersViewAddServiceProviderMutationVariables,
} from './__generated__/AppCustomerCustomerServiceProvidersViewAddServiceProviderMutation';
import {
  AppCustomerCustomerServiceProvidersViewQuery,
  AppCustomerCustomerServiceProvidersViewQueryVariables,
} from './__generated__/AppCustomerCustomerServiceProvidersViewQuery';

type ServiceProvider =
  AppCustomerCustomerServiceProvidersViewQuery['customer']['first']['serviceProviders']['items'][number];

type FormDataAuth = NonNullable<AddServiceProviderInput['auth']>;

@Component({
  apollo: {
    customer: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: ServiceProvidersView): AppCustomerCustomerServiceProvidersViewQueryVariables {
        return { id: this.customerId, take: this.pageSize, skip: this.pageSize * (this.currentPage - 1) };
      },
    },
  },
  data() {
    return { customer: undefined };
  },
})
export default class ServiceProvidersView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private customer?: AppCustomerCustomerServiceProvidersViewQuery['customer'];

  private readonly plusIcon = plusIcon;

  private readonly columns: Column[] = [
    { name: 'name', label: 'Firma' },
    { name: 'firstName', label: 'Vorname' },
    { name: 'lastName', label: 'Nachname' },
    { name: 'auth', label: 'Typ' },
    { name: 'status', label: 'Status' },
    { name: 'action', label: '', align: 'right' },
  ];

  private get serviceProviders(): AppCustomerCustomerServiceProvidersViewQuery['customer']['first']['serviceProviders']['items'] {
    return this.customer?.first.serviceProviders.items ?? [];
  }

  protected get count(): number {
    return this.customer?.first.serviceProviders.count ?? 0;
  }

  private get authenticationMethodOptions(): Option<ServiceProviderAuthMethod>[] {
    return Object.values(SERVICE_PROVIDER_AUTH_METHOD_META).sort((a, b) => a.label.localeCompare(b.label));
  }

  private get serviceProviderStatuses(): Record<ServiceProviderStatus, string> {
    return {
      [ServiceProviderStatus.ACTIVE]: 'Aktiv',
      [ServiceProviderStatus.INACTIVE]: 'Inaktiv',
    };
  }

  private getStatusLabel({ status }: ServiceProvider): string | undefined {
    return this.serviceProviderStatuses[status] ?? undefined;
  }

  private constructAuthInput(auth: FormDataAuth): FormDataAuth {
    const authValue = auth?.type === ServiceProviderAuthMethod.PIN ? auth.value : uuidv4();

    return {
      type: auth?.type,
      value: authValue,
    };
  }

  private isAuthValueInputHidden(auth?: FormDataAuth): boolean {
    if (auth?.type === ServiceProviderAuthMethod.TOKEN) {
      return true;
    }

    return false;
  }

  private isRowOfAuthTypeToken({
    auth,
  }: AppCustomerCustomerServiceProvidersViewQuery['customer']['first']['serviceProviders']['items'][number]): boolean {
    return auth.type === ServiceProviderAuthMethod.TOKEN;
  }

  private async addServiceProvider({ auth, ...input }: AddServiceProviderInput): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppCustomerCustomerServiceProvidersViewAddServiceProviderMutation,
      AppCustomerCustomerServiceProvidersViewAddServiceProviderMutationVariables
    >({
      mutation: AddServiceProviderMutation,
      variables: {
        input: { ...input, customerId: this.customerId, auth: auth ? this.constructAuthInput(auth) : auth },
      },
    });

    if (!data) {
      throw new Error('The service provider could not be added');
    }

    return data.addServiceProvider.serviceProvider.id;
  }

  private copyToClipboard({
    auth,
  }: AppCustomerCustomerServiceProvidersViewQuery['customer']['first']['serviceProviders']['items'][number]): void {
    navigator.clipboard.writeText(generateActivityCheckInAuthTokenUrl(auth.value));

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'In die Zwischenablage kopiert', class: 'success' }],
    });
  }

  private async onSubmitted(serviceProviderId: string, hide: () => Promise<void>): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppCustomer/ServiceProvider', params: { serviceProviderId } });
  }

  private pluckId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }

  private get options(): Option[] {
    return [
      {
        value: null,
        label: 'Aus Aktivität übernehmen',
      },
      {
        value: 'PT1H',
        label: 'Eine Stunde',
      },
      {
        value: 'PT4H',
        label: 'Vier Stunden',
      },
      {
        value: 'PT8H',
        label: 'Acht Stunden',
      },
      {
        value: 'P1D',
        label: 'Ein Tag',
      },
    ];
  }
}
