




































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Option } from '@/features/ui/inputs/model';
import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { Column } from '@/features/ui/table/model';
import { AddHeatingSystemMeasurementInput } from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { NIL } from 'uuid';
import { Component, Mixins } from 'vue-property-decorator';
import addHeatingSystemMeasurementMutation from './add-heating-system-measurement.gql';
import {
  ViessmannMeasurementConfiguration,
  VIESSMANN_BURNER_MEASUREMENT_TEMPLATES,
  VIESSMANN_CIRCUIT_MEASUREMENT_TEMPLATES,
  VIESSMANN_DEFAULT_MEASUREMENTS,
} from './constants';
import query from './view.gql';
import {
  AppCustomerSetupViessmannMeasurementsWizardControlAddHeatingSystemMeasurementMutation,
  AppCustomerSetupViessmannMeasurementsWizardControlAddHeatingSystemMeasurementMutationVariables,
} from './__generated__/AppCustomerSetupViessmannMeasurementsWizardControlAddHeatingSystemMeasurementMutation';
import {
  AppCustomerSetupViessmannMeasurementsWizardControlViewQuery,
  AppCustomerSetupViessmannMeasurementsWizardControlViewQueryVariables,
} from './__generated__/AppCustomerSetupViessmannMeasurementsWizardControlViewQuery';

type Device =
  AppCustomerSetupViessmannMeasurementsWizardControlViewQuery['customers']['first']['viessmannApis']['first']['connectedInstallations']['first']['devices']['first'];

type MeasurementSelection = {
  selected: boolean;
  name: string;
  metricName: string;
  consumerId?: string;
};
@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(
        this: SetupViessmannMeasurementsWizardControl,
      ): AppCustomerSetupViessmannMeasurementsWizardControlViewQueryVariables {
        return {
          customerId: this.customerId,
          viessmannApiId: this.viessmannApiId,
          installationId: parseInt(this.installationId),
          deviceId: this.deviceId,
          featureNames: this.featureNames,
          featureNamesReady: this.featureNames !== null,
        };
      },
      result(
        this: SetupViessmannMeasurementsWizardControl,
        { data }: ApolloQueryResult<AppCustomerSetupViessmannMeasurementsWizardControlViewQuery>,
      ): void {
        const consumers =
          data.customers?.first.viessmannApis.first.connectedInstallations.first.heatingSystem.consumers ?? [];
        const features =
          data.customers?.first.viessmannApis.first.connectedInstallations.first.devices.first.features?.map(
            ({ feature }) => feature,
          ) ?? [];

        this.measurementSelections =
          this.measurementConfigurations
            ?.filter(({ path }) => features.includes(path))
            .map((measurementConfiguration) => ({
              selected: true,
              name: measurementConfiguration.label,
              metricName: measurementConfiguration.name,
              consumerId: consumers.length === 1 ? consumers[0].node?.id : undefined,
            })) ?? [];
      },
    },
  },
  data() {
    return { customers: undefined, measurementSelections: [] };
  },
})
export default class SetupViessmannMeasurementsWizardControl extends Mixins(Busyable) {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly viessmannApiId!: string;

  @StringProp(true)
  private readonly gatewaySerial!: string;

  @StringProp(true)
  private readonly installationId!: string;

  @StringProp(true)
  private readonly deviceId!: string;

  private readonly customers?: AppCustomerSetupViessmannMeasurementsWizardControlViewQuery['customers'];

  private measurementSelections!: MeasurementSelection[];

  private get title(): string {
    return `Gerät "${this.deviceId}" an Gateway "${this.gatewaySerial}" von Heizungssystem "${this.installationId}" Messstellen`;
  }

  private get columns(): Column[] {
    return [
      { name: 'selected', label: 'Auswählen', width: 30 },
      { name: 'name', label: 'Name der Messstelle' },
      { name: 'consumer', label: 'Versorgungsbereich' },
    ];
  }

  private get device(): Device | undefined {
    return this.customers?.first.viessmannApis.first.connectedInstallations.first.devices.first;
  }

  private get featureNames(): string[] | null {
    return this.measurementConfigurations?.map(({ path }) => path) ?? null;
  }

  private get circuitMeasurementConfigurations(): ViessmannMeasurementConfiguration[] {
    const circuits = this.device?.circuits?.flatMap(({ components }) => components) ?? [];

    return circuits.flatMap((circuit) =>
      VIESSMANN_CIRCUIT_MEASUREMENT_TEMPLATES.map((measurementConfiguration) => ({
        ...measurementConfiguration,
        name: measurementConfiguration.name.replace('{}', circuit),
        label: measurementConfiguration.label.replace('{}', circuit),
        path: measurementConfiguration.path.replace('{}', circuit),
      })),
    );
  }

  private get burnerMeasurementConfigurations(): ViessmannMeasurementConfiguration[] {
    const burners = this.device?.burners?.flatMap(({ components }) => components) ?? [];

    return burners.flatMap((burner) =>
      VIESSMANN_BURNER_MEASUREMENT_TEMPLATES.map((measurementConfiguration) => ({
        ...measurementConfiguration,
        name: measurementConfiguration.name.replace('{}', burner),
        label: measurementConfiguration.label.replace('{}', burner),
        path: measurementConfiguration.path.replace('{}', burner),
      })),
    );
  }

  private get measurementConfigurations(): ViessmannMeasurementConfiguration[] | null {
    return this.customers
      ? VIESSMANN_DEFAULT_MEASUREMENTS.concat([
          ...this.circuitMeasurementConfigurations,
          ...this.burnerMeasurementConfigurations,
        ])
      : null;
  }

  private get consumerOptions(): Option[] {
    return (
      this.customers?.first.viessmannApis.first.connectedInstallations.first.heatingSystem.consumers.map(
        ({ node, name }) => ({ label: name, value: node?.id }),
      ) ?? []
    );
  }

  private get isValidSelection(): boolean {
    if (this.measurementSelections.some(({ name, consumerId }) => !name || !consumerId)) {
      return false;
    }
    return true;
  }

  @Busy()
  private async addMeasurements(hide: () => Promise<void>): Promise<void> {
    const device = this.customers?.first.viessmannApis.first.connectedInstallations.first.devices.first;
    if (!device || device.__typename !== 'ViessmannConnectedDevice') {
      return;
    }
    const addHeatingSystemMeasurementInputs: AddHeatingSystemMeasurementInput[] = this.measurementSelections
      .filter(({ selected, consumerId }) => selected === true && isDef(consumerId))
      .map(({ name, metricName, consumerId }) => ({
        name,
        metricName,
        consumerId: consumerId as string,
        heatingSystemId: this.customers?.first.viessmannApis.first.connectedInstallations.first.heatingSystem.id ?? NIL,
        spotId: device.deviceMount.spot.id,
      }));

    const results = await Promise.allSettled(
      addHeatingSystemMeasurementInputs.map((input) =>
        this.$apollo.mutate<
          AppCustomerSetupViessmannMeasurementsWizardControlAddHeatingSystemMeasurementMutation,
          AppCustomerSetupViessmannMeasurementsWizardControlAddHeatingSystemMeasurementMutationVariables
        >({
          mutation: addHeatingSystemMeasurementMutation,
          variables: {
            input,
          },
        }),
      ),
    );

    this.ADD_TOAST_MESSAGES({
      messages: [
        {
          text: `${results.filter(({ status }) => status === 'fulfilled').length} Messstellen werden hinzugefügt.`,
          class: 'success',
        },
      ],
    });

    await hide();
  }
}
