


































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerEnerIqEconomicUnitTableFragment } from '../../../components/ener-iq-economic-unit-table/__generated__/AppCustomerEnerIqEconomicUnitTableFragment';
import connectMutation from './connect.gql';
import query from './view.gql';
import {
  AppCustomerEnerIqApiEconomicUnitsViewConnectMutation,
  AppCustomerEnerIqApiEconomicUnitsViewConnectMutationVariables,
} from './__generated__/AppCustomerEnerIqApiEconomicUnitsViewConnectMutation';
import {
  AppCustomerEnerIqApiEconomicUnitsViewQuery,
  AppCustomerEnerIqApiEconomicUnitsViewQueryVariables,
} from './__generated__/AppCustomerEnerIqApiEconomicUnitsViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: EconomicUnitsView): AppCustomerEnerIqApiEconomicUnitsViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class EconomicUnitsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerEnerIqApiEconomicUnitsViewQuery['customers'];

  private get economicUnits(): AppCustomerEnerIqEconomicUnitTableFragment[] {
    return this.customers?.first.enerIqApi?.economicUnits.items ?? [];
  }

  private async connect(
    economicUnit: AppCustomerEnerIqEconomicUnitTableFragment & { id: EnerIqConnectedEconomicUnitId },
  ): Promise<void> {
    if (!window.confirm('Wollen Sie die Heizungsanlage mit EnerIQ verknüpfen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerEnerIqApiEconomicUnitsViewConnectMutation,
      AppCustomerEnerIqApiEconomicUnitsViewConnectMutationVariables
    >({
      mutation: connectMutation,
      variables: { input: { enerIqApiId: this.vendorApiId, heatingSystemId: economicUnit.heatingSystem.id } },
    });

    if (!data) {
      throw new Error('Die Verknüpfung der Heizungsanlage zu EnerIQ konnte nicht hergestellt werden');
    }

    const text = `Die Verknüpfung der Heizungsanlage "${economicUnit.heatingSystem.name}" zu EnerIQ wurde hergestellt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$emit('ener-iq-economic-unit-connected', economicUnit);

    await this.$apollo.queries.customers.refetch();
  }

  public goToEnerIqEconomicUnitPage(connectedEconomicUnitId: string): void {
    const { customerId, vendorApiId } = this;
    this.$router.push({
      name: 'AppCustomer/EnerIqConnectedEconomicUnit',
      params: {
        customerId,
        vendorApiId,
        connectedEconomicUnitId,
      },
    });
  }
}
