







































import { Column } from '@/features/ui/table/model';
import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAufzugheldenDeviceTableDeviceFragment } from './__generated__/AppCustomerAufzugheldenDeviceTableDeviceFragment';

@Component
export default class AufzugheldenDeviceTable extends Vue {
  @ArrayProp(() => [])
  private readonly devices!: AppCustomerAufzugheldenDeviceTableDeviceFragment;

  private readonly columns: Column[] = [
    { name: 'id', label: 'Aufzug' },
    { name: 'address', label: 'Adresse' },
    { name: 'path', label: 'Verknüpftes Objekt' },
    { name: 'integration', label: '' },
    { name: 'action', label: '', align: 'right' },
  ];
}
