














import { generateDomId } from '@/util/dom-id';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import addNukiApiMutation from './add-nuki-api.gql';
import startAuthentication from './start-authentication.gql';
import {
  AppCustomerAddNukiApiMutation,
  AppCustomerAddNukiApiMutationVariables,
} from './__generated__/AppCustomerAddNukiApiMutation';
import {
  AppCustomerStartNukiApiAuthenticationMutation,
  AppCustomerStartNukiApiAuthenticationMutationVariables,
} from './__generated__/AppCustomerStartNukiApiAuthenticationMutation';

@Component
export default class NukiApi extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private async initAuth(): Promise<void> {
    const { data } = await this.$apollo.mutate<AppCustomerAddNukiApiMutation, AppCustomerAddNukiApiMutationVariables>({
      mutation: addNukiApiMutation,
      variables: {
        input: {
          customerId: this.customerId,
          label: `AUTHORIZATION REQUESTED ${generateDomId()}`,
        },
      },
    });

    const nukiApiId = data?.addNukiApi.nukiApi.id;

    if (!nukiApiId) {
      throw new Error('No vendor api id');
    }

    const redirectUrl = this.$router.resolve({
      name: 'AppCustomer/VendorApis/NukiApi',
      params: { customerId: this.customerId, vendorApiId: nukiApiId },
    });

    const authenticationResponse = await this.$apollo.mutate<
      AppCustomerStartNukiApiAuthenticationMutation,
      AppCustomerStartNukiApiAuthenticationMutationVariables
    >({
      mutation: startAuthentication,
      variables: {
        input: {
          nukiApiId,
          redirectUrl: redirectUrl.href,
        },
      },
    });

    if (authenticationResponse.data) {
      location.href = authenticationResponse.data.startNukiApiAuthentication.authorizationUrl;
    }
  }
}
