


































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAareonHeatingSystemTableFragment } from '../../../components/aareon-heating-system-table/__generated__/AppCustomerAareonHeatingSystemTableFragment';
import connectMutation from './connect.gql';
import disconnectMutation from './disconnect.gql';
import query from './view.gql';
import {
  AppCustomerAareonApiHeatingSystemViewQuery,
  AppCustomerAareonApiHeatingSystemViewQueryVariables,
} from './__generated__/AppCustomerAareonApiHeatingSystemViewQuery';
import {
  AppCustomerAareonApiHeatingSystemsViewConnectMutation,
  AppCustomerAareonApiHeatingSystemsViewConnectMutationVariables,
} from './__generated__/AppCustomerAareonApiHeatingSystemsViewConnectMutation';
import {
  AppCustomerAareonApiHeatingSystemsViewDisconnectMutation,
  AppCustomerAareonApiHeatingSystemsViewDisconnectMutationVariables,
} from './__generated__/AppCustomerAareonApiHeatingSystemsViewDisconnectMutation';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: HeatingSystemsView): AppCustomerAareonApiHeatingSystemViewQueryVariables {
        return { customerId: this.customerId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class HeatingSystemsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerAareonApiHeatingSystemViewQuery['customers'];

  private get aareonHeatingSystems(): AppCustomerAareonHeatingSystemTableFragment[] {
    return this.customers?.first.aareonApi?.aareonHeatingSystems.items ?? [];
  }

  private async connect(aareonHeatingSystem: AppCustomerAareonHeatingSystemTableFragment): Promise<void> {
    if (!window.confirm('Wollen Sie die Heizungsanlage mit Aareon verknüpfen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAareonApiHeatingSystemsViewConnectMutation,
      AppCustomerAareonApiHeatingSystemsViewConnectMutationVariables
    >({
      mutation: connectMutation,
      variables: { input: { aareonApiId: this.vendorApiId, heatingSystemId: aareonHeatingSystem.heatingSystem.id } },
    });

    if (!data) {
      throw new Error('Die Verknüpfung der Heizungsanlage zu Aareon konnte nicht hergestellt werden');
    }

    const text = `Die Verknüpfung der Heizungsanlage "${aareonHeatingSystem.heatingSystem.name}" zu Aareon wurde hergestellt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$emit('aareon-heating-system-connected', aareonHeatingSystem);

    await this.$apollo.queries.customers.refresh();
  }

  private async disconnect(aareonHeatingSystem: AppCustomerAareonHeatingSystemTableFragment): Promise<void> {
    if (!window.confirm('Wollen Sie die Heizungsanlage von Aareon trennen?')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerAareonApiHeatingSystemsViewDisconnectMutation,
      AppCustomerAareonApiHeatingSystemsViewDisconnectMutationVariables
    >({
      mutation: disconnectMutation,
      variables: { input: { id: aareonHeatingSystem.heatingSystem.id } },
    });

    if (!data) {
      throw new Error('Die Verknüpfung der Heizungsanlage zu Aareon konnte nicht getrennt werden');
    }

    const text = `Die Verknüpfung der Heizungsanlage "${aareonHeatingSystem.heatingSystem.name}" zu Aareon wurde getrennt.`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$emit('aareon-heating-system-disconnected', aareonHeatingSystem);

    await this.$apollo.queries.customers.refresh();
  }
}
