





































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerViessmannConnectedInstallationViewQuery,
  AppCustomerViessmannConnectedInstallationViewQueryVariables,
} from './__generated__/AppCustomerViessmannConnectedInstallationViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ViessmannConnectedInstallationView): AppCustomerViessmannConnectedInstallationViewQueryVariables {
        return {
          customerId: this.customerId,
          vendorApiId: this.vendorApiId,
          installationId: parseInt(this.installationId),
        };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class ViessmannConnectedInstallationView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @StringProp(true)
  private readonly installationId!: string;

  private readonly customers?: AppCustomerViessmannConnectedInstallationViewQuery['customers'];
}
