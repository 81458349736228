




























































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerViessmannDeviceTableFragment } from '../../../components/viessmann-device-table/__generated__/AppCustomerViessmannDeviceTableFragment';
import query from './view.gql';
import {
  AppCustomerViessmannConnectedInstallationDevicesViewQuery,
  AppCustomerViessmannConnectedInstallationDevicesViewQueryVariables,
} from './__generated__/AppCustomerViessmannConnectedInstallationDevicesViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'network-only',
      variables(this: DevicesView): AppCustomerViessmannConnectedInstallationDevicesViewQueryVariables {
        return {
          customerId: this.customerId,
          viessmannApiId: this.vendorApiId,
          installationId: parseInt(this.installationId),
        };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class DevicesView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @StringProp(true)
  private readonly installationId!: string;

  private readonly customers?: AppCustomerViessmannConnectedInstallationDevicesViewQuery['customers'];

  private get devices(): AppCustomerViessmannDeviceTableFragment[] {
    return this.customers?.first.viessmannApis.first.connectedInstallations.first.devices.items ?? [];
  }
}
