





















































import { AddExternalSftpConfigurationInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import addExternalSftpConfigurationMutation from './add-external-sftp-configuration.gql';
import {
  AppCustomerAddVendorApiWizardControlExternalSftpConfigurationMutation,
  AppCustomerAddVendorApiWizardControlExternalSftpConfigurationMutationVariables,
} from './__generated__/AppCustomerAddVendorApiWizardControlExternalSftpConfigurationMutation';

@Component
export default class ExternalSftpConfiguration extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private readonly isNonEmptyString = isNonEmptyString;

  private async addExternalSftpConfiguration(
    input: AddExternalSftpConfigurationInput,
  ): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment> {
    const { data } = await this.$apollo.mutate<
      AppCustomerAddVendorApiWizardControlExternalSftpConfigurationMutation,
      AppCustomerAddVendorApiWizardControlExternalSftpConfigurationMutationVariables
    >({
      mutation: addExternalSftpConfigurationMutation,
      variables: { input: { ...input, customerId: this.customerId } },
    });

    if (!data || !data.addExternalSftpConfiguration.externalSftpConfiguration) {
      throw new Error(
        `Die SFTP Server Konfiguration konnte nicht angelegt werden: ${data?.addExternalSftpConfiguration.verificationError}`,
      );
    }

    return data.addExternalSftpConfiguration.externalSftpConfiguration;
  }

  private isValidPort(value: unknown): boolean {
    return Number.isInteger(value) && (value as number) > 0 && (value as number) < 65536;
  }
}
