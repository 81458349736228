export type ViessmannSettingConfiguration = {
  label: string;
  name: string;
  path: string;
};

export const VIESSMANN_DEFAULT_SETTINGS: ViessmannSettingConfiguration[] = [
  {
    label: 'DHW Haupttemperatur',
    name: 'heatingDhwTemperatureMain',
    path: 'heating.dhw.temperature.main',
  },
];

export const VIESSMANN_CIRCUIT_SETTING_TEMPLATES: ViessmannSettingConfiguration[] = [
  {
    label: 'Heizkreis {} Normale Betriebsprogramm Temperatur',
    name: 'heatingCircuitsOperatingProgramsNormal{}',
    path: 'heating.circuits.{}.operating.programs.normal',
  },
  {
    label: 'Heizkreis {} Reduzierte Betriebsprogramm Temperatur',
    name: 'heatingCircuitsOperatingProgramsReduced{}',
    path: 'heating.circuits.{}.operating.programs.reduced',
  },
];
