



































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerViessmannApiViewQuery,
  AppCustomerViessmannApiViewQueryVariables,
} from './__generated__/AppCustomerViessmannApiViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ViessmannApiView): AppCustomerViessmannApiViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class ViessmannApiView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerViessmannApiViewQuery['customers'];
}
