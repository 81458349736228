
































































import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { Action, RootAction } from '@/features/core/store';
import { AppCustomerPushCustomer } from '@/features/core/store/app-customer';
import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins } from 'vue-property-decorator';
import { AppCustomerViewQuery } from './__generated__/AppCustomerViewQuery';
import query from './view.gql';
import { LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID } from '@/env';

type Customer = AppCustomerViewQuery['customers']['items'][number];

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      result(this: CustomerView, { data, stale }: ApolloQueryResult<AppCustomerViewQuery>): void {
        if (!stale && data.customers.items.length === 0) {
          this.$router.replace({ name: 'AppCustomer' });
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class CustomerView extends Mixins(AppRedirectionMixin, GroupsMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly appCustomerPushCustomer!: Action<AppCustomerPushCustomer, void>;

  private readonly customers?: AppCustomerViewQuery['customers'];

  private get selectedCustomer(): Customer | undefined {
    return this.customers?.items.find(({ id }) => id === this.customerId);
  }

  private set selectedCustomer(value: Customer | undefined) {
    if (value !== undefined && value.id !== this.customerId) {
      this.appCustomerPushCustomer({ customerId: value.id });
      this.$router.push({ params: { customerId: value.id } });
    }
  }

  private enableNotificationAuditLog(): boolean {
    return !LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID || LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID === this.customerId;
  }

  private matchTabValue(bar: string, button: string): boolean {
    return bar === button || bar.startsWith(`${button}/`);
  }
}
