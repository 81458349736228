var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-layout',{attrs:{"use":"core-layout-default"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.attributeDefinitions)?_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-content-header',{attrs:{"headline":_vm.attributeDefinitions.first.configuration.name,"back-link":""},on:{"back-click":function($event){return _vm.$router.go(-1)}}},[_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer' }}},[_vm._v("Kunden")]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Customer', params: { customerId: _vm.attributeDefinitions.first.customer.id } }}},[_vm._v(_vm._s(_vm.attributeDefinitions.first.customer.name))]),_vm._v(_vm._s(' ')),_c('ui-breadcrumb-item',{attrs:{"to":{ name: 'AppCustomer/Customer/AttributeDefinitions', params: { customerId: _vm.attributeDefinitions.first.customer.id } }}},[_vm._v("Attribute")])],1)],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel',[_c('ui-form',{attrs:{"initial-data":_vm.attributeDefinitions.first,"action":_vm.editAttributeDefinition},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
var formData = ref.formData;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"configuration.name","path":"name","label":"Name"}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"configuration.__typename","label":"Typ","options":_vm.attributeTypeOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"disabled":""}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-textarea","initial-path":"configuration.description","path":"description","label":"Beschreibung"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',[_vm._v("Dieses Attribut wird nur mit „Geräten“ verknüpft.")])],1),(!_vm.isCalculationType)?_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox-list","initial-path":"configuration.treeNodeTypes","path":"treeNodeTypes","label":"Anwendbar auf","all":"","empty-value":_vm.emptyArray,"options":_vm.treeNodeTypeOptions}})],1):_vm._e(),(!_vm.isCalculationType)?_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-checkbox-list","initial-path":"configuration.inheritableTreeNodeTypes","path":"inheritableTreeNodeTypes","label":"Vererbbar auf","all":"","empty-value":_vm.emptyArray,"options":_vm.treeNodeTypeOptions}})],1):_vm._e(),(_vm.attributeDefinitions.first.configuration.__typename === 'SelectAttributeConfiguration')?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","initial-path":"configuration.options","path":"options","label":"Options","taggable":"","multiple":"","no-drop":"","placeholder":"Optionen eingeben (Drücken Sie \"ENTER\" um die Eingabe als Option hinzuzufügen)"}})],1):_vm._e(),(_vm.isCalculationType)?_c('ui-flex',{staticClass:"calculation-fields"},[_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Zugriff auf ausgewählten Hersteller einschränken","initial-path":"configuration.calculations.manufacturer","path":"calculations.manufacturer","options":_vm.manufacturerOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Hinzufügen…","disabled":_vm.$apollo.queries.treeNodes.loading},on:{"update":_vm.onUpdateManufacturer}})],1),_c('ui-cell',{attrs:{"basis":0.5}},[_c('ui-form-field',{ref:"deviceTypes",attrs:{"input":"ui-input-select","label":"Zugriff auf ausgewählte Gerätetypen einschränken","initial-path":"configuration.calculations.deviceRole","path":"calculations.deviceRole","options":_vm.deviceTypeOptions,"placeholder":"Hinzufügen…","multiple":"","disabled":_vm.$apollo.queries.treeNodes.loading},on:{"update":_vm.onUpdateDeviceTypes}})],1),_c('ui-cell',{staticClass:"toggle-secondary-fields",attrs:{"basis":1}},[_c('ui-button',{attrs:{"back":"","icon":_vm.showMoreCalculationFields ? _vm.minusIcon : _vm.plusIcon},on:{"click":_vm.toggleSecondaryFields}},[_vm._v(_vm._s(_vm.showMoreCalculationFields ? 'Berechnungsoption entfernen' : 'Kalkulationsoption hinzufügen'))])],1),_c('ui-flex',{staticClass:"calculation-inputs"},[_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Metrik","initial-path":"configuration.calculations.metricName","path":"calculations.metricName","options":_vm.metricOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Hinzufügen…","required":true}})],1),_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Metrikwert","initial-path":"configuration.calculations.metricValue","path":"calculations.metricValue","options":_vm.metricValueOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Hinzufügen…"}})],1),_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Kalkulationsart","initial-path":"configuration.calculations.calculationType","path":"calculations.calculationType","options":_vm.calculationTypes,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Hinzufügen…"}})],1),_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"configuration.calculations.calculationFactor","path":"calculations.calculationFactor","label":"Kalkulationsfaktor","type":"number"}})],1),(_vm.showMoreCalculationFields)?_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","label":"Kalkulationsart2","initial-path":"configuration.calculations.calculationType2","path":"calculations.calculationType2","options":_vm.calculationTypes,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Hinzufügen…"}})],1):_vm._e(),(_vm.showMoreCalculationFields)?_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"configuration.calculations.calculationFactor2","path":"calculations.calculationFactor2","label":"Kalkulationsfaktor2","type":"number"}})],1):_vm._e(),_c('ui-cell',{attrs:{"basis":0.1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","initial-path":"configuration.calculations.resultUnit","path":"calculations.resultUnit","label":"Ergebniseinheit"}})],1)],1),(_vm.$apollo.queries.treeNodes.loading)?_c('ui-cell',{attrs:{"basis":1}},[_c('p',{staticClass:"loading"},[_vm._v("Loading Objects...")])]):_c('ui-flex',{staticClass:"object-selection"},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{ref:"objectFilter",attrs:{"input":"core-input-tree-node-select-control","initial-path":"configuration.calculations.spotFilterIds","initial-transformer":_vm.transformSpotFilterIds,"path":"calculations.spotFilterIds","transformer":_vm.pluckIds,"root-ids":_vm.rootIds,"label":"Zugriff auf ausgewählte Objekte einschränken","filter":_vm.filter,"open-selected":true}})],1)],1)],1):_vm._e(),_c('ui-cell',{attrs:{"grow":0}},[_c('ui-wizard',{attrs:{"title":"Bitte aufmerksam lesen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-wizard-button',{attrs:{"secondary":""},on:{"click":show}},[_vm._v("Attribut löschen")])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"action":_vm.removeAttributeDefinition,"no-reset":""},on:{"submitted":function($event){return _vm.onAttributeDefinitionRemoved(hide)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('ui-flex',{attrs:{"max":600}},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-message',{attrs:{"warning":""}},[_vm._v("Wenn Sie dieses Attribut entfernen werden "),_c('strong',[_vm._v("alle dazugehörigen gespeicherten Werte unwiderruflich gelöscht")]),_vm._v("."),_c('br'),_c('br'),_vm._v("Bitte geben Sie den Namen des Attributs ein um die Löschung zu bestätigen.")])],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-text","path":"name","placeholder":"Attributname","error":!_vm.equalsName(formData.name)}})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty || !_vm.equalsName(formData.name),"busy":busy},on:{"click":submit}},[_vm._v("Entfernen")])],1)],1)]}}],null,true)})]}}],null,true)})],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{staticClass:"btn-submit",attrs:{"disabled":!dirty || !_vm.isValidData(formData),"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,false,2955370375)})],1)],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }