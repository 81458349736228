




















































































































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { RecurringInterval } from '@/features/domain-ui/recurring-interval/model';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import addActivityCheckInScheduleMutation from './add-activity-check-in-schedule.gql';
import query from './view.gql';
import {
  AppCustomerActivityActivityCheckInSchedulesViewAddActivityCheckInScheduleMutation,
  AppCustomerActivityActivityCheckInSchedulesViewAddActivityCheckInScheduleMutationVariables,
} from './__generated__/AppCustomerActivityActivityCheckInSchedulesViewAddActivityCheckInScheduleMutation';
import {
  AppCustomerActivityActivityCheckInSchedulesViewQuery,
  AppCustomerActivityActivityCheckInSchedulesViewQueryVariables,
} from './__generated__/AppCustomerActivityActivityCheckInSchedulesViewQuery';
import { DAY_OF_WEEK_META, INTERVAL_UNIT_META } from '@/features/domain-ui/interval/constants';
import { Option } from '@/features/ui/inputs/model';
import { TreeNodeType, TREE_NODE_TYPE_META } from '@/features/domain-ui/tree-node-type/constants';
import { AddActivityCheckInScheduleInput } from '@/types/iot-portal';

type Schedule =
  AppCustomerActivityActivityCheckInSchedulesViewQuery['activity']['first']['checkInSchedules']['items'][number];

@Component({
  apollo: {
    activity: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ActivityCheckInSchedulesView): AppCustomerActivityActivityCheckInSchedulesViewQueryVariables {
        return { id: this.activityId };
      },
    },
  },
  data() {
    return { activity: undefined };
  },
})
export default class ActivityCheckInSchedulesView extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly activityId!: string;

  private readonly columns: Column[] = [
    { name: 'interval', label: 'Intervall' },
    { name: 'startDate', label: 'Von' },
    { name: 'endDate', label: 'Bis' },
    { name: 'treeNodeType', label: 'Objekttyp' },
    { name: 'treeNodeIds', label: 'Anwenden in', width: 200 },
    { name: 'notifyOnMissedLogEntry', label: 'Benachrichtigung' },
    { name: 'action', label: '', align: 'right' },
  ];

  private readonly activity?: AppCustomerActivityActivityCheckInSchedulesViewQuery['activity'];

  private get schedules(): Schedule[] {
    return this.activity?.first.checkInSchedules.items ?? [];
  }

  private get title(): string | undefined {
    return `Zeitplan für Aktivität ${this.activity?.first.name} anlegen`;
  }

  private get rootIds(): string[] | undefined {
    if (this.activity === undefined) {
      return undefined;
    }
    return [this.activity.first.customer.id];
  }

  private get treeNodeTypes(): Option[] {
    return Object.keys(TreeNodeType).map((type) => ({
      value: type,
      label: TREE_NODE_TYPE_META[type as TreeNodeType].labelPlural,
    }));
  }

  private isValid(formData: {
    schedule: RecurringInterval | null;
    treeNodeIds: string[] | null;
    treeNodeType: TreeNodeType | null;
  }): boolean {
    return formData.schedule !== null && formData.treeNodeIds !== null && formData.treeNodeType !== null;
  }

  private async addActivityCheckInSchedule({
    schedule,
    ...input
  }: { schedule: RecurringInterval } & Pick<
    AddActivityCheckInScheduleInput,
    'treeNodeIds' | 'treeNodeType'
  >): Promise<string> {
    const { data } = await this.$apollo.mutate<
      AppCustomerActivityActivityCheckInSchedulesViewAddActivityCheckInScheduleMutation,
      AppCustomerActivityActivityCheckInSchedulesViewAddActivityCheckInScheduleMutationVariables
    >({
      mutation: addActivityCheckInScheduleMutation,
      variables: { input: { ...schedule, activityId: this.activityId, ...input, notifyOnMissedLogEntry: false } },
    });

    if (!data) {
      throw new Error('Der Zeitplan konnte nicht angelegt werden.');
    }

    return data.addActivityCheckInSchedule.activityCheckInSchedule.id;
  }

  private async onAddActivityCheckInScheduleSubmitted(
    activityCheckInScheduleId: string,
    hide: () => Promise<void>,
  ): Promise<void> {
    await hide();

    this.$router.push({ name: 'AppCustomer/Activity/ActivityCheckInSchedule', params: { activityCheckInScheduleId } });
  }

  private getIntervalText(interval: RecurringInterval): string {
    return interval.daysOfWeek !== null && interval.daysOfWeek.length > 0
      ? `Jeden ${interval.daysOfWeek.map((day) => DAY_OF_WEEK_META[day].label).join(', ')}`
      : interval.amount === 1
      ? INTERVAL_UNIT_META[interval.unit].labelAdverb
      : `Alle ${interval.amount} ${INTERVAL_UNIT_META[interval.unit].labelPlural}`;
  }

  private pluckId<T>(objects: { id: T }[] | null): T[] | null {
    return objects?.map(({ id }) => id) ?? null;
  }
}
