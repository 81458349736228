



































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerEnerIqConnectedEconomicUnitViewQuery,
  AppCustomerEnerIqConnectedEconomicUnitViewQueryVariables,
} from './__generated__/AppCustomerEnerIqConnectedEconomicUnitViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: EnerIqConnectedEconomicUnitView): AppCustomerEnerIqConnectedEconomicUnitViewQueryVariables {
        return {
          customerId: this.customerId,
          connectedEconomicUnitId: this.connectedEconomicUnitId,
        };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class EnerIqConnectedEconomicUnitView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  @StringProp(true)
  private readonly connectedEconomicUnitId!: string;

  private readonly customers?: AppCustomerEnerIqConnectedEconomicUnitViewQuery['customers'];
}
