



















import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerViessmannInstallationTableFragment } from '../../../components/viessmann-installation-table/__generated__/AppCustomerViessmannInstallationTableFragment';
import query from './view.gql';
import {
  AppCustomerViessmannApiConnectedInstallationsViewQuery,
  AppCustomerViessmannApiConnectedInstallationsViewQueryVariables,
} from './__generated__/AppCustomerViessmannApiConnectedInstallationsViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: ConnectedInstallationsView): AppCustomerViessmannApiConnectedInstallationsViewQueryVariables {
        return { customerId: this.customerId, viessmannApiId: this.vendorApiId };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class ConnectedInstallationsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerViessmannApiConnectedInstallationsViewQuery['customers'];

  private get connectedInstallations(): AppCustomerViessmannInstallationTableFragment[] {
    return this.customers?.first.viessmannApis.first.connectedInstallations?.items ?? [];
  }

  public goToViessmannInstallationPage(installationId: number): void {
    const { customerId, vendorApiId } = this;
    this.$router.push({
      name: 'AppCustomer/ViessmannConnectedInstallation',
      params: {
        customerId,
        vendorApiId,
        installationId: String(installationId),
      },
    });
  }
}
